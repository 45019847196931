import { CloseIcon } from '@chakra-ui/icons'
import { Flex, Text, Box } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { Label } from '../../../app/components/Label/Label'
import { Alarm } from '../types'

interface Props {
  alarm: Alarm
  closeToast: () => void
}

const AlarmToast: React.FC<Props> = ({ alarm, closeToast }) => {
  return (
    <Box
      p="12px"
      bg="white"
      rounded="md"
      boxShadow="4px 14px 38px 0px rgba(46, 46, 46, 0.12)"
      minW="382px"
    >
      <Flex justifyContent="space-between" w="full">
        <Flex justifyContent="space-between" direction="column" gap="1">
          <Text fontSize="14px" fontWeight="700" lineHeight="18px" color="text.dark">
            {alarm.alarm_type.name}
          </Text>
          <Flex direction="row" align="center">
            <Box>
              <Label type={alarm.severity} />
            </Box>
            <Text ml="1" fontSize="12px" fontWeight="700" color="text.secondary">
              • {alarm.device_title} {alarm.location_title}
            </Text>
          </Flex>
          <Text fontSize="12px" fontWeight="400" color="text.secondary">
            {format(alarm.timestamp, 'yyyy-MM-dd HH:mm:ss')}
          </Text>
        </Flex>
        <CloseIcon onClick={closeToast} cursor="pointer" />
      </Flex>
    </Box>
  )
}
export default AlarmToast
