import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { IMAGES } from '../AddLocation'

interface ImageModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  selectedImage: IMAGES | null
  setSelectedImage: (i: IMAGES | null) => void
}

const ImageModal = ({
  isOpen,
  setIsOpen,
  selectedImage,
  setSelectedImage,
}: ImageModalProps) => {
  const selectImage = useCallback(
    (id: IMAGES) => {
      if (selectedImage === id) {
        setSelectedImage(null)
      } else {
        setSelectedImage(id)
      }
    },
    [selectedImage, setSelectedImage],
  )

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent maxW={'650px'}>
        <ModalHeader>Choose image</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <HStack spacing={4}>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.LIVING_ROOM_1)}>
                <Image
                  border={
                    selectedImage === IMAGES.LIVING_ROOM_1 ? '2px solid #2182EB' : 0
                  }
                  boxShadow={
                    selectedImage === IMAGES.LIVING_ROOM_1 ? '0px 3px 20px #1DABFC6B' : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.LIVING_ROOM_1}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Living room 1
                </Text>
              </Box>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.KITCHEN_1)}>
                <Image
                  border={selectedImage === IMAGES.KITCHEN_1 ? '2px solid #2182EB' : 0}
                  boxShadow={
                    selectedImage === IMAGES.KITCHEN_1 ? '0px 3px 20px #1DABFC6B' : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.KITCHEN_1}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Kitchen 1
                </Text>
              </Box>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.LIVING_ROOM_2)}>
                <Image
                  border={
                    selectedImage === IMAGES.LIVING_ROOM_2 ? '2px solid #2182EB' : 0
                  }
                  boxShadow={
                    selectedImage === IMAGES.LIVING_ROOM_2 ? '0px 3px 20px #1DABFC6B' : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.LIVING_ROOM_2}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Living room 2
                </Text>
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.WORKING_PLACE_1)}>
                <Image
                  border={
                    selectedImage === IMAGES.WORKING_PLACE_1 ? '2px solid #2182EB' : 0
                  }
                  boxShadow={
                    selectedImage === IMAGES.WORKING_PLACE_1
                      ? '0px 3px 20px #1DABFC6B'
                      : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.WORKING_PLACE_1}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Working place 1
                </Text>
              </Box>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.WORKING_PLACE_2)}>
                <Image
                  border={
                    selectedImage === IMAGES.WORKING_PLACE_2 ? '2px solid #2182EB' : 0
                  }
                  boxShadow={
                    selectedImage === IMAGES.WORKING_PLACE_2
                      ? '0px 3px 20px #1DABFC6B'
                      : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.WORKING_PLACE_2}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Working place 2
                </Text>
              </Box>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.BEDROOM_1)}>
                <Image
                  border={selectedImage === IMAGES.BEDROOM_1 ? '2px solid #2182EB' : 0}
                  boxShadow={
                    selectedImage === IMAGES.BEDROOM_1 ? '0px 3px 20px #1DABFC6B' : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.BEDROOM_1}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Bedroom 1
                </Text>
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.BEDROOM_2)}>
                <Image
                  border={selectedImage === IMAGES.BEDROOM_2 ? '2px solid #2182EB' : 0}
                  boxShadow={
                    selectedImage === IMAGES.BEDROOM_2 ? '0px 3px 20px #1DABFC6B' : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.BEDROOM_2}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Bedroom 2
                </Text>
              </Box>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.OFFICE_1)}>
                <Image
                  border={selectedImage === IMAGES.OFFICE_1 ? '2px solid #2182EB' : 0}
                  boxShadow={
                    selectedImage === IMAGES.OFFICE_1 ? '0px 3px 20px #1DABFC6B' : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.OFFICE_1}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Office 1
                </Text>
              </Box>
              <Box cursor="pointer" onClick={() => selectImage(IMAGES.LIVING_ROOM_3)}>
                <Image
                  border={
                    selectedImage === IMAGES.LIVING_ROOM_3 ? '2px solid #2182EB' : 0
                  }
                  boxShadow={
                    selectedImage === IMAGES.LIVING_ROOM_3 ? '0px 3px 20px #1DABFC6B' : ''
                  }
                  borderRadius="12px"
                  maxW={'190px'}
                  src={`/locations/${IMAGES.LIVING_ROOM_3}.png`}
                />
                <Text fontSize="xs" fontWeight="bold" mt={'5px'} color="text.dark">
                  Living room 3
                </Text>
              </Box>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter mt="20px" justifyContent={'flex-start'}>
          <Button w="220px" colorScheme="blue" onClick={() => setIsOpen(false)} mr={3}>
            Add
          </Button>
          <Button
            w="220px"
            variant="ghost"
            onClick={() => {
              setSelectedImage(null)
              setIsOpen(false)
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ImageModal
