import { pythonApi } from '../../../app/services/api/api'
import {
  getDashboardLocationMockData,
  getDashboardOverviewMockData,
  getDeviceByIdMock,
  getDeviceReportDataMock,
} from '../../../mockData/dashboard'
import {
  Defects,
  DeviceReport,
  Location,
  TopFiveDevice,
  DashBoardRequest,
  Periods,
  DeviceData,
  DashboardOverview,
  DashboardLocation,
  CurrentDataRequest,
  CurrentLocationsDataRequest,
  LocationChart,
} from '../types'

const PeriodMapping: { [k: string]: number } = {
  [Periods.DAY]: 1,
  [Periods.WEEK]: 12,
  [Periods.MONTH]: 24,
  [Periods.YEAR]: 360,
}

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentData: builder.query<any, CurrentDataRequest>({
      query: ({ deviceIds, fields }) => {
        const searchParams = new URLSearchParams()
        deviceIds.forEach((id) => searchParams.append('device_id', id.toString()))
        fields.forEach((field) => searchParams.append('fields', field))
        return {
          url: `/current/data/device/?${searchParams}`,
          method: 'GET',
        }
      },
    }),
    getCurrentLocationsData: builder.query<any, CurrentLocationsDataRequest>({
      query: ({ locationIds }) => {
        const searchParams = new URLSearchParams()

        locationIds.forEach((id) => searchParams.append('location_id', id.toString()))

        return {
          url: `/current/data/locations/?${searchParams.toString()}`,
          method: 'GET',
        }
      },
    }),
    getDashboardOverview: builder.query<DashboardOverview, DashBoardRequest>({
      query: ({ from, to, last_hour = false }) => ({
        url: `/v2/dashboard-overview/?from_date=${from}&to_date=${to}&last_hour=${last_hour}&paging=false`,
        method: 'GET',
      }),
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getDashboardOverviewMockData()

        return mockResponse ? mockResponse : response
      },
    }),
    getDashboardLocation: builder.query<DashboardLocation, DashBoardRequest>({
      query: ({ from, to, location }) => ({
        url: `/v2/dashboard-location/${location}/?from_date=${from}&to_date=${to}&limit=35`,
        method: 'GET',
      }),
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getDashboardLocationMockData(arg.location)

        return mockResponse ? mockResponse : response
      },
    }),

    // UNUSED
    getLocations: builder.query<Location.Response, DashBoardRequest>({
      query: ({ from, to, last_hour = false }) => ({
        url: `/locations-data/?from_date=${from}&to_date=${to}&last_hour=${last_hour}&paging=false`,
        method: 'GET',
      }),
    }),

    // UNUSED
    getLocationsData: builder.query<Location.Data, DashBoardRequest>({
      query: ({ from, to, location }) => ({
        url: `/locations-data/${location}/?from_date=${from}&to_date=${to}`,
        method: 'GET',
      }),
    }),

    getAllClimateDevices: builder.query({
      queryFn: async (params, queryApi, extraOptions, baseQuery) => {
        try {
          const { locationIds, from, to } = params
          if (!locationIds) {
            return { data: [] }
          }

          const responses = await Promise.all(
            locationIds.map((location) =>
              baseQuery(
                `/locations-data/${location.id}/dashboard/?from_date=${from}&to_date=${to}&limit=35&exclude_hidden=true`,
              ),
            ),
          )

          const result = {
            data: responses
              .reduce((agg: any, response: any) => agg.concat(response.data), [])
              // @ts-ignore
              .filter((device) => device.category === 'climate_control'),
          }

          return result
        } catch (error) {
          return { error: error.message || 'Could not fetch data' }
        }
      },
    }),

    // UNUSED
    getTopFiveDevices: builder.query<TopFiveDevice.Data[], DashBoardRequest>({
      //   query: () => "top/five",
      query: ({ from, to, location, isFavorite = false }) => ({
        url: `/locations-data/${location}/devices/?from_date=${from}&to_date=${to}&favorites=${isFavorite}&exclude_hidden=true`,
        method: 'GET',
      }),
    }),

    getLocationChartData: builder.query<LocationChart.Data[], LocationChart.Request>({
      query: ({ from, to, period = 'day', location }) => ({
        url: `/v2/dashboard-location/${location}/data/?from_date=${from}&to_date=${to}&period=${PeriodMapping[period]}`,
        method: 'GET',
      }),
    }),

    getDeviceReportData: builder.query<DeviceReport.Response, DeviceReport.Request>({
      query: ({ from, to, period = 'day', device }) => ({
        url: `/data/device/${device}/?from_date=${from}&to_date=${to}&period=${PeriodMapping[period]}`,
        method: 'GET',
      }),
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getDeviceReportDataMock(arg.from, arg.to)

        return mockResponse ? mockResponse : response
      },
    }),
    getDashboardNotifications: builder.query<Defects.Response, void>({
      query: () => 'defects/?search=&page=1&ordering=-start_time',
    }),
    getDeviceById: builder.query<DeviceData.Data, { id: string }>({
      query: ({ id }) => `devices/${id}/`,
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getDeviceByIdMock(arg.id)

        return mockResponse ? mockResponse : response
      },
    }),

    getSensiboDeviceData: builder.query<any, { id: string }>({
      query: ({ id }) => `sensibo/${id}/device-data/`,
    }),
    getACState: builder.query<any, { id: string }>({
      query: ({ id }) => `sensibo/${id}/ac-state/`,
    }),
    setACState: builder.mutation<any, any>({
      query: ({ id, acState }) => {
        return {
          url: `sensibo/${id}/ac-state/set/`,
          method: 'POST',
          body: acState,
        }
      },
      onQueryStarted: async (arg, { queryFulfilled, dispatch, getState }) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getACState', { id: arg.id }, (draft) => {
            Object.assign(draft, arg.acState)
          }),
        )

        try {
          await queryFulfilled
        } catch {
          // If mutation fails, undo the optimistic update
          patchResult.undo()
        }
      },
    }),
    patchACState: builder.mutation<any, any>({
      query: ({ id, acState }) => {
        return {
          url: `sensibo/${id}/ac-state/patch/`,
          method: 'PATCH',
          body: acState,
        }
      },
    }),
    getLocationsDevicesByLocationIds: builder.query<
      any,
      { locationIds: number[]; from?: string; to?: string }
    >({
      query: ({ locationIds, from, to }) => ({
        url: 'base-report/',
        method: 'POST',
        body: {
          location_ids: locationIds,
          from_date: from,
          to_date: to,
          exclude_hidden: true,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetLocationsQuery,
  useGetCurrentLocationsDataQuery,
  useGetDashboardOverviewQuery,
  useGetLocationsDataQuery,
  useGetTopFiveDevicesQuery,
  useGetDeviceReportDataQuery,
  useGetLocationChartDataQuery,
  useGetDeviceByIdQuery,
  useGetDashboardNotificationsQuery,
  useGetDashboardLocationQuery,
  useGetCurrentDataQuery,
  useGetSensiboDeviceDataQuery,
  useGetACStateQuery,
  useSetACStateMutation,
  usePatchACStateMutation,
  useGetAllClimateDevicesQuery,
} = extendedApi

export const setMultipleACStates = async (dispatch, { ids, acState }) => {
  const results = await Promise.all(
    ids.map(async (id) => {
      const action = extendedApi.endpoints.setACState.initiate({ id, acState })
      const patchResult = dispatch(
        extendedApi.util.updateQueryData('getACState', { id }, (draft) => {
          Object.assign(draft, acState)
        }),
      )

      try {
        await dispatch(action).unwrap()
      } catch {
        patchResult.undo()
      }
    }),
  )
  return results
}
