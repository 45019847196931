import { DeleteIcon, EditIcon, StarIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Image,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLazyDeleteLocationQuery } from '../api/locationsApi'

interface CardProps {
  property?: {
    imageUrl: string
    imageAlt: string
    location: string
    id: number
  }
  refetchLocations?: any
}

export const Card = ({
  property = {
    imageUrl: 'https://bit.ly/2Z4KKcF',
    imageAlt: 'Rear view of modern home with pool',
    location: 'Apt1 park Vladi',
    id: 0,
  },
  refetchLocations,
}: CardProps) => {
  const [trigger] = useLazyDeleteLocationQuery()
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const cancelRef = useRef(null)

  const handleDelete = async () => {
    await trigger({ id: property.id })
      .unwrap()
      .then(() => {
        refetchLocations()
        setOpenDeleteDialog(false)
      })
  }

  return (
    <Box
      width={{
        sm: 'calc(100% - 24px)',
        md: 'calc(33.333% - 24px)',
        lg: 'calc(20% - 24px)',
      }}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Link to={`/locations/${property.id}/devices`}>
        <Image src={property.imageUrl} alt={property.imageAlt} w="100%" />
      </Link>
      <Box display="flex" flexDirection="column" alignContent="flex-start" p={6}>
        <Box fontSize="xl" fontWeight="bold">
          {property.location}
        </Box>
        <HStack spacing={4} fontSize="sm" color="gray" mt={2}>
          <Link to={`/locations/${property.id}/edit`}>
            <Button variant="unstyled" rightIcon={<EditIcon />}>
              Edit
            </Button>
          </Link>
          <Button
            variant="unstyled"
            onClick={() => setOpenDeleteDialog(true)}
            rightIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          <AlertDialog
            isOpen={openDeleteDialog}
            leastDestructiveRef={cancelRef}
            onClose={() => setOpenDeleteDialog(false)}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Location
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={() => setOpenDeleteDialog(false)}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>
        <HStack spacing={4} mt={8}>
          <Box w={'100%'} textAlign="center">
            <Link to={`/locations/${property.id}/devices`}>
              <Button colorScheme="button.primary" w={'100%'} h={10}>
                Devices
              </Button>
            </Link>
          </Box>
          {/* <Box w={'50%'} textAlign='center'>
            <Button colorScheme='button' variant='outline' w={'100%'} h={10}>Report</Button>
          </Box> */}
        </HStack>
      </Box>
    </Box>
  )
}
