export const mockUsers = ['vboyadjiev@aiotcloud.app']

export const isMockUser = (username) => mockUsers.some((user) => user === username)

export const setMockUser = (username) => {
  if (isMockUser(username) && process.env.REACT_APP_ENABLE_MOCK_USER) {
    window.localStorage.setItem('username', username)
  }
}

export const getUserMockData = (username, mockObject) =>
  mockObject.find((mock) => mock.user === username)
