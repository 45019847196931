import { Box, Button, Flex, Show } from '@chakra-ui/react'
import { isEqual } from 'date-fns'
import {
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'
import React from 'react'
import { PredefinedDatesProps } from './types'

const today = startOfToday()

const predefinedDatesList = [
  {
    name: 'Last 24h',
    dates: {
      start: subDays(today, 1),
      end: today,
    },
  },
  {
    name: 'Last week',
    dates: {
      start: subDays(today, 7),
      end: today,
    },
  },
  {
    name: 'Last month',
    dates: {
      start: startOfMonth(today),
      end: today,
    },
  },
  {
    name: 'Last 3 months',
    dates: {
      // start: startOfMonth(subMonths(today, 3)),
      start: subMonths(today, 3),
      end: today,
    },
  },
  {
    name: 'Last 6 months',
    dates: {
      // start: startOfMonth(subMonths(today, 6)),
      start: subMonths(today, 6),
      end: today,
    },
  },
  {
    name: 'This year',
    dates: {
      start: startOfYear(today),
      end: today,
    },
  },
  {
    name: 'Last year',
    dates: {
      start: startOfYear(subYears(today, 1)),
      end: startOfDay(endOfYear(subYears(today, 1))),
    },
  },
]
const PredefinedDates = ({
  selectedStart,
  selectedEnd,
  setStartDate,
  setEndDate,
  setCurrentMonth,
}: PredefinedDatesProps) => {
  const handleClick = ({ start, end }: { start: Date; end: Date }) => {
    setStartDate(start)
    setEndDate(end)
    setCurrentMonth(format(end, 'MMM-yyyy'))
  }
  return (
    <Show above="md">
      <Box m="4">
        <Flex flexDirection="column" rowGap="3">
          {predefinedDatesList.map(({ name, dates }) => {
            const isSelected =
              isEqual(dates.start, selectedStart!) && isEqual(dates.end, selectedEnd!)
            return (
              <Button
                style={{ boxShadow: 'none' }}
                key={name}
                outline="0"
                boxShadow="none"
                textAlign="start"
                onClick={() => handleClick(dates)}
                variant="unstyled"
                fontSize="13px"
                fontWeight="400"
                color={isSelected ? '#157FEE' : 'text.secondary'}
                p="4"
                py="2"
              >
                {name}
              </Button>
            )
          })}
        </Flex>
      </Box>
      <Box mx="4" my="4" border="1px" borderColor="#DEDFE2" />
    </Show>
  )
}

export default PredefinedDates
