import {
  Analysis,
  Compare,
  Device,
  Location,
  ConsumptionBreakDownRequest,
  ConsumptionBreakDownResponse,
} from './types'

import { pythonApi } from '../../../app/services/api/api'
import { v4 as uuid } from 'uuid'
import {
  compareDevicesMockData,
  getAnalysisConsumptionBreakdownMockData,
  getDevicesMockData,
  getHeatMapMock,
  getLocationsMock,
} from '../../../mockData/analysis'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalysisConsumptionBreakdown: builder.query<
      ConsumptionBreakDownResponse,
      ConsumptionBreakDownRequest
    >({
      query: (body) => {
        return {
          url: 'analysis/devices-period-consumption/',
          method: 'POST',
          body,
        }
      },
      transformResponse: (response: Device.Response, _meta, arg) => {
        // MOCK DATA START
        const mockResponse = getAnalysisConsumptionBreakdownMockData(arg.location_id)

        const result = mockResponse ? mockResponse : response

        // MOCK DATA END

        // @ts-ignore
        return result
      },
    }),
    getAnalysisLocations: builder.query<Location.Response, void>({
      // query: () => "locations",
      query: () => 'locations/?&paging=false&short=false',
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getLocationsMock()
        return mockResponse ? mockResponse : response
      },
    }),
    getDeviceHeatMap: builder.query<Analysis.Response, Analysis.Request>({
      query: (args) => {
        if (args.device) {
          return {
            url: 'analysis/',
            params: args,
          }
        } else {
          const { device, ...analysisLocationArgs } = args

          return {
            url: 'analysis/location/',
            params: analysisLocationArgs,
          }
        }
      },
      transformResponse: (response: Analysis.QueryResponse, _meta, arg) => {
        const result = response

        Object.keys(result?.results).map((key) => {
          for (const date in result?.results[key]) {
            result?.results[key][date].forEach((hourData) => {
              hourData.idx = `${date}-${hourData.hour}-${hourData[key]}`
            })
          }
        })

        const calculateGeneratedPower = (data) => {
          let generatedPower = 0
          for (const date in data.generated_power) {
            for (const reading of data.generated_power[date]) {
              if (reading.generated_power > 0) {
                generatedPower += reading.generated_power
              }
            }
          }
          return generatedPower
        }

        const totalGeneratedPower = calculateGeneratedPower(result?.results)
        const isGenerator =
          result.total_consumption === 0 && totalGeneratedPower > 0 ? true : false

        return {
          totalConsumption: result.total_consumption,

          //TODO: replace this when result returns total_generated_power
          totalGeneratedPower: totalGeneratedPower,
          totalPrice: (result.total_consumption * response.price)?.toFixed(2),
          price: result.price,
          isGenerator: isGenerator,
          consumptionBorders: result?.scale_borders?.has_borders
            ? {
                min: result.scale_borders.scale_min,
                max: result.scale_borders.scale_max,
              }
            : undefined,
          results: result?.results,
        }
      },
    }),
    getDevices: builder.query<Device.Response, { locationId: string | number }>({
      query: ({ locationId }) => ({
        url: `/devices/?location=${locationId}&exclude_hidden=true`,
        method: 'GET',
      }),

      transformResponse: (response: Device.Response, _meta, arg) => {
        // MOCK DATA START
        const mockResponse = getDevicesMockData(arg.locationId)
        const result = mockResponse ? mockResponse : response

        // MOCK DATA END

        // @ts-ignore
        const results = [...result.results]
        results?.sort((a, b) => Number(b.is_main) - Number(a.is_main))
        return { ...result, results }
      },
    }),
    getCompareDevices: builder.query<Compare.Response, Compare.Request>({
      query: (args) => {
        return {
          url: '/data/compare_devices/',
          method: 'POST',
          body: args,
        }
      },
      transformResponse: (response: Compare.Response, _meta, arg) => {
        // MOCK DATA START

        const mockResponse = compareDevicesMockData(arg.date_ranges, arg.devices)

        const result = mockResponse
          ? {
              ...response,
              ...mockResponse,
            }
          : response

        // MOCK DATA END

        // @ts-ignore
        result.results.sort((a, b) => Number(b.is_main) - Number(a.is_main))
        return result
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAnalysisConsumptionBreakdownQuery,
  useGetDeviceHeatMapQuery,
  useGetAnalysisLocationsQuery,
  useGetDevicesQuery,
  useGetCompareDevicesQuery,
} = extendedApi
