import { createIcon } from '@chakra-ui/react'

export const ReportsIcon = createIcon({
  displayName: 'BarIcon',
  viewBox: '0 0 29 28',

  path: (
    <g id="file-text">
      <path
        id="Icon"
        d="M16.5827 2.33325H7.24935C6.63051 2.33325 6.03702 2.57908 5.59943 3.01667C5.16185 3.45425 4.91602 4.04775 4.91602 4.66659V23.3333C4.91602 23.9521 5.16185 24.5456 5.59943 24.9832C6.03702 25.4208 6.63051 25.6666 7.24935 25.6666H21.2494C21.8682 25.6666 22.4617 25.4208 22.8993 24.9832C23.3369 24.5456 23.5827 23.9521 23.5827 23.3333V9.33325M16.5827 2.33325L23.5827 9.33325M16.5827 2.33325V9.33325H23.5827M18.916 15.1666H9.58268M18.916 19.8333H9.58268M11.916 10.4999H9.58268"
        fill="none"
        stroke="currentColor" // Set the stroke color to black
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
})
