import { Button, Flex } from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Analysis, Device, Location } from '../api/types'
import { useGetAnalysisLocationsQuery, useGetDevicesQuery } from '../api/analysisApi'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'

import SingleSelect from '../../reports/components/SingleSelect'
import DateRangeInputs from '../../../app/components/DateRangePicker/DateRangeInputs'
import { format } from 'date-fns'
import { startOfDay } from 'date-fns'

const AnalysisForm = ({ dataAnalysisPage }: { dataAnalysisPage?: boolean }) => {
  const {
    data: { results: locations } = { results: [] },
    isLoading: locationsLoading,
    isError: locationsError,
  } = useGetAnalysisLocationsQuery()

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Analysis.FormFields>()

  const [searchParams] = useSearchParams()

  const locationId = watch('locationId')
  const startDate = watch('startDate') || null
  const endDate = watch('endDate') || null
  const navigate = useNavigate()

  const { data: { results: devices } = { results: [] }, isLoading: devicesLoading } =
    useGetDevicesQuery(
      {
        locationId,
      },
      {
        skip: !locationId,
      },
    )

  useEffect(() => {
    const location = searchParams.get('location')
    const fromDate = searchParams.get('from_date')
    const toDate = searchParams.get('to_date')
    const device = searchParams.get('device')
    if (fromDate && toDate) {
      setValue('startDate', startOfDay(new Date(fromDate)))
      setValue('endDate', startOfDay(new Date(toDate)))
    }
    if (locations && location) {
      setValue('locationId', parseInt(location))
    }

    if (devices && device) {
      setValue('deviceId', parseInt(device))
    }
  }, [])

  const onSubmit = ({
    startDate,
    endDate,
    locationId,
    deviceId,
  }: Analysis.FormFields) => {
    const [startHour, endHour] = [0, 23]

    const searchParams = createSearchParams({
      from_date: format(startDate!, 'yyyy-MM-dd'),
      to_date: format(endDate!, 'yyyy-MM-dd'),
      from_hour: startHour.toString(),
      to_hour: endHour.toString(),
      location: locationId?.toString(),
    })

    if (deviceId) {
      searchParams.append('device', deviceId.toString())
    }
    navigate({
      pathname: dataAnalysisPage ? '' : 'data-analysis',
      search: `?${searchParams.toString()}`,
    })
  }

  const locationsSelect = useMemo(
    () =>
      (locations as Location.Data[]).map(({ id, title }) => ({
        id,
        name: title,
      })),
    [locations],
  )

  return (
    <Flex direction="column" gap={6} as="form" onSubmit={handleSubmit(onSubmit)}>
      <Flex w="380px">
        {/* @ts-ignore */}
        <DateRangeInputs
          register={register}
          watch={watch}
          setValue={setValue}
          errors={errors}
          selectedStartName="startDate"
          selectedEndName="endDate"
          startDate={startDate}
          endDate={endDate}
        />
      </Flex>

      <SingleSelect
        key="location-id"
        items={locationsSelect}
        isDisabled={!startDate && !endDate}
        {...register('locationId')}
        setSelectedOption={(value) => {
          setValue('locationId', value as number)
        }}
        selectedOption={locationId}
        header="Select location"
        tooltip={{
          label: 'Please select period',
          hasArrow: true,
          placement: 'top',
        }}
      />
      <SingleSelect
        key="devices"
        isDisabled={!locationId}
        items={(devices as Device.Data[]).map(({ id, title, is_main }) => ({
          id,
          name: title,
          isMain: is_main,
        }))}
        {...register('deviceId')}
        setSelectedOption={(value) => setValue('deviceId', value as number)}
        selectedOption={watch('deviceId')}
        header="Select Device (optional)"
        tooltip={{
          label: 'Please select location',
          hasArrow: true,
          placement: 'top',
        }}
      />
      <Button
        mt={8}
        alignSelf="flex-start"
        colorScheme="button.primary"
        type="submit"
        disabled={!locationId}
        w={240}
        h={12}
      >
        Analyse
      </Button>
    </Flex>
  )
}

export default AnalysisForm
