import {
  Box,
  Divider,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Tabs,
  VStack,
  HStack,
  Center,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel'
import { Icon } from '../../../utils/Icon'
import details from '../../../assets/analysis/details.svg'
import compare from '../../../assets/analysis/compare.svg'
import CompareForm from '../forms/CompareForm'
import AnalysisForm from '../forms/AnalysisForm'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

enum DIRECTION {
  COL = 'column',
  ROW = 'row',
}

const AnalysisHomeForm = ({ dataAnalysisPage }: { dataAnalysisPage?: boolean }) => {
  const compareText = useBreakpointValue({
    base: 'Compare',
    md: 'Compare device(s)',
  })

  const direction = useBreakpointValue({
    base: DIRECTION.COL,
    laptopM: DIRECTION.ROW,
  })

  const isCarousel = useBreakpointValue({
    base: true,
    desktop: false,
  })

  const optionsContent = isCarousel ? (
    <Box
      // w={{base: "full", lg: "auto"}}
      boxShadow="lg"
      rounded="lg"
      borderWidth={1}
      bgColor="white"
      px={5}
      h={350}
    >
      <Carousel
        infiniteLoop
        showStatus={false}
        showArrows={false}
        autoPlay={true}
        showIndicators={false}
      >
        <VStack>
          <Icon src={details} h={250} />
          <Text fontSize="md" fontWeight="bold">
            Detail Analysis
          </Text>
          <Text fontSize="xs">Check the consumption</Text>
        </VStack>
        <VStack>
          <Icon src={compare} h={250} />
          <Text fontSize="md" fontWeight="bold">
            Compare locations and devices
          </Text>
          <Text fontSize="xs">Check the consumption</Text>
        </VStack>
      </Carousel>
    </Box>
  ) : (
    <VStack
      boxShadow="lg"
      rounded="lg"
      borderWidth={1}
      bgColor="white"
      px={10}
      pt={6}
      pb={12}
      alignSelf="flex-start"
    >
      <Box alignSelf="flex-start">
        <Text>Options</Text>
      </Box>
      <HStack spacing={12} px={14}>
        <VStack>
          <Icon src={details} />
          <Text fontSize="md" fontWeight="bold">
            Detail Analysis
          </Text>
          <Text fontSize="xs">Check the consumption</Text>
        </VStack>
        <Center height={40}>
          <Divider orientation="vertical" height="full" />
        </Center>
        <VStack>
          <Icon src={compare} />
          <Text fontSize="md" fontWeight="bold">
            Compare locations and devices
          </Text>
          <Text fontSize="xs">Check the consumption</Text>
        </VStack>
      </HStack>
    </VStack>
  )

  return (
    <Stack direction={direction} w="full" mt={5} justify="space-between">
      <VStack
        boxShadow="lg"
        rounded="lg"
        borderWidth="1px"
        bgColor="white"
        px={{ base: 2, md: 5 }}
        w={{ base: 'full', lg: 'auto' }}
        flexShrink={1}
      >
        <Tabs w="full" mt={5} colorScheme="gray">
          <TabList w="full">
            <Tab
              fontSize={{ base: 'md', md: '2xl' }}
              whiteSpace="nowrap"
              _selected={{
                fontWeight: 'bold',
                boxShadow: 'none',
                color: 'gray.600',
                borderColor: 'currentColor',
              }}
            >
              Heat map
            </Tab>
            <Tab
              fontSize={{ base: 'md', md: '2xl' }}
              whiteSpace="nowrap"
              _selected={{
                fontWeight: 'bold',
                boxShadow: 'none',
                color: 'gray.600',
                borderColor: 'currentColor',
              }}
            >
              {compareText}
            </Tab>
          </TabList>

          <TabPanels w="full" mt={5}>
            <TabPanel px={2}>
              <AnalysisForm dataAnalysisPage={dataAnalysisPage} />
            </TabPanel>
            <TabPanel px={2}>
              <CompareForm />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      {optionsContent}
    </Stack>
  )
}

export default AnalysisHomeForm
