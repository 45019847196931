import Header from '../../../app/components/Header'
import AnalysisHomeForm from '../components/AnalysisHomeForm'

export const Main = () => {
  return (
    <>
      <Header title="Analysis"></Header>
      <AnalysisHomeForm />
    </>
  )
}
