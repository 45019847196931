import { createIcon } from '@chakra-ui/icon'

export const DrawerIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 32.32 18.85',
  path: (
    <>
      <path
        id="Path_1357"
        data-name="Path 1357"
        d="M35.474,12.818H5.847A1.351,1.351,0,0,1,4.5,11.472h0a1.351,1.351,0,0,1,1.347-1.347H35.474a1.351,1.351,0,0,1,1.347,1.347h0A1.351,1.351,0,0,1,35.474,12.818Z"
        transform="translate(-4.5 -10.125)"
      />
      <path
        id="Path_1358"
        data-name="Path 1358"
        d="M35.474,19.568H5.847A1.351,1.351,0,0,1,4.5,18.222h0a1.351,1.351,0,0,1,1.347-1.347H35.474a1.351,1.351,0,0,1,1.347,1.347h0A1.351,1.351,0,0,1,35.474,19.568Z"
        transform="translate(-4.5 -8.795)"
      />
      <path
        id="Path_1359"
        data-name="Path 1359"
        d="M35.474,26.318H5.847A1.351,1.351,0,0,1,4.5,24.972h0a1.351,1.351,0,0,1,1.347-1.347H35.474a1.351,1.351,0,0,1,1.347,1.347h0A1.351,1.351,0,0,1,35.474,26.318Z"
        transform="translate(-4.5 -7.465)"
      />
    </>
  ),
})
