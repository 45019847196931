import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Flex,
  Grid,
  Text,
  Tooltip,
  AccordionPanel,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useCallback, useState } from 'react'
import { Label } from '../../../app/components/Label/Label'
import { CheckCircleIcon } from '../../../assets/icons/CheckCircle'
import Info from '../../../assets/icons/Info'
import { addCommasToNumber } from '../../../utils/helpers'

export const CustomAccordionItem = ({
  index,
  alarm,
  patchAlarmById,
  refetchAllAlarms,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const setNotActive = useCallback(() => {
    patchAlarmById({ id: alarm.id, body: { is_active: false } })
  }, [alarm.id, patchAlarmById])
  return (
    <AccordionItem outline="none" boxShadow="none">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            outline="none"
            boxShadow="none"
            px="0"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              outline: 'none',
              _hover: { bg: isExpanded ? 'none' : 'gray.100' },
              _focus: { boxShadow: 'none' },
              transitionDuration: '0.3s',
            }}
          >
            <Grid templateColumns="repeat(6, 1fr)" gap={6} p={4} key={index} w="full">
              <Flex align="center">
                <AccordionIcon mr="4" />
                <Flex direction="column" textAlign="left">
                  <Text fontSize="14px" fontWeight="700" color="text.dark">
                    {alarm.alarm_type?.name}
                  </Text>
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    color="text.light"
                    textAlign="left"
                  >
                    {format(alarm.timestamp, 'yyyy-MM-dd HH:mm:ss')} • ID:{' '}
                    {alarm.alarm_type?.id}
                  </Text>
                </Flex>
              </Flex>

              <Flex align="center">
                <Text textAlign="left">
                  <Label type={alarm.severity} />
                </Text>
              </Flex>
              <Flex align="center">
                <Text fontSize="14px" fontWeight="700" color="text.dark" textAlign="left">
                  {alarm.location_title}
                </Text>
              </Flex>
              <Flex align="center">
                <Text fontSize="14px" fontWeight="700" color="text.dark" textAlign="left">
                  {alarm.device_title}
                </Text>
              </Flex>
              <Flex align="center">
                <Text fontSize="12px" fontWeight="400" color="text.dark" textAlign="left">
                  {addCommasToNumber(alarm.value)} {alarm.alarm_type?.measurement_type}
                </Text>
              </Flex>
              <Flex gap={6} align="center">
                <Text fontSize="12px" fontWeight="400" color="text.dark">
                  {alarm.alarm_type?.alarm_type}
                </Text>

                <Flex
                  gap="2"
                  opacity={isHovered ? 1 : 0}
                  transition="opacity 0.3s ease-in-out"
                >
                  <Tooltip
                    hasArrow
                    rounded="lg"
                    label={
                      <Box pl="16px" p="12px">
                        <Text fontSize="14px" fontWeight="700" color="white">
                          You may try
                        </Text>

                        <Text fontSize="14px" fontWeight="400" color="white">
                          {alarm.alarm_type?.ai_suggestion}
                        </Text>
                      </Box>
                    }
                    placement="left"
                    bg="icon.strong"
                  >
                    <Flex
                      color="icon.strong"
                      bg="#F7F9FD"
                      rounded="lg"
                      align="center"
                      justifyContent="space-around"
                      boxSize="32px"
                      _hover={{ bg: '#E8EEFD' }}
                    >
                      <Info />
                    </Flex>
                  </Tooltip>

                  <Tooltip
                    hasArrow
                    rounded="md"
                    placement="bottom"
                    bg="heading.dark"
                    label={<Text p="4px">Mark as resolved</Text>}
                  >
                    <Flex
                      color="icon.strong"
                      bg="#F7F9FD"
                      rounded="lg"
                      align="center"
                      justifyContent="space-around"
                      boxSize="32px"
                      _hover={{ bg: '#E8EEFD' }}
                    >
                      <CheckCircleIcon
                        as="button"
                        onClick={(e) => {
                          e.stopPropagation()
                          setNotActive()
                          refetchAllAlarms()
                        }}
                      />
                    </Flex>
                  </Tooltip>
                </Flex>
              </Flex>
            </Grid>
          </AccordionButton>
          <AccordionPanel pb={4} bg="container.background.gray">
            <Flex ml="8" direction="column">
              <Text>Description: {alarm.alarm_type?.description}</Text>
              <Text>
                Maximum Value: {addCommasToNumber(alarm.alarm_type?.limit)}{' '}
                {alarm.alarm_type?.measurement_type}
              </Text>
            </Flex>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}
