import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { DeviceTypeParameter } from '../../../app/services/api/types'

const DeviceType = ({
  param,
  index,
  register,
  setValue,
}: {
  param: DeviceTypeParameter
  index: number
  register: any
  setValue: any
}) => {
  const [notifications, setNotifications] = useState<boolean>(false)

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb="30px">
        <Box>
          <Text fontSize="sm">
            {param.name?.replace(/^./, (str) => str.toUpperCase())}{' '}
            notifications(optional)
          </Text>
          <Text fontSize="0.7rem" color="text.light">
            Notify me when the value of the device exceeds the limits
          </Text>
        </Box>
        <Switch
          id="notifications"
          onChange={() => {
            // remove notification from the form values if it's switch off
            setValue(`notifications.${index}.id`, param.id)
            setNotifications(!notifications)
          }}
        />
      </Box>

      {notifications && (
        <Flex>
          <Input
            type="number"
            placeholder="min value"
            w={200}
            {...register(`notifications.${index}.min`)}
          />
          <Center w={5}>
            <Text>-</Text>
          </Center>
          <Input
            type="number"
            placeholder="max value"
            w={200}
            {...register(`notifications.${index}.max`)}
          />
          <Center ml={3}>
            <InfoOutlineIcon color="gray" />
            <Container w={150} p={2} fontSize="0.6rem" color="text.light" centerContent>
              Suggested values: 12-14% min and 50-54% max
            </Container>
          </Center>
        </Flex>
      )}
      <Divider mb="20px" mt="10px" />
    </Box>
  )
}

export default DeviceType
