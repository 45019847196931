import { createIcon } from '@chakra-ui/icons'

const BellIcon = createIcon({
  displayName: 'BellIcon',
  viewBox: '0 0 24 25',
  path: (
    <g id="Icons/bell">
      <path
        id="Vector"
        d="M18 8.35254C18 6.76124 17.3679 5.23512 16.2426 4.1099C15.1174 2.98468 13.5913 2.35254 12 2.35254C10.4087 2.35254 8.88258 2.98468 7.75736 4.1099C6.63214 5.23512 6 6.76124 6 8.35254C6 15.3525 3 17.3525 3 17.3525H21C21 17.3525 18 15.3525 18 8.35254Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Vector_2"
        d="M13.73 21.3525C13.5542 21.6556 13.3019 21.9072 12.9982 22.0821C12.6946 22.2569 12.3504 22.349 12 22.349C11.6496 22.349 11.3054 22.2569 11.0018 22.0821C10.6982 21.9072 10.4458 21.6556 10.27 21.3525"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
})

export default BellIcon
