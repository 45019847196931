import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Heading,
  HStack,
  Text,
  Skeleton,
  Stack,
} from '@chakra-ui/react'
import Chart from '../../../app/components/LineChart'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { useGetCompareDevicesQuery } from '../api/analysisApi'
import { ArrowBackIcon } from '@chakra-ui/icons'
import Header from '../../../app/components/Header'
import { calculateCO2Emissions, formatDateForCompare, numberWithCommas } from '../utils'
import { KILOWATT_HOUR } from '../../../utils/consts'

const CompareData = () => {
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const { date_ranges, devices, shouldCompareOneDevice } = location.state as {
    date_ranges: any
    devices: any
    shouldCompareOneDevice: boolean
  }

  const {
    data: { results: devicesData } = { results: [] },
    isSuccess: compareDataSuccess,
  } = useGetCompareDevicesQuery({
    date_ranges,
    devices,

    period: searchParams.get('period') || '24',
  })

  const chartData = devicesData.map(({ title, datas }, idx) => ({
    title: shouldCompareOneDevice
      ? `${formatDateForCompare(date_ranges[idx].from_date)} - ${formatDateForCompare(
          date_ranges[idx].to_date,
        )}`
      : title,
    datas: datas.map((d) => ({
      ...d,
      carbon_footprint: calculateCO2Emissions(d.consumption, d.carbon_intensity),
    })),
  }))
  const chartConfigs: {
    label: string
    field: 'current' | 'consumption' | 'carbon_footprint'
  }[] = Array.from([
    // { label: 'Current Usage', field: 'current' },
    { label: 'Total Consumption', field: 'consumption' },
    { label: 'Carbon Footprint', field: 'carbon_footprint' },
  ])
  const navigate = useNavigate()

  return (
    <>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          <Stack direction="row">
            <ArrowBackIcon
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(-1)
              }}
            />
            <Text>Compare Data</Text>
          </Stack>
        </Heading>
      </Header>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems="flex-start"
        mt={5}
        px={4}
      >
        <Flex
          direction="column"
          boxShadow="lg"
          rounded="lg"
          borderWidth="1px"
          bgColor="white"
          w="2xl"
          p={4}
        >
          <Skeleton isLoaded={compareDataSuccess}>
            {devicesData.map(
              (
                { device_type, title, location, carbon_intensity, total_consumption },
                idx,
              ) => (
                <div key={idx}>
                  <Box>
                    <Box>
                      <Flex alignItems="center">
                        {device_type?.image && (
                          <Image w={9} h={8} src={`${device_type?.image}`} />
                        )}
                        <Flex direction="column" ml={4}>
                          <Text fontSize="sm" fontWeight="bold" color="text.light">
                            Device
                          </Text>
                          <Heading
                            as="h2"
                            fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}
                          >
                            {title}
                          </Heading>
                          <Text fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}>
                            {location?.title}
                          </Text>
                          {shouldCompareOneDevice ? (
                            <Text fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}>
                              {`${formatDateForCompare(
                                date_ranges[idx].from_date,
                              )} - ${formatDateForCompare(date_ranges[idx].to_date)}`}
                            </Text>
                          ) : null}
                        </Flex>
                      </Flex>
                    </Box>
                    <HStack spacing={8} mt={10}>
                      {/* <Flex direction="column">
                        <Heading
                          as="h4"
                          fontSize={{ base: 'xs', md: 'sm' }}
                          color="text.light"
                        >
                          Current Usage
                        </Heading>
                        <Skeleton isLoaded={compareDataSuccess}>
                          <Heading
                            as="h3"
                            fontSize={{ base: 'sm', md: 'md', lg: '2xl' }}
                            fontWeight={{ base: 'normal', lg: 'bold' }}
                            mt={3}
                          >
                            {current_usage?.toFixed(2)} kW
                          </Heading>
                        </Skeleton>
                      </Flex> */}
                      <Flex direction="column">
                        <Heading
                          as="h4"
                          fontSize={{ base: 'xs', md: 'sm' }}
                          color="text.light"
                        >
                          Carbon Footprint
                        </Heading>
                        <Skeleton isLoaded={compareDataSuccess}>
                          <Heading
                            as="h3"
                            fontSize={{ base: 'sm', md: 'md', lg: '2xl' }}
                            fontWeight={{ base: 'normal', lg: 'bold' }}
                            mt={3}
                          >
                            {numberWithCommas(
                              calculateCO2Emissions(total_consumption, carbon_intensity),
                            )}
                            kg
                          </Heading>
                        </Skeleton>
                      </Flex>
                      <Flex direction="column">
                        <Heading
                          as="h4"
                          fontSize={{ base: 'xs', md: 'sm' }}
                          color="text.light"
                        >
                          Total Consumption for the period
                        </Heading>
                        <Skeleton isLoaded={compareDataSuccess}>
                          <Heading
                            as="h3"
                            fontSize={{ base: 'sm', md: 'md', lg: '2xl' }}
                            fontWeight={{ base: 'normal', lg: 'bold' }}
                            mt={3}
                          >
                            {total_consumption?.toFixed(2)} {KILOWATT_HOUR}
                          </Heading>
                        </Skeleton>
                      </Flex>
                    </HStack>
                  </Box>
                  <Center w="full" my={10}>
                    <Divider orientation="horizontal" w="full" />
                  </Center>
                </div>
              ),
            )}
          </Skeleton>
        </Flex>

        <Flex
          flexDirection="column"
          w="4xl"
          boxShadow="lg"
          rounded="lg"
          borderWidth="1px"
          p={5}
          pt={0}
          bgColor="white"
        >
          {chartConfigs.map(({ label, field }, idx) => (
            <Box
              w="full"
              // h="md"
              boxShadow="lg"
              rounded="lg"
              borderWidth="1px"
              bgColor="white"
              key={idx}
              px={7}
              py={15}
              mt={8}
            >
              <Skeleton isLoaded={compareDataSuccess}>
                <Chart
                  key={idx}
                  config={[[field, label]]}
                  period={searchParams.get('period') || 'week'}
                  deviceData={chartData}
                  chartTitle={label}
                  sameDevice={shouldCompareOneDevice}
                />
              </Skeleton>
            </Box>
          ))}
        </Flex>
      </Stack>
    </>
  )
}

export default CompareData
