import { createIcon } from '@chakra-ui/icons'

export const Download = createIcon({
  displayName: 'Download',
  viewBox: '0 0 24 24',
  path: (
    <g id="Leading-icon">
      <path
        id="Vector"
        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none" // Set fill to none to make it transparent
      />
      <path
        id="Vector_2"
        d="M7 10L12 15L17 10"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none" // Set fill to none to make it transparent
      />
      <path
        id="Vector_3"
        d="M12 15V3"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none" // Set fill to none to make it transparent
      />
    </g>
  ),
})
