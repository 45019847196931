import { ArrowBackIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../../app/components/Header'
import { KILOWATT_HOUR } from '../../utils/consts'
import { IMAGES } from './AddLocation'
import { useEditLocationMutation, useGetLocationQuery } from './api/locationsApi'
import ImageModal from './components/ImageModal'
import Loading from './components/Loading'

const EditLocation = () => {
  const { id } = useParams()

  const [ownImage, setOwnImage] = useState<any>(null)
  const [pureImage, setPureImage] = useState<any>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<IMAGES | null>(null)

  const { data: locationData, isFetching: locationDataFetching } = useGetLocationQuery({
    id: parseInt(id || '0'),
  })

  const navigate = useNavigate()
  const [editLocation, { isLoading, isError }] = useEditLocationMutation()

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (locationData) {
      setValue('name', locationData?.title)
      setValue('price', locationData?.price)
      if (locationData?.description) {
        setSelectedImage(locationData.description as IMAGES)
      }
      if (locationData?.image) {
        setOwnImage({
          preview: locationData.image,
        })
      }
    }
  }, [locationData])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setPureImage(acceptedFiles[0])
      setOwnImage(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        )[0],
      )
    },
  })

  async function onSubmit(values: any) {
    const formData = new FormData()
    formData.append('title', values.name)
    formData.append('price', values.price)
    if (selectedImage) {
      formData.append('description', selectedImage)
    }

    if (pureImage) {
      formData.append('image', pureImage)
    }

    await editLocation({ id, body: formData })
      .unwrap()
      .then((res) => {
        navigate('/locations')
      })
  }

  return (
    <>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold" pl={{ base: 6, lg: 0 }}>
          <Stack
            _hover={{ cursor: 'pointer' }}
            direction="row"
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBackIcon />
            <Text>Locations</Text>
          </Stack>
        </Heading>
      </Header>
      <Stack
        direction="column"
        boxShadow="lg"
        rounded="lg"
        mt={4}
        borderWidth={1}
        w="100%"
        maxWidth="2xl"
        px={6}
        py={8}
        mb={20}
        spacing={8}
        mx="auto"
      >
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          Edit location
        </Heading>
        <VStack spacing={6} as="form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.name !== undefined} color="text.light">
            <Input
              placeholder="Enter location name *"
              id="name"
              color="#000"
              {...register('name', {
                required: '* Required field',
              })}
            />
            <FormErrorMessage>
              {(errors.name?.message as React.ReactNode) ?? null}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.price !== undefined} color="text.light">
            <Input
              type="text"
              placeholder={`Enter supplier base electricity price (BGN / ${KILOWATT_HOUR})*`}
              id="price"
              color="#000"
              {...register('price', {
                required: '* Required field',
              })}
            />
            <FormErrorMessage>
              {(errors.price?.message as React.ReactNode) ?? null}
            </FormErrorMessage>
          </FormControl>
          <FormControl color="text.dark">
            <Text mb={2}>Add image (optional)</Text>
            {ownImage || selectedImage ? (
              <HStack alignItems={'flex-start'}>
                <Box>
                  <Image
                    width={'250px'}
                    src={ownImage ? ownImage.preview : `/locations/${selectedImage}.png`}
                    onLoad={() => {
                      if (ownImage) {
                        URL.revokeObjectURL(ownImage.preview)
                      }
                    }}
                  />
                </Box>
                <VStack alignItems={'flex-start'} pl={4}>
                  <Button
                    onClick={() => {
                      if (ownImage) {
                        setOwnImage(null)
                      } else {
                        setIsOpen(true)
                      }
                    }}
                    variant="unstyled"
                    rightIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      if (ownImage) {
                        setOwnImage(null)
                      } else {
                        setSelectedImage(null)
                      }
                    }}
                    variant="unstyled"
                    rightIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </VStack>
              </HStack>
            ) : (
              <>
                <Box
                  h="130px"
                  borderRadius="10px"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  border="1px dashed #dedfe2"
                  background="#F7F7F7"
                  cursor="pointer"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {
                    <Text fontSize="16px" color="#DEDFE2">
                      Drag & drop or
                      <Button
                        background="transparent"
                        border="0"
                        pl="2"
                        color="#9B9B9B"
                        textDecor="underline"
                        outline="none"
                        _hover={{
                          background: 'transparent',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setIsOpen(true)
                        }}
                      >
                        browse in library
                      </Button>
                    </Text>
                  }
                </Box>
              </>
            )}
          </FormControl>
          {isError && (
            <Text alignSelf="flex-start" color="red.400">
              There is some error, please try again later
            </Text>
          )}
          <Button
            colorScheme="button.primary"
            size="lg"
            w={60}
            h={12}
            alignSelf="flex-start"
            mt={8}
            type="submit"
            rightIcon={<Loading isLoading={isLoading} />}
          >
            Edit
          </Button>
        </VStack>

        <ImageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
        />
      </Stack>
    </>
  )
}

export default EditLocation
