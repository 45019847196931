import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { TileProps } from './types'

export const Tile: React.FC<TileProps> = ({
  name,
  value,
  percentage,
  setActiveName,
  doughnutChartColor,
}) => {
  return (
    <Box
      _hover={{ border: `1px solid ${doughnutChartColor}` }}
      border="1px"
      borderColor="#F5F6F7"
      width="100%"
      height="100%"
      padding="12px"
      background="#F5F6F7"
      borderRadius="12px"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      gap={2.5} // Approximation of 10px in Chakra's scale
      display="flex"
      onMouseEnter={() => {
        setActiveName(name)
      }}
    >
      <Text
        alignSelf="stretch"
        textAlign="center"
        color="text.secondary"
        fontSize="14px"
        fontWeight="700"
        lineHeight="18px"
        letterSpacing="0.28px"
        noOfLines={1}
      >
        {name}
      </Text>
      <Box
        pl={2}
        pr={2}
        pt={1}
        pb={1}
        bg={doughnutChartColor}
        borderRadius="100px"
        overflow="hidden"
        display="flex"
        alignItems="center"
        gap={2.5} // Approximation of 10px in Chakra's scale
      >
        <Text
          textAlign="center"
          color="white"
          fontSize="14px"
          fontWeight="700"
          lineHeight="18px"
          letterSpacing="0.28px"
          noOfLines={1}
        >
          {percentage}%
        </Text>
      </Box>
    </Box>
  )
}
