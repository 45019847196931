import React, { useEffect, useState } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import './flippable.css'

export const Flippable: React.FC<{
  value: string | number | JSX.Element
  unit?: string
  hovered?: boolean
  hoveredBgColor?: string
  topMargin?: number
}> = ({ value, unit, hovered, hoveredBgColor = '#e1e8f0', topMargin = 2 }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [nextValue, setNextValue] = useState(value)
  const [isRotating, setIsRotating] = useState(false)

  useEffect(() => {
    if (value !== currentValue) {
      setNextValue(value)
      setIsRotating(true)
      setTimeout(() => {
        setIsRotating(false)
        setCurrentValue(value)
      }, 800)
    }
  }, [value, currentValue])

  return (
    <Flex alignItems="center" mt={topMargin} bg="inherit">
      <Box className="rotate-container">
        <Box
          className={`card ${isRotating ? 'rotate-out' : ''} ${
            hovered ? 'hovered' : ''
          } `}
          bg={hovered ? hoveredBgColor : null}
        >
          <Flex align="center" bg={hovered ? hoveredBgColor : null} gap="1">
            {currentValue} {unit}
          </Flex>
        </Box>
        <Box
          className={`card next-card ${isRotating ? 'rotate-in' : ''} ${
            hovered ? 'hovered' : ''
          }`}
          bg={hovered ? hoveredBgColor : null}
        >
          <Flex align="center" bg={hovered ? hoveredBgColor : null} gap="1">
            {nextValue} {unit}
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}
