import { createIcon } from '@chakra-ui/react'

export const CloseIcon = createIcon({
  displayName: 'CloseIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3718 11.9573C13.7624 12.3478 13.7624 12.9809 13.3718 13.3715C12.9813 13.762 12.3482 13.762 11.9576 13.3715L8.00034 9.41418L4.04305 13.3715C3.65253 13.762 3.01936 13.762 2.62884 13.3715C2.23831 12.9809 2.23831 12.3478 2.62884 11.9573L6.58612 7.99997L2.62627 4.04011C2.23574 3.64959 2.23574 3.01643 2.62627 2.6259C3.01679 2.23538 3.64996 2.23538 4.04048 2.6259L8.00034 6.58576L11.9602 2.6259C12.3507 2.23538 12.9839 2.23538 13.3744 2.6259C13.7649 3.01643 13.7649 3.64959 13.3744 4.04011L9.41455 7.99997L13.3718 11.9573Z"
      fill="#484848"
    />
  ),
})
