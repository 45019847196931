import { createIcon } from '@chakra-ui/icon'

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 12 8',
  path: (
    <path
      id="Check"
      d="M4.14744 7.34005L4.15897 7.35166L4.16363 7.35636C4.26146 7.44561 4.39828 7.50012 4.54631 7.5L4.54748 7.5C4.62178 7.50011 4.69471 7.48635 4.76202 7.46009C4.82937 7.43381 4.88863 7.39598 4.93697 7.35031L4.93749 7.34982L11.3533 1.30483L11.3533 1.30481C11.4019 1.25906 11.4388 1.20631 11.4634 1.15035C11.4878 1.0947 11.5 1.03607 11.5 0.977503C11.5 0.918936 11.4878 0.860307 11.4634 0.804654C11.4388 0.748701 11.4019 0.695942 11.3533 0.650201L11.3532 0.650078C11.3047 0.604379 11.2454 0.566574 11.1779 0.540215C11.1104 0.513869 11.0373 0.5 10.9629 0.5C10.8885 0.5 10.8154 0.513869 10.7479 0.540215C10.6804 0.566574 10.6211 0.604379 10.5726 0.650078L10.5725 0.650174L4.88887 6.00539L4.54599 6.32846L4.20311 6.00539L1.42784 3.39053C1.32903 3.29751 1.18901 3.24046 1.03728 3.24046C0.885622 3.24046 0.745664 3.29746 0.64686 3.3904L4.14744 7.34005ZM4.14744 7.34005L4.13518 7.32922M4.14744 7.34005L4.13518 7.32922M4.13518 7.32922L4.10434 7.30196M4.13518 7.32922L4.10434 7.30196M4.10434 7.30196L0.64686 4.04492C0.646858 4.04492 0.646855 4.04492 0.646853 4.04491C0.598207 3.99908 0.561376 3.94639 0.536829 3.89055C0.512167 3.83446 0.5 3.7758 0.5 3.71771C0.5 3.65961 0.512167 3.60095 0.536828 3.54486C0.561407 3.48895 0.59824 3.43624 0.646727 3.39053L4.10434 7.30196Z"
      fill="none"
      stroke="currentColor"
    />
  ),
})
