import { pythonApi } from '../../../app/services/api/api'
import { getDashboardOverviewMockData } from '../../../mockData/dashboard'
import { ReportData, ReportHistoryData } from '../types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLocations: builder.query<any, void>({
      query: () => ({
        url: `/locations/`,
        method: 'GET',
      }),
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getDashboardOverviewMockData()
        return mockResponse ? mockResponse : response
      },
    }),
    getReportData: builder.query<ReportData.Data, ReportData.QueryParams>({
      query: ({ from_date, to_date, location_ids }) => {
        const searchParams = new URLSearchParams()
        location_ids.forEach((id) => searchParams.append('location_ids', id))
        searchParams.append('from_date', from_date)
        searchParams.append('to_date', to_date)
        return {
          url: `/locations/report-data/?${searchParams}`,
          method: 'GET',
        }
      },
    }),
    getReportsHistory: builder.query<
      ReportHistoryData.Data,
      { page: number; location?: number }
    >({
      query: ({ page, location }) => {
        const queryParams = new URLSearchParams()
        queryParams.set('page', page?.toString())
        location && queryParams.set('locations', location?.toString())

        return {
          url: `/reports/?${queryParams.toString()}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useGetAllLocationsQuery,
  useGetReportDataQuery,
  useGetReportsHistoryQuery,
} = extendedApi
export default extendedApi
