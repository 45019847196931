import { createIcon } from '@chakra-ui/icons'

export const MailIcon = createIcon({
  displayName: 'MailIcon',
  viewBox: '0 0 24 24',
  path: (
    <g id="Leading-icon">
      <path
        id="Vector"
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Vector_2"
        d="M22 6L12 13L2 6"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
})
