import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const DeviceValue = ({ value, index, register, setValue }: any) => {
  const [notifications, setNotifications] = useState<boolean>(true)

  useEffect(() => {
    setValue(`notifications.${index}.min`, value.min_value)
    setValue(`notifications.${index}.max`, value.max_value)
  }, [])

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb="30px">
        <Box>
          <Text fontSize="sm">
            {value.device_type_parameter?.name?.replace(/^./, (str: string) =>
              str.toUpperCase(),
            )}{' '}
            notifications(optional)
          </Text>
          <Text fontSize="0.7rem" color="text.light">
            Notify me when the value of the device exceeds the limits
          </Text>
        </Box>
        <Switch
          id="notifications"
          isChecked={notifications}
          onChange={() => {
            // remove notification from the form values if it's switch off
            setValue(`notifications.${index}.id`, value.device_type_parameter?.id)
            setNotifications(!notifications)
          }}
        />
      </Box>

      {notifications && (
        <Flex>
          <Input
            type="number"
            placeholder="min value"
            w={200}
            {...register(`notifications.${index}.min`)}
          />
          <Center w={5}>
            <Text>-</Text>
          </Center>
          <Input
            type="number"
            placeholder="max value"
            w={200}
            {...register(`notifications.${index}.max`)}
          />
          <Center ml={3}>
            <InfoOutlineIcon color="gray" />
            <Container w={150} p={2} fontSize="0.6rem" color="text.light" centerContent>
              Suggested values: 12-14% min and 50-54% max
            </Container>
          </Center>
        </Flex>
      )}
      <Divider mb="20px" mt="10px" />
    </Box>
  )
}

export default DeviceValue
