import React from 'react'
import { default as ReactJSOdometer } from 'react-odometerjs'
import 'odometer/themes/odometer-theme-default.css'
import { Box } from '@chakra-ui/react'

interface Props {
  value: number
}

export const Odometer: React.FC<Props> = ({ value }) => {
  return (
    <Box>
      <ReactJSOdometer
        value={value}
        duration={300}
        format="(,ddd).dd"
        style={{ color: "#484848", fontSize: '32px', fontWeight: 700 }}
      />
    </Box>
  )
}
