import { addHours } from 'date-fns'
import { addDays } from 'date-fns'
import { startOfDay } from 'date-fns'
import { differenceInDays } from 'date-fns'
import { format } from 'date-fns'
import { getUserMockData } from './mockUsers'

const devicesMockOffice = [
  {
    id: 305,
    title: 'Total consumption',
    total_consumption: 412.81,
    current_usage: 12.44,
    is_favorite: false,
    is_main: true,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'relay',
  },
  {
    id: 307,
    title: 'Water heater',
    total_consumption: 120.44,
    current_usage: 5.44,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 308,
    title: 'Lights',
    total_consumption: 56.19,
    current_usage: 6.12,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 309,
    title: 'Appliance 1',
    total_consumption: 44.21,
    current_usage: 9.11,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 310,
    title: 'Appliance 2',
    total_consumption: 34.12,
    current_usage: 2.23,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 311,
    title: 'Appliance 3',
    total_consumption: 14.12,
    current_usage: 2.23,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
]
const devicesMockFactory = [
  {
    id: 306,
    title: 'Total consumption',
    total_consumption: 412.81,
    current_usage: 12.44,
    is_favorite: false,
    is_main: true,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'relay',
  },
  {
    id: 312,
    title: 'Air conditioning',
    total_consumption: 120.44,
    current_usage: 5.44,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 313,
    title: 'Lights Room 1',
    total_consumption: 56.19,
    current_usage: 6.12,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 314,
    title: 'Lights Room 2',
    total_consumption: 44.21,
    current_usage: 9.11,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 315,
    title: 'Industrial Unit 1',
    total_consumption: 34.12,
    current_usage: 2.23,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 316,
    title: 'Industrial unit 2',
    total_consumption: 14.12,
    current_usage: 2.23,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 0,
    category: 'emeter',
  },
  {
    id: 317,
    title: 'Solar panel 1',
    total_consumption: 0,
    current_usage: 0,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 43.12,
    category: 'energy_generator',
  },
  {
    id: 318,
    title: 'Solar panel 2',
    total_consumption: 0,
    current_usage: 0,
    is_favorite: false,
    is_main: false,
    is_virtual: false,
    is_hidden: false,
    total_generated_power: 44.12,
    category: 'energy_generator',
  },
]

// Function to generate consumption data for a given date and hour
const generateHourlyConsumptionData = (date: Date) => {
  const data = []
  for (let hour = 0; hour < 24; hour++) {
    const consumption = Math.random() * 80 + 8
    data.push({
      time: format(addHours(date, hour), 'yyyy-MM-dd HH:00:00'),
      consumption,
      generated_power: consumption,
      power_factor: Math.random(),
      min_voltage: Math.random() * 10 + 210,
      max_voltage: Math.random() * 10 + 220,
      phases_count: Math.floor(Math.random() * 3) + 1,
      children_consumption: Math.random() * 2,
      current: Math.random() * 20 + 10,
      resistance: null,
      carbon_intensity: null,
      fossil_fuel: null,
      humidity: null,
      phases: Array.from({ length: Math.floor(Math.random() * 3) + 1 }, () => ({
        consumption: Math.random() * 4 + 2,
        power_factor: Math.random(),
        min_voltage: Math.random() * 10 + 210,
        max_voltage: Math.random() * 10 + 220,
        current: Math.random() * 20 + 10,
        resistance: null,
      })),
    })
  }
  return data
}

const generateWeeklyConsumptionData = (date: Date, data: any) => {
  for (let hour = 0; hour < 2; hour++) {
    const consumption = Math.random() * 80 + 8
    data.push({
      time: format(addHours(date, hour === 0 ? 0 : 12), 'yyyy-MM-dd HH:00:00'),
      consumption,
      power_factor: Math.random(),
      min_voltage: Math.random() * 10 + 210,
      max_voltage: Math.random() * 10 + 220,
      phases_count: Math.floor(Math.random() * 3) + 1,
      children_consumption: Math.random() * 2,
      current: Math.random() * 20 + 10,
      resistance: null,
      carbon_intensity: null,
      fossil_fuel: null,
      humidity: null,
      phases: Array.from({ length: Math.floor(Math.random() * 3) + 1 }, () => ({
        consumption: Math.random() * 4 + 2,
        power_factor: Math.random(),
        min_voltage: Math.random() * 10 + 210,
        max_voltage: Math.random() * 10 + 220,
        current: Math.random() * 20 + 10,
        resistance: null,
      })),
    })
  }
  return data
}

const generateMonthlyConsumptionData = (date: Date, data: any) => {
  const consumption = Math.random() * 80 + 8
  data.push({
    time: format(date, 'yyyy-MM-dd HH:00:00'),
    consumption,
    power_factor: Math.random(),
    min_voltage: Math.random() * 10 + 210,
    max_voltage: Math.random() * 10 + 220,
    phases_count: Math.floor(Math.random() * 3) + 1,
    children_consumption: Math.random() * 2,
    current: Math.random() * 20 + 10,
    resistance: null,
    carbon_intensity: null,
    fossil_fuel: null,
    humidity: null,
    phases: Array.from({ length: Math.floor(Math.random() * 3) + 1 }, () => ({
      consumption: Math.random() * 4 + 2,
      power_factor: Math.random(),
      min_voltage: Math.random() * 10 + 210,
      max_voltage: Math.random() * 10 + 220,
      current: Math.random() * 20 + 10,
      resistance: null,
    })),
  })
  return data
}

// Function to generate consumption data for the last 31 days
const generateConsumptionData = (from?: string, to?: string) => {
  const currentDate = to ? new Date(to) : new Date()
  const fromDate = from ? new Date(from) : new Date()

  const daysDifference = differenceInDays(currentDate, fromDate) + 1
  //@ts-ignore
  let last31DaysData = []

  if (daysDifference === 1) {
    for (let i = 0; i < daysDifference; i++) {
      const date = addDays(fromDate, i)

      //@ts-ignore
      last31DaysData = generateHourlyConsumptionData(startOfDay(date))
    }
  }

  if (daysDifference > 1 && daysDifference <= 8) {
    for (let i = 0; i < daysDifference; i++) {
      const date = addDays(fromDate, i)
      //@ts-ignore
      generateWeeklyConsumptionData(startOfDay(date), last31DaysData)
    }
  } //@ts-ignore

  if (daysDifference > 8) {
    for (let i = 0; i < daysDifference; i++) {
      const date = addDays(fromDate, i)
      //@ts-ignore
      generateMonthlyConsumptionData(startOfDay(date), last31DaysData)
    }
  } //@ts-ignore
  return last31DaysData
}

export const getDashboardOverviewMockData = () => {
  const username = window.localStorage.getItem('username')
  const mockData = [
    {
      user: 'vboyadjiev@aiotcloud.app',
      response: {
        extras: null,
        count: 6,
        next: null,
        previous: null,
        results: [
          {
            id: 90,
            title: 'Office',
            description: '',
            image: null,
            total_consumption: 412.81,
            parent_id: null,
            sub_locations_count: 0,
            current_usage: 0,
            price_unit: 'kW',
          },

          {
            id: 91,
            title: 'Factory',
            description: '',
            image: null,
            total_consumption: 2081.71575,
            parent_id: null,
            sub_locations_count: 0,
            current_usage: 3641.82499,
            price_unit: 'kW',
          },
        ],
      },
    },
  ]

  if (username) {
    console.log('User data mocked for query "getDashboardOverview"')
    return mockData.find((obj) => obj.user === username)?.response
  }
}

const dashboardLocationMockData = [
  {
    user: 'vboyadjiev@aiotcloud.app',
    response: [
      {
        id: 90,
        from_date: '2024-02-26T00:00:00+02:00',
        to_date: '2024-02-26T23:59:59+02:00',
        last_hour: false,
        limit: 35,
        title: 'Office',
        description: '',
        image: null,
        carbon_intensity: null,
        total_consumption: 412.81,
        current_usage: 0,
        price_unit: 'kW',
        devices: devicesMockOffice,
      },
      {
        id: 91,
        from_date: '2024-02-26T00:00:00+02:00',
        to_date: '2024-02-26T23:59:59+02:00',
        last_hour: false,
        limit: 35,
        title: 'Factory',
        description: '',
        image: null,
        carbon_intensity: null,
        total_consumption: 412.81,
        current_usage: 0,
        price_unit: 'kW',
        devices: devicesMockFactory,
      },
    ],
  },
]
export const getDashboardLocationMockData = (id: any) => {
  const username = window.localStorage.getItem('username')

  if (!username) return

  const mockObject = getUserMockData(username, dashboardLocationMockData)

  if (mockObject) {
    console.log('User data mocked for query "getDashboardLocation"')
  }
  return mockObject.response.find((item: any) => item.id === Number(id))
}

export const getDeviceReportDataMock = (from?: string, to?: string) => {
  const username = window.localStorage.getItem('username')
  const mockData = [
    {
      user: 'vboyadjiev@aiotcloud.app',
      response: {
        results: generateConsumptionData(from, to),
        extras: {
          phase_count: 3,
          params: [],
          total_consumption: 119.80516999999999,
          total_children_consumption: 15.988185,
          carbon_intensity: null,
          current_usage: 5.1579,
        },
      },
    },
  ]

  if (username) {
    console.log('User data mocked for query "getDeviceReportData"')
    return mockData.find((obj) => obj.user === username)?.response
  }
}

export const getDeviceByIdMock = (id: string) => {
  const username = window.localStorage.getItem('username')
  const mockData = [
    {
      user: 'vboyadjiev@aiotcloud.app',
      response: [...devicesMockOffice, ...devicesMockFactory],
    },
  ]

  if (username) {
    console.log('User data mocked for query "getDeviceById"')
    const device = mockData
      .find((obj) => obj.user === username)
      ?.response.find((device) => device.id === Number(id))

    if (device) {
      return {
        ...device,
        on_dashboard: true,
        serial_number: '',
        is_virtual: false,
        physical_devices: [],
        device_type: {
          name: '',
          title: '',
          image: null,
        },
        device_type_relations: [],
      }
    }
  }
}

export const getDeviceDataMock = () => {
  const username = window.localStorage.getItem('username')
  const mockData = [
    {
      user: 'vboyadjiev@aiotcloud.app',
      response: {
        total_consumption: 412.81,
        total_generated_power: 512.81,
        min_consumption: 0,
        max_consumption: 0,
        min_temperature: null,
        max_temperature: null,
        humidity: null,
        power_factor: null,
        current_usage: 37.64,
        carbon_intensity: 0,
        price: null,
        price_unit: 'kW',
        device_type: null,
      },
    },
  ]

  if (username) {
    console.log('User data mocked for query "getDeviceData"')
    return mockData.find((obj) => obj.user === username)?.response
  }
}
