import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  TokenRequest,
  UserResponse,
} from '../types'

import { pythonApi } from '../../../app/services/api/api'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    getProfile: builder.query<UserResponse, void>({
      query: () => 'profile',
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
      query: (email) => ({
        url: '/auth/forgotten-password',
        method: 'POST',
        body: email,
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: (email) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: email,
      }),
    }),
    validateToken: builder.query<void, TokenRequest>({
      query: (token) => ({
        url: '/auth/validate-token',
        method: 'POST',
        body: token,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useLoginMutation,
  useLazyGetProfileQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLazyValidateTokenQuery,
} = extendedApi
