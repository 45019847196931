import { CalendarIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import React, { MouseEventHandler, useRef } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker'

type Props = InputProps & {
  onDatePickerChange: (from: DateObject, to: DateObject) => void
}

const RangeDatePicker = React.forwardRef<HTMLInputElement, Props>(
  ({ onDatePickerChange, ...props }, ref) => {
    // TODO: Improve typing
    const datePickerRef = useRef<React.ElementRef<typeof DatePicker>>(null)
    return (
      <DatePicker
        range
        arrow={false}
        className="gray"
        ref={datePickerRef}
        onChange={(dateObjects: DateObject[]) => {
          const from = dateObjects[0]
          const to = dateObjects[1]
          onDatePickerChange(from, to)

          // This will close calendar on selecting dates
          if (from && to) {
            datePickerRef.current?.closeCalendar()
          }
        }}
        render={(
          _: any,
          openCalendar: MouseEventHandler<SVGElement | HTMLInputElement>,
        ) => {
          return (
            <InputGroup size="lg">
              <Input
                placeholder="Select period"
                ref={ref}
                autoComplete="off"
                onClick={openCalendar}
                {...props}
              />
              <InputRightElement>
                <CalendarIcon color="gray" onClick={openCalendar} />
              </InputRightElement>
            </InputGroup>
          )
        }}
      />
    )
  },
)

export default RangeDatePicker
