import { pythonApi } from '../../../app/services/api/api'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    acknowledgeAlarmById: builder.mutation<any, { alarmId: number }>({
      query: ({ alarmId }) => {
        return {
          url: `/alarms/${alarmId}/`,
          method: 'PATCH',
          body: {
            is_acknowledged: true,
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useAcknowledgeAlarmByIdMutation } = extendedApi
