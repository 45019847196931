import { Select } from '@chakra-ui/react'
import React from 'react'
import { Device } from '../api/types'

type Props = {
  locationId: string | number
  devices: Device.Data[]
  placeholder?: string
}

const DeviceDropDown = React.forwardRef<HTMLSelectElement, Props>(
  ({ locationId, devices, placeholder, ...props }, ref) => {
    return (
      <Select
        size="lg"
        ref={ref}
        {...props}
        placeholder={`${placeholder || 'Select device'}`}
      >
        {devices &&
          devices.map(({ id, title }) => (
            <option key={title} value={id}>
              {title}
            </option>
          ))}
      </Select>
    )
  },
)

export default DeviceDropDown
