import React from 'react'
import { Box, Heading, Text, Button, VStack, Icon } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { MdOutlineErrorOutline, MdLocationOff, MdDevices } from 'react-icons/md'

const NotFound = () => {
  const location = useLocation()
  const message = location.state?.message || 'Page not found'
  const renderIcon = () => {
    if (message === 'Location not found') {
      return <Icon as={MdLocationOff} boxSize="50px" mb={6} />
    } else if (message === 'Device not found') {
      return <Icon as={MdDevices} boxSize="50px" mb={6} />
    } else {
      return <Icon as={MdOutlineErrorOutline} boxSize="50px" mb={6} />
    }
  }

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        backgroundClip="text"
        color="text.brand"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        {message}
      </Text>

      <VStack>
        {renderIcon()}
        <Link to="/dashboard">
          <Button colorScheme="button.primary" color="white" variant="solid">
            Go to Overview
          </Button>
        </Link>
      </VStack>
    </Box>
  )
}

export default NotFound
