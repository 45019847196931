import dayjs from 'dayjs'
import { Period, Periods, Title } from './types'

export const DEFAULTPERIOD = {
  from: dayjs().format('YYYY-MM-DD'),
  to: dayjs().format('YYYY-MM-DD'),
  period: Periods.DAY,
}

export const PERIODS: (Period & Title)[] = [
  {
    title: '1D',
    ...DEFAULTPERIOD,
  },
  {
    title: '1W',
    from: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    period: Periods.WEEK,
  },
  {
    title: '1M',
    from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    period: Periods.MONTH,
  },
  {
    title: '1Y',
    period: Periods.YEAR,
    from: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
  },
]

export const LOCATIONPARAMS: ['location', 'locationIds'] = ['location', 'locationIds']
export const PERIODPARAMS: ['from', 'to', 'period', 'last_hour'] = [
  'from',
  'to',
  'period',
  'last_hour',
]
export const DASHBOARDPARAMS = [...LOCATIONPARAMS, ...PERIODPARAMS]
export const DEVICEPARAMS = [...DASHBOARDPARAMS, 'device']
