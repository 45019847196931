import React, { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  Box,
  Spinner,
  Flex,
  Text,
  Icon,
  Button,
  FormControl,
  FormLabel,
  Select,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useColorModeValue,
  Fade,
  SliderMark,
  useToast,
} from '@chakra-ui/react'
import {
  BsFillBrightnessHighFill,
  BsDroplet,
  BsThermometerHalf,
  BsFan,
} from 'react-icons/bs'
import { GoDotFill } from 'react-icons/go'
import { MdArrowDropDown } from 'react-icons/md'
import { FaPowerOff } from 'react-icons/fa'
import { FaRegCircleCheck } from 'react-icons/fa6'
import { PiWarningCircleBold } from 'react-icons/pi'
import {
  useGetACStateQuery,
  useSetACStateMutation,
  usePatchACStateMutation,
  setMultipleACStates,
} from '../api/dasboardApi'
import { SerializedError } from '@reduxjs/toolkit'

type MyObject =
  | {
      data: any
    }
  | {
      error:
        | {
            status: number
            data: unknown
          }
        | {
            status: 'FETCH_ERROR'
            data?: undefined
            error: string
          }
        | {
            status: 'PARSING_ERROR'
            originalStatus: number
            data: string
            error: string
          }
        | SerializedError
    }

const isErrorObject = (obj: any): obj is MyObject => {
  return obj && obj.error !== undefined
}

const ClimateDeviceData: React.FC<{
  deviceId: string
  isEnabled?: boolean
  devices?: { id: string; title: string }[]
}> = ({ deviceId, isEnabled, devices }) => {
  const { data, isFetching: isGetACStateFetching } = useGetACStateQuery({
    id: deviceId,
  })

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const [isModeDropdownOpen, setIsModeDropdownOpen] = useState(false)
  const [isFanLevelDropdownOpen, setIsFanLevelDropdownOpen] = useState(false)
  const [setACState] = useSetACStateMutation()

  const toast = useToast()

  const onSubmit = async (formData) => {
    const formDataSerialized = {
      ...formData,
      on: !data.on,
      swing: data.swing,
      id: deviceId,

      temperatureUnit: 'C',
    }
    const response = await setACState({
      acState: formDataSerialized,
      id: deviceId,
    })

    if (isErrorObject(response)) {
      toast({
        position: 'bottom-right',
        render: () => (
          <Box>
            <Flex
              color="#484848"
              p={3}
              bg="white"
              gap={3}
              rounded="3xl"
              justifyContent="space-between"
            >
              <Icon as={PiWarningCircleBold} boxSize="24px" color="#d82135" />
              There has been an error switching the device {data.on ? 'on' : 'off'}
            </Flex>
          </Box>
        ),
      })
    } else {
      toast({
        position: 'bottom-right',
        render: () => (
          <Box>
            <Flex
              color="#484848"
              p={3}
              gap={3}
              bg="white"
              rounded="3xl"
              justifyContent="space-between"
            >
              <Icon as={FaRegCircleCheck} boxSize="24px" color="#00d183" />
              The device has been switched {data.on ? 'off' : 'on'}
            </Flex>
          </Box>
        ),
      })
    }
  }

  const dispatch = useDispatch()

  const onUpdate = async (formData) => {
    const formDataSerialized = {
      ...formData,
      on: true,
      swing: data.swing,
      id: deviceId,
      temperatureUnit: 'C',
    }

    const response = await setMultipleACStates(dispatch, {
      ids: devices ? devices.map((d) => d.id) : [deviceId],
      acState: formDataSerialized,
    })

    if (isErrorObject(response)) {
      toast({
        position: 'bottom-right',
        render: () => (
          <Box color="#484848" p={3} bg="red.500">
            <Flex
              color="#484848"
              p={3}
              gap={3}
              bg="white"
              rounded="3xl"
              justifyContent="space-between"
            >
              <Icon as={PiWarningCircleBold} boxSize="24px" color="#d82135" />
              There has been an error updating the device
            </Flex>
          </Box>
        ),
      })
    } else {
      toast({
        position: 'bottom-right',
        render: () => (
          <Box>
            <Flex
              color="#484848"
              p={3}
              bg="white"
              gap={3}
              rounded="3xl"
              justifyContent="space-between"
            >
              <Icon as={FaRegCircleCheck} boxSize="24px" color="#00d183" />
              The device has been updated
            </Flex>
          </Box>
        ),
      })
    }
  }

  useEffect(() => {
    if (data) {
      const { targetTemperature, mode, fanLevel } = data
      setValue('mode', mode)
      setValue('fanLevel', fanLevel)
      setValue('targetTemperature', targetTemperature)
    }
  }, [data, setValue])

  const temperatureRangeBg = useColorModeValue(
    'linear-gradient(to right, #3182CE, #EF4444)',
    'linear-gradient(to right, #2563EB, #D946EF)',
  )

  if (isGetACStateFetching) {
    return (
      <Box>
        <Spinner />
        <span>Loading...</span>
      </Box>
    )
  }

  if (!data) {
    return <div>No data available</div>
  }

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      padding="20px"
    >
      {devices ? (
        <Box>
          {devices.map((device) => (
            <Text>{device.title}</Text>
          ))}
        </Box>
      ) : null}
      <Flex alignItems="center" marginY="10px" cursor="pointer">
        <Icon as={FaPowerOff} boxSize="24px" />
        <Text marginLeft="10px">Status:</Text>
        <Icon as={GoDotFill} boxSize="24px" color={data.on ? '#00d183' : '#f46f31'} />
      </Flex>
      <Flex
        alignItems="center"
        marginY="10px"
        onMouseEnter={() => setIsModeDropdownOpen(true)}
        onMouseLeave={() => setIsModeDropdownOpen(false)}
        cursor="pointer"
        w="250px"
        justifyContent="space-between"
      >
        <Flex>
          <Icon as={BsThermometerHalf} boxSize="24px" />
          <Text marginLeft="10px">Mode:</Text>
        </Flex>
        <FormControl id="mode" w="120px">
          <Select
            cursor="pointer"
            {...register('mode')}
            borderColor={isModeDropdownOpen ? '#cad5df' : 'transparent'}
            icon={
              <Fade in={isModeDropdownOpen}>
                <MdArrowDropDown />
              </Fade>
            }
          >
            <option value="cool">Cool</option>
            <option value="dry">Dry</option>
            <option value="fan">Fan</option>
            <option value="heat">Heat</option>
            <option value="auto">Auto</option>
          </Select>
        </FormControl>
      </Flex>

      <Flex
        alignItems="center"
        marginY="10px"
        onMouseEnter={() => setIsFanLevelDropdownOpen(true)}
        onMouseLeave={() => setIsFanLevelDropdownOpen(false)}
        cursor="pointer"
        w="250px"
        justifyContent="space-between"
      >
        <Flex>
          <Icon as={BsFan} boxSize="24px" />
          <Text marginLeft="10px" noOfLines={1} w="80px">
            Fan Level:
          </Text>
        </Flex>
        <FormControl id="fanLevel" w="120px">
          <Select
            {...register('fanLevel')}
            cursor="pointer"
            borderColor={isFanLevelDropdownOpen ? '#cad5df' : 'transparent'}
            icon={
              <Fade in={isFanLevelDropdownOpen}>
                <MdArrowDropDown />
              </Fade>
            }
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
            <option value="auto">Auto</option>
          </Select>
        </FormControl>
      </Flex>

      <FormControl id="targetTemperature">
        <Slider
          defaultValue={data.targetTemperature}
          min={16}
          max={30}
          step={1}
          onChange={(value) => setValue('targetTemperature', value)}
          maxW="300px"
          mt="6"
        >
          <SliderMark value={16} {...labelStyles} ml="0">
            16°C
          </SliderMark>

          <SliderMark value={30} {...labelStyles} ml="-8">
            30°C
          </SliderMark>
          <SliderMark
            value={watch('targetTemperature')}
            textAlign="center"
            bg="blue.500"
            rounded="2xl"
            color="white"
            mt="-10"
            ml="-5"
            w="12"
          >
            {watch('targetTemperature')}°C
          </SliderMark>

          <SliderTrack>
            <SliderFilledTrack bg={temperatureRangeBg} />
          </SliderTrack>
          <SliderThumb boxSize={6} />
        </Slider>
      </FormControl>

      <Flex justifyContent="space-between" w="300px">
        {!isEnabled ? (
          <Button
            type="submit"
            mt="4"
            colorScheme="button.primary"
            onClick={handleSubmit(onSubmit)}
          >
            {data.on ? 'Turn off' : 'Turn on'}
          </Button>
        ) : null}

        <Button
          type="submit"
          disabled={!isEnabled && !data.on}
          mt="4"
          colorScheme="button.primary"
          onClick={handleSubmit(onUpdate)}
        >
          Update settings
        </Button>
      </Flex>
    </Flex>
  )
}

export default ClimateDeviceData
