import React, { useCallback, useRef } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import { ToastContainer, toast } from 'react-toastify'
import useAlertsSocket from '../hooks/useAlertsSocket'
import { AlarmData } from '../types'
import AlarmToast from './AlarmToast'
import FooterAlarmToast from './FooterAlarmToast'
import { useAcknowledgeAlarmByIdMutation } from '../api/layoutApi'

const AlarmToastManager = () => {
  const toastIds = useRef<number[]>([])
  const footerToastId = useRef<React.ReactText | null>(null)

  const [acknowlegdeAlarmById] = useAcknowledgeAlarmByIdMutation()

  const filterToastIds = useCallback(
    (toastId) => toastIds.current.filter((id) => id !== toastId),
    [],
  )

  const createOrUpdateToastFooter = useCallback(() => {
    if (!footerToastId.current) {
      const toastId = toast(
        ({ closeToast }) => (
          <FooterAlarmToast
            closeToast={() => {
              closeToast()
              toastIds.current.map((id) => {
                toast.dismiss(id)
                // acknowlegdeAlarmById(id)
              })
              footerToastId.current = null
              toastIds.current = null
            }}
          />
        ),
        {
          position: 'bottom-right',
          autoClose: false,
          closeButton: false,
        },
      )

      footerToastId.current = toastId
    }
  }, [])

  const createOrUpdateAlarmToast = useCallback(
    (alarm) => {
      if (toastIds.current?.length >= 2) {
        if (toastIds.current.includes(alarm.id)) {
          const oldestToastId = toastIds.current[0]
          toast.dismiss(oldestToastId)
          toastIds.current = filterToastIds(oldestToastId)
          return
        }
      }

      toast(
        ({ closeToast }) => (
          <AlarmToast
            alarm={alarm}
            closeToast={() => {
              closeToast()
              toastIds.current = filterToastIds(alarm.id)
              acknowlegdeAlarmById({ alarmId: alarm.id })
            }}
          />
        ),
        {
          position: 'bottom-right',
          autoClose: false,
          toastId: alarm.id,
          closeButton: <CloseIcon cursor="pointer" />,
        },
      )
      toastIds.current.push(alarm.id)
    },
    [filterToastIds, acknowlegdeAlarmById],
  )

  const handleAlarms = useCallback(
    (data: AlarmData) => {
      if (data.active_alarms >= 2) {
        createOrUpdateToastFooter()
      }

      if (data.last_alarms.length > 0 && toastIds.current?.length > 0) {
        const lastAlarmsSorted = data.last_alarms.map((a) => a.id).sort()
        const currentAlarmsSorted = toastIds.current.sort()
        const areEqual = lastAlarmsSorted.every(
          (alarm, index) => alarm === currentAlarmsSorted[index],
        )

        if (areEqual) return
        data.last_alarms.reverse().forEach((alarm) => createOrUpdateAlarmToast(alarm))
      }
    },
    [createOrUpdateAlarmToast, createOrUpdateToastFooter],
  )

  useAlertsSocket(handleAlarms)

  return <ToastContainer newestOnTop={true} />
}

export default AlarmToastManager
