import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { CheckIcon } from '../../../assets/icons/Check'
import { EllipseIcon } from '../../../assets/icons/Ellipse'
import { ChipProps } from './types'

const Chip = ({ name, value, selectedValues, setSelected, icons }: ChipProps) => {
  const isSelected = useMemo(
    () => selectedValues.includes(value),
    [selectedValues, value],
  )

  const handleClick = useCallback(
    (value) => {
      if (isSelected) {
        setSelected(selectedValues.filter((selectedValue) => selectedValue !== value))
      } else {
        setSelected([...selectedValues, value])
      }
    },
    [selectedValues, setSelected, isSelected],
  )

  const Icon = isSelected ? icons.selected : icons.unselected
  return (
    <Box
      px="16px"
      py="8px"
      bg="white"
      borderRadius={24}
      overflow="hidden"
      border="1px  solid"
      borderColor={isSelected ? '#157FEE' : '#CECFD0'}
      justifyContent="flex-start"
      alignItems="flex-start"
      gap={8}
      display="inline-flex"
      cursor="pointer"
      color={!isSelected ? 'text.secondary' : '#157FEE'}
      background={isSelected ? '#E8EEFD' : 'white'}
      _hover={{
        background: !isSelected ? '#F0F0F0' : 'white',
        color: !isSelected ? 'text.secondary' : '#157FEE',
      }}
      onClick={() => handleClick(value)}
    >
      <Flex justifyContent="space-between" alignItems="center">
        {Icon}
        <Text ml="2">{name}</Text>
      </Flex>
    </Box>
  )
}

export default Chip
