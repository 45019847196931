import { extendTheme } from '@chakra-ui/react'
import colors from './colors'

import fontSizes from './fonts'
import breakpoints from './breakpoints'
import Tabs from './components/Tabs'

export default extendTheme({
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },
  colors,
  fontSizes,
  breakpoints,
  components: {
    Tabs,
    Button: {
      variants: {
        toggle: {
          rounded: 'full',
          w: '40px',
          h: '40px',
          p: '8px',
          bg: 'transparent',
          border: '1px solid',
          borderColor: 'border.light',
          color: 'icon.strong',
          _hover: {
            bg: '#E8EEFD',
          },
          _active: {
            bg: 'linear-gradient(90deg, #1DABFC 0%, #157FEE 100%)',
            color: 'icon.on',
            borderColor: 'icon.hover',
            _hover: {
              bg: 'icon.hover',
              borderColor: 'icon.hover',
            },
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none',
            transition: 'none',
          },
        },
      },
    },
    Progress: {
      variants: {
        generator: {
          filledTrack: {
            bg: 'linear-gradient(90deg, #1DABFC 0%, #21D17F 100%)',
          },
        },
        consumer: {
          filledTrack: {
            bg: 'linear-gradient(90deg, #1DABFC 0%, #FF7150 100%)',
          },
        },
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        height: '100vh',
      },
      '.root': {
        height: '100vh',
      },
    },
  },
})
