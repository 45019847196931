import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Title, DashboardOverviewLocation } from '../types'
import { Card } from '../components/Card'

type Props = {
  locations: (Title & DashboardOverviewLocation)[]
}

const ListView: React.FC<Props> = ({ locations }) => {
  return (
    <Flex justifyContent="center">
      <Box p={4}>
        <SimpleGrid columns={3} spacing="14">
          {locations &&
            locations
              .filter(({ parent_id }) => parent_id === null)
              .map((location) => <Card location={location} />)}
        </SimpleGrid>
      </Box>
    </Flex>
  )
}

export default ListView
