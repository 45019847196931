import { Box, Tab, TabList, Tabs } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DEVICEPARAMS, PERIODS } from '../consts'
import { Period } from '../types'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import RangePicker from './RangePicker'
import { useQueryParams } from '../hooks/useQueryParams'

export const PeriodTabStrip: React.FC = () => {
  const data = PERIODS
  const [tabIndex, setTabIndex] = useState(0)
  const [customDates, setCustomDates] = useState<DateObject[] | undefined>([])

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  let [searchParams, setSearchParams] = useSearchParams()
  const [queryParams] = useQueryParams(DEVICEPARAMS)

  useEffect(() => {
    const periodFilterIndex = data.findIndex(
      (item) =>
        item.period?.toString() === searchParams.get('period') ||
        item.last_hour === searchParams.get('last_hour'),
    )
    setTabIndex(periodFilterIndex >= 0 ? periodFilterIndex : 0)
  }, [searchParams, data])

  const tabItems = data.map((tabItem, idx) => (
    <Tab
      key={idx}
      fontSize="sm"
      color="text.light"
      px=".4rem"
      py=".2rem"
      mr=".2rem"
      fontWeight="bold"
      onClick={() => handleNavigation(tabItem)}
    >
      {tabItem.title}
    </Tab>
  ))

  const handleNavigation: (item: Period) => void = (tabItem) => {
    setCustomDates(undefined)
    setSearchParams(
      {
        ...queryParams,
        ...{
          from: tabItem.from,
          to: tabItem.to,
          last_hour: (tabItem.last_hour || false).toString(),
        },
        ...(tabItem.period ? { period: tabItem.period.toString() } : {}),
      },
      { replace: true },
    )
  }

  return (
    <Tabs variant="solid-rounded" index={tabIndex} onChange={handleTabsChange}>
      <TabList>
        {tabItems.concat([
          <Box key={tabItems.length} alignSelf="center" ml={6} cursor="pointer">
            <DatePicker
              className="gray"
              value={customDates}
              showOtherDays={true}
              onChange={(dateObjects: DateObject[]) => {
                if (dateObjects.length === 2) {
                  setCustomDates(dateObjects)
                  const [start, end] = dateObjects
                  setSearchParams({
                    ...queryParams,
                    ...{
                      from: start.format('YYYY-MM-DD'),
                      to: end.format('YYYY-MM-DD'),
                      period: 'week',
                      last_hour: 'false',
                    },
                  })
                }
              }}
              range
              render={<RangePicker />}
            />
          </Box>,
        ])}
      </TabList>
    </Tabs>
  )
}
