import { Button } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionButton = motion(Button)
export const PageButton: React.FC<{
  page: number
  currentPage: number
  setCurrentPage: (pageNumber: number) => void
}> = ({ page, currentPage, setCurrentPage }) => {
  const isCurrentPage = currentPage === page
  return (
    <MotionButton
      key={`motion-button-${page}`}
      onClick={(e) => {
        e.preventDefault()
        setCurrentPage(page)
      }}
      px="3"
      py="1"
      border="1px solid"
      borderColor="transparent"
      rounded="lg"
      bg={isCurrentPage ? 'border.strongest' : 'none'}
      color={isCurrentPage ? 'text.on' : 'none'}
      _hover={{
        borderColor: isCurrentPage ? 'transparent' : 'border.strong',
      }}
      _focus={{
        outline: 'none',
      }}
      outline="0"
      boxShadow="none"
    >
      {page}
    </MotionButton>
  )
}
