import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { DeviceTypeParameter } from '../../app/services/api/types'
import {
  useAddDeviceMutation,
  useGetDeviceTypesQuery,
  useGetLocationsInfoQuery,
} from './api/locationsApi'
import DeviceType from './components/DeviceType'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../app/components/Header'
import Loading from './components/Loading'

interface FormNotification {
  id: number
  min: number
  max: number
}

const getNotifications = (nots?: FormNotification[]) => {
  if (!nots) {
    return []
  }

  return nots
    .map((n: FormNotification) => {
      return {
        parameter_id: n.id,
        min_value: n.min,
        max_value: n.max,
        is_main: false,
      }
    })
    .filter((n) => !!n)
}

const AddDevice = () => {
  const [addDevice, { isLoading, isError }] = useAddDeviceMutation()

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
    control,
  } = useForm()
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'notifications', // unique name for your Field Array
  })
  const location = useLocation()
  const navigate = useNavigate()

  const { locationId } = location.state || ({} as any)

  const { data: locationsData, isFetching } = useGetLocationsInfoQuery({})

  const { data: deviceTypes, isFetching: isDeviceTypesFetching } = useGetDeviceTypesQuery(
    {},
  )

  const [deviceTypeId, setDeviceTypeId] = useState<string | null>(null)

  const selectedDeviceType = useMemo(() => {
    if (!deviceTypes) {
      return null
    }
    const selectedDevice = deviceTypes.results?.filter(
      (d) => `${d.id}` === deviceTypeId,
    )?.[0]

    return selectedDevice
  }, [deviceTypes, deviceTypeId])

  useEffect(() => {
    if (locationId) {
      setValue('location', locationId)
    }
  }, [locationId])

  async function onSubmit(values: any) {
    if (values.name && values.location) {
      const notifications = getNotifications(values.notifications)
      await addDevice({
        title: values.name,
        location_id: values.location,
        device_type_id: values.type,
        device_type_parameters: notifications,
      })
        .unwrap()
        .then((res) => {
          navigate(
            values.location ? `/locations/${values.location}/devices` : '/locations',
          )
        })
    }
  }

  return (
    <>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold" pl={{ base: 6, lg: 0 }}>
          <Stack
            _hover={{ cursor: 'pointer' }}
            direction="row"
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBackIcon />
            <Text>
              {(location.state as any)?.path === 'locations' ? 'Locations' : 'Devices'}
            </Text>
          </Stack>
        </Heading>
      </Header>
      <Stack
        direction="column"
        boxShadow="lg"
        rounded="lg"
        mt={4}
        borderWidth={1}
        w="100%"
        maxWidth="2xl"
        px={6}
        py={8}
        spacing={8}
        mx="auto"
      >
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          Add new device
        </Heading>
        <VStack spacing={6} as="form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.name !== undefined} color="text.light">
            <Input
              placeholder="Enter device name"
              id="name"
              {...register('name', {
                required: '* Required field',
              })}
              color="text.dark"
            />
            <FormErrorMessage>
              {(errors.name?.message as React.ReactNode) ?? null}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.type !== undefined} color="text.light">
            <Select
              placeholder="Enter device type"
              id="type"
              {...register('type', {
                required: '* Required field',
              })}
              onChange={(e) => {
                setDeviceTypeId(e.target.value)
              }}
              color="text.dark"
            >
              {deviceTypes?.results?.map((device) => {
                return (
                  <option key={device.id} value={device.id}>
                    {device.title}
                  </option>
                )
              })}
            </Select>
            <FormErrorMessage>
              {(errors.type?.message as React.ReactNode) ?? null}
            </FormErrorMessage>
          </FormControl>
          {deviceTypeId && selectedDeviceType && (
            <Flex justifyContent="space-between" flexDirection={'column'} w="full">
              {selectedDeviceType.device_type_parameters?.map(
                (param: DeviceTypeParameter, i: number) => {
                  return (
                    <DeviceType
                      key={param.id}
                      param={param}
                      index={i}
                      register={register}
                      setValue={setValue}
                    />
                  )
                },
              )}
            </Flex>
          )}

          <FormControl isInvalid={errors.type !== undefined} color="text.light">
            <Select
              placeholder="Assign to location"
              id="location"
              {...register('location', {
                required: '* Required field',
              })}
              color="text.dark"
            >
              {locationsData?.results?.map((location) => {
                return (
                  <option key={location.id} value={location.id}>
                    {location.title}
                  </option>
                )
              })}
            </Select>
            <FormErrorMessage>
              {(errors.location?.message as React.ReactNode) ?? null}
            </FormErrorMessage>
          </FormControl>
          {isError && (
            <Text alignSelf="flex-start" color="red.400">
              There is some error, please try again later
            </Text>
          )}
          <Button
            colorScheme="button.primary"
            size="lg"
            w={60}
            h={12}
            alignSelf="flex-start"
            mt={8}
            type="submit"
            rightIcon={<Loading isLoading={isLoading} />}
          >
            Add
          </Button>
        </VStack>
      </Stack>
    </>
  )
}

export default AddDevice
