import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Grid, Skeleton, useMediaQuery } from '@chakra-ui/react'
import {
  ENERGY_GENERATOR,
  ENERGY_PLANT_LOGGER,
  KILOWATT_HOUR,
} from '../../../utils/consts'
import { useGetDeviceReportDataQuery } from '../api/dasboardApi'
import { DEFAULTPERIOD, DEVICEPARAMS } from '../consts'
import { useQueryParams } from '../hooks/useQueryParams'
import { RechartsAreaChart } from '../../../app/components/AreaChart'

interface EnergyDeviceDataParams {
  deviceCategory: string
  deviceReportData: any
  isDeviceReportDataFetching: boolean
}

const EnergyDeviceData: React.FC<EnergyDeviceDataParams> = ({
  deviceCategory,
  deviceReportData,
  isDeviceReportDataFetching,
}) => {
  const [searchParams] = useSearchParams()

  const [isSmallerThan1865] = useMediaQuery('(max-width: 1865px)')

  const isElectricAvailable = useMemo(
    () => deviceReportData?.some((d) => !!d.current),
    [deviceReportData],
  )

  const isEnergyGenerator = deviceCategory === ENERGY_GENERATOR
  const isEnergyPlantLogger = deviceCategory === ENERGY_PLANT_LOGGER
  return (
    <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap="16px">
      {isDeviceReportDataFetching ? (
        <Skeleton mx="auto"></Skeleton>
      ) : (
        <Box
          // borderWidth="1px"
          borderRadius="xl"
          background="#fff"
          p={2}
          // overflow="visible"
        >
          <RechartsAreaChart
            title={
              isEnergyGenerator || isEnergyPlantLogger
                ? `Produced Energy (${KILOWATT_HOUR})`
                : `Total Consumption (${KILOWATT_HOUR})`
            }
            data={deviceReportData}
            period={searchParams.get('period') || 'day'}
            dataKeys={[
              isEnergyGenerator || isEnergyPlantLogger
                ? {
                    key: 'generated_power',
                    displayValue: `Produced Energy`,
                  }
                : {
                    key: 'consumption',
                    displayValue: `Total Consumption`,
                  },
            ]}
          />
        </Box>
      )}
      {isDeviceReportDataFetching ? (
        <Skeleton mx="auto"></Skeleton>
      ) : (
        <>
          {!(isEnergyGenerator || isEnergyPlantLogger) && isElectricAvailable ? (
            <Box background="#fff" p={2} borderRadius="xl">
              <RechartsAreaChart
                title="Current (A)"
                data={deviceReportData}
                period={searchParams.get('period') || 'day'}
                dataKeys={[{ key: 'current', displayValue: 'Current' }]}
              />
            </Box>
          ) : null}
        </>
      )}
      {isDeviceReportDataFetching ? (
        <Skeleton mx="auto"></Skeleton>
      ) : (
        <>
          {!(isEnergyGenerator || isEnergyPlantLogger) ? (
            <Box background="#fff" p={2} borderRadius="xl">
              <RechartsAreaChart
                title="Voltage (V)"
                data={deviceReportData}
                period={searchParams.get('period') || 'day'}
                dataKeys={[
                  { key: 'max_voltage', displayValue: 'Max Voltage' },
                  { key: 'min_voltage', displayValue: 'Min Voltage' },
                ]}
              />
            </Box>
          ) : null}
        </>
      )}
      {isDeviceReportDataFetching ? (
        <Skeleton mx="auto"></Skeleton>
      ) : (
        <>
          {!(isEnergyGenerator || isEnergyPlantLogger) ? (
            <Box background="#fff" p={2} borderRadius="xl">
              <RechartsAreaChart
                title="Power Factor (cos φ)"
                data={deviceReportData}
                period={searchParams.get('period') || 'day'}
                dataKeys={[{ key: 'power_factor', displayValue: 'Power Factor (cos φ)' }]}
              />
            </Box>
          ) : null}
        </>
      )}
    </Grid>
  )
}

export default EnergyDeviceData
