import { Box, Flex, IconButton, Link, VStack } from '@chakra-ui/react'
import { Outlet, Link as ReachLink } from 'react-router-dom'
import { Icon } from '../../../utils/Icon'
import back from '../../../assets/Login/back.svg'

export const Auth: React.FC = () => {
  return (
    <VStack justifyContent="center" w="full" h="100vh" alignContent="center">
      <Flex
        direction="column"
        textAlign="center"
        w={{ base: '95%', sm: 'lg' }}
        boxShadow="lg"
        p={{ base: 4, sm: 8 }}
        rounded="lg"
        borderWidth={1}
        bgColor="white"
      >
        <Outlet />
        <Box
          w="full"
          fontSize="xs"
          fontWeight="semibold"
          color="text.dark"
          textAlign="center"
          pt={2}
          mt={8}
        >
          <Link color="text.dark" as={ReachLink} to="/login" fontSize="xs">
            <IconButton
              aria-label="Back to login"
              w="1rem"
              minW={5}
              variant="unstyled"
              icon={<Icon w={4} src={back} />}
            />
            Back to log in
          </Link>
        </Box>
      </Flex>
    </VStack>
  )
}
