import { createIcon } from '@chakra-ui/icons'

// Define the "ProducedEnergyIcon" using the `createIcon` function
const ProducedEnergyIcon = createIcon({
  displayName: 'ProducedEnergyIcon',
  viewBox: '0 0 32 32', // Ensure your viewBox matches the SVG's
  // Utilize the path directly from your SVG
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1111 2.66663C13.1111 2.11434 13.5588 1.66663 14.1111 1.66663H16.5556C17.1078 1.66663 17.5556 2.11434 17.5556 2.66663C17.5556 3.21891 17.1078 3.66663 16.5556 3.66663L14.1111 3.66663C13.5588 3.66663 13.1111 3.21891 13.1111 2.66663ZM9 26.9753C9 27.8996 9.69696 28.5555 10.4444 28.5555H20.2222C20.9697 28.5555 21.6667 27.8996 21.6667 26.9753L21.6667 8.91354C21.6667 7.98916 20.9697 7.33329 20.2222 7.33329H10.4444C9.69696 7.33329 9 7.98916 9 8.91354L9 26.9753ZM10.4444 30.5555C8.49188 30.5555 7 28.901 7 26.9753L7 8.91354C7 6.98786 8.49187 5.33329 10.4444 5.33329L20.2222 5.33329C22.1748 5.33329 23.6667 6.98786 23.6667 8.91354L23.6667 26.9753C23.6667 28.901 22.1748 30.5555 20.2222 30.5555H10.4444ZM16.9802 12.9991C17.2866 12.5396 17.1624 11.9187 16.7028 11.6124C16.2433 11.306 15.6225 11.4302 15.3161 11.8897L12.0568 16.7786C11.8523 17.0854 11.8332 17.48 12.0072 17.8052C12.1812 18.1303 12.5201 18.3333 12.8889 18.3333H15.9093L13.6865 21.6675C13.3801 22.127 13.5043 22.7479 13.9638 23.0542C14.4233 23.3606 15.0442 23.2364 15.3506 22.7769L18.6098 17.888C18.8144 17.5811 18.8335 17.1866 18.6595 16.8614C18.4854 16.5363 18.1466 16.3333 17.7778 16.3333H14.7574L16.9802 12.9991Z"
        fill="#484848"
      />
    </g>
  ),
})

export default ProducedEnergyIcon
