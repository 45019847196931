import { LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { useResetPasswordMutation } from '../api/authApi'
import key from '../../../assets/Login/key.svg'
import success from '../../../assets/Login/success.svg'
import { Icon } from '../../../utils/Icon'
import { ResetPassworForm } from '../types'

export const ResetPassword: React.FC<any> = () => {
  const [resetPassword, { isLoading, isSuccess, isError }] = useResetPasswordMutation()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirm, setShowConfirm] = React.useState(false)
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<ResetPassworForm>()

  async function onSubmit(values: any) {
    resetPassword(values as { password: string; confirm: string })
  }

  const redirectUI = <Navigate replace to="/forgot-password" />

  const loadingUI = (
    <Center>
      <Spinner size="xl"></Spinner>
    </Center>
  )

  const resetPasswordUI = (
    <>
      <Icon w={12} h={12} src={key} mx="auto" />
      <Text as="h2" fontSize={{ base: 'xl', sm: '2xl' }} fontWeight="bold" mt={6}>
        Reset your password
      </Text>
      <Text textAlign="center" fontSize="sm" mt={4}>
        Please enter a new password for your account
      </Text>
      <Flex direction="column" as="form" onSubmit={handleSubmit(onSubmit)} mt={14}>
        <Box>
          <FormControl isInvalid={errors.password !== undefined}>
            <InputGroup size="lg">
              <InputLeftElement
                w={{ base: 10, sm: 14 }}
                pointerEvents="none"
                children={<LockIcon color="gray.200" />}
              />
              <Input
                type={showPassword ? 'text' : 'password'}
                pl={{ base: 10, sm: 14 }}
                placeholder="New password"
                id="password"
                {...register('password', {
                  required: 'This is required',
                  minLength: {
                    value: 8,
                    message: 'Password should be at least 8 characters length',
                  },
                })}
              />
              <InputRightElement>
                <Button
                  size="lg"
                  onClick={() => setShowPassword(!showPassword)}
                  variant="unstyled"
                  _focus={{ boxShadow: 'none' }}
                >
                  {showPassword ? (
                    <ViewOffIcon color="gray.300" />
                  ) : (
                    <ViewIcon color="gray.300" />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          {!errors.password && (
            <Text w="full" textAlign="left" color="text.light" fontSize="xs" mt={1}>
              Password must be at least 8 characters
            </Text>
          )}
        </Box>
        <FormControl isInvalid={errors.confirm !== undefined} mt={4}>
          <InputGroup size="lg">
            <InputLeftElement
              w={{ base: 10, sm: 14 }}
              pointerEvents="none"
              children={<LockIcon color="gray.200" />}
            />
            <Input
              type={showConfirm ? 'text' : 'password'}
              pl={{ base: 10, sm: 14 }}
              placeholder="Confirm new password"
              id="confirm"
              {...register('confirm', {
                required: 'This is required',
                minLength: {
                  value: 8,
                  message: 'New password should be at least 8 characters length',
                },
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues()
                    return password === value || 'Passwords should match!'
                  },
                },
              })}
            />
            <InputRightElement>
              <Button
                size="lg"
                onClick={() => setShowConfirm(!showConfirm)}
                variant="unstyled"
                _focus={{ boxShadow: 'none' }}
              >
                {showConfirm ? (
                  <ViewOffIcon color="gray.300" />
                ) : (
                  <ViewIcon color="gray.300" />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.confirm && errors.confirm.message}</FormErrorMessage>
        </FormControl>
        <Button
          w="full"
          variant="main"
          h={12}
          type="submit"
          fontSize="md"
          fontWeight="bold"
          mt={4}
        >
          Reset Password
        </Button>
      </Flex>
    </>
  )

  const resetPasswordSuccessUI = (
    <Flex direction="column">
      <Icon w={12} h={12} src={success} mx="auto" />
      <Text fontSize="2xl" mt={6}>
        Password Reset
      </Text>
      <Text textAlign="center" fontSize="sm" mt={4}>
        Your password has been successfully changed. Use the button below to log in to
        your account.
      </Text>
      <Button
        w="full"
        variant="main"
        h={12}
        isLoading={isLoading}
        type="submit"
        fontSize="md"
        mt={14}
        onClick={() => navigate('/', { replace: true })}
      >
        Continue
      </Button>
    </Flex>
  )

  return isLoading
    ? loadingUI
    : isSuccess
    ? resetPasswordSuccessUI
    : isError
    ? redirectUI
    : resetPasswordUI
}
