import { createIcon } from '@chakra-ui/react'

const ArrowUpIcon = createIcon({
  displayName: 'MyIcon',
  viewBox: '0 0 17 16',
  path: (
    <g id="Trailing-icon">
      <path
        id="Vector"
        d="M8.5 12.6667V3.33333"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Vector_2"
        d="M3.8335 8L8.50016 3.33333L13.1668 8"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
})

export default ArrowUpIcon
