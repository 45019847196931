import { DeleteIcon, EditIcon, StarIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Location } from '../../../app/services/api/types'
import { useLazyDeleteLocationQuery } from '../api/locationsApi'

export const SublocationCard = ({
  location,
  refetchLocation,
}: {
  location: Location
  refetchLocation: any
}) => {
  const [trigger] = useLazyDeleteLocationQuery()

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const cancelRef = useRef(null)

  const handleDelete = async () => {
    await trigger({ id: location.id })
      .unwrap()
      .then(() => {
        refetchLocation()
      })
  }
  return (
    <Box maxW="xs" borderWidth="1px" borderRadius="lg" overflow="hidden" pb={4}>
      <Box display="flex" flexDirection="column" alignContent="flex-start" p={6}>
        <Box fontSize="xl" fontWeight="bold">
          {location.title}
        </Box>
        <HStack spacing={4} fontSize="sm" color="gray" mt={2}>
          <Link to={`/locations/${location.id}/edit`}>
            <Button variant="unstyled" rightIcon={<EditIcon />}>
              Edit
            </Button>
          </Link>
          <Button
            variant="unstyled"
            onClick={() => setOpenDeleteDialog(true)}
            rightIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          <AlertDialog
            isOpen={openDeleteDialog}
            leastDestructiveRef={cancelRef}
            onClose={() => setOpenDeleteDialog(false)}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Sublocation
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={() => setOpenDeleteDialog(false)}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>
        <HStack spacing={4} mt={8}>
          <Link to={`/locations/${location.id}/devices`}>
            <Button colorScheme="button.primary" w={28} h={10}>
              Devices
            </Button>
          </Link>
        </HStack>
      </Box>
    </Box>
  )
}
