import { Text } from '@chakra-ui/react'

export const Dots = () => (
  <Text
    key="dots"
    color="text.disabled"
    fontSize="14px"
    fontWeight="700"
    lineHeight="18px"
  >
    ...
  </Text>
)
