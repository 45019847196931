import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import {
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  format,
  parse,
  startOfToday,
  isBefore,
  isEqual,
  isAfter,
  addDays,
  startOfMonth,
} from 'date-fns'
import { getDay } from 'date-fns'
import { add } from 'date-fns'
import { subDays } from 'date-fns'
import ArrowRightIcon from '../../../assets/icons/ArrowRightIcon'
import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon'
import PredefinedDates from './PredefinedDates'
import { DateRangePickerProps } from './types'

const DateRangePicker = ({
  selectedStart,
  selectedEnd,
  setSelectedStart,
  setIsOpen,
  setSelectedEnd,
}: DateRangePickerProps) => {
  const today = startOfToday()

  const [hoveredDate, setHoveredDate] = useState<null | Date>(null)
  const [currentMonth, setCurrentMonth] = useState(
    format(selectedStart || today, 'MMM-yyyy'),
  )

  const firstDayCurrentMonth = parse(
    currentMonth,
    'MMM-yyyy',
    selectedStart || new Date(),
  )
  const lastDayCurrentMonth = endOfMonth(
    parse(currentMonth, 'MMM-yyyy', selectedStart || new Date()),
  )

  const days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth, { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
  })

  const previousMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  const nextMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  const handleDateClick = (day: Date) => {
    if (selectedStart && isEqual(day, selectedStart)) {
      return
    }

    if (!selectedStart || (selectedStart && selectedEnd)) {
      setSelectedStart(day)
      setSelectedEnd(null)
    } else if (isBefore(day, selectedStart)) {
      setSelectedEnd(selectedStart)
      setSelectedStart(day)

      setHoveredDate(null)
      setTimeout(() => {
        setIsOpen(false)
      }, 200)
    } else {
      setHoveredDate(null)
      setSelectedEnd(day)
      setTimeout(() => {
        setIsOpen(false)
      }, 200)
    }
  }
  const handleDateHover = (day: Date) => {
    if (!selectedStart || (selectedStart && selectedEnd) || isAfter(day, today)) {
      return
    }

    setHoveredDate(day)
  }

  return (
    <>
      <Box
        className="date-range-picker"
        background="white"
        maxW="512px"
        border="1px"
        borderColor="#DEDFE2"
        rounded="2xl"
        zIndex="100"
      >
        <Flex my="6">
          <PredefinedDates
            selectedStart={selectedStart}
            selectedEnd={selectedEnd}
            setStartDate={setSelectedStart}
            setEndDate={setSelectedEnd}
            setCurrentMonth={setCurrentMonth}
          />

          <Flex flexDirection="column" minW="263px" mx="6">
            <Flex justifyContent="space-between" alignItems="center">
              <ArrowLeftIcon onClick={() => previousMonth()} cursor="pointer" />
              <Button variant="unstyled">
                <time>{format(firstDayCurrentMonth, 'MMM yyyy')}</time>
              </Button>
              <ArrowRightIcon onClick={() => nextMonth()} cursor="pointer" />
            </Flex>
            <Grid templateColumns="repeat(7, 1fr)" mt="3">
              <GridItem display="flex" justifyContent="center">
                <Text color="#9B9B9B" fontSize="12px" fontWeight="400">
                  mo
                </Text>
              </GridItem>
              <GridItem display="flex" justifyContent="center">
                <Text color="#9B9B9B" fontSize="12px" fontWeight="400">
                  tu
                </Text>
              </GridItem>
              <GridItem display="flex" justifyContent="center">
                <Text color="#9B9B9B" fontSize="12px" fontWeight="400">
                  we
                </Text>
              </GridItem>
              <GridItem display="flex" justifyContent="center">
                <Text color="#9B9B9B" fontSize="12px" fontWeight="400">
                  th
                </Text>
              </GridItem>
              <GridItem display="flex" justifyContent="center">
                <Text color="#9B9B9B" fontSize="12px" fontWeight="400">
                  fr
                </Text>
              </GridItem>
              <GridItem display="flex" justifyContent="center">
                <Text color="#9B9B9B" fontSize="12px" fontWeight="400">
                  sa
                </Text>
              </GridItem>
              <GridItem display="flex" justifyContent="center">
                <Text color="#9B9B9B" fontSize="12px" fontWeight="400">
                  su
                </Text>
              </GridItem>
            </Grid>
            <Box my="4" border="2px" borderColor="#DEDFE2" />
            <Grid templateColumns="repeat(7, 1fr)" rowGap={7}>
              {days.map((day) => {
                const isInRange =
                  selectedStart &&
                  selectedEnd &&
                  isAfter(addDays(day, 0), selectedStart) &&
                  isBefore(subDays(day, 0), selectedEnd)

                const isInRangeHover =
                  (selectedStart &&
                    hoveredDate &&
                    isAfter(addDays(day, 0), selectedStart) &&
                    isBefore(subDays(day, 0), hoveredDate)) ||
                  (isAfter(addDays(day, 0), hoveredDate!) &&
                    isBefore(subDays(day, 0), selectedStart!))

                const isSelected =
                  (selectedStart && isEqual(day, selectedStart)) ||
                  (selectedEnd && isEqual(day, selectedEnd))

                const isHovered = isEqual(day, hoveredDate!)

                const isHoveredDayEqualToStart = isEqual(selectedStart!, hoveredDate!)

                const shouldRenderRightBorder =
                  (isAfter(selectedStart!, hoveredDate!) &&
                    isEqual(day, selectedStart!)) ||
                  (isBefore(selectedStart!, hoveredDate!) &&
                    isEqual(day, hoveredDate!)) ||
                  isEqual(day, selectedEnd!) ||
                  isHoveredDayEqualToStart

                const shouldRenderLeftBorder =
                  (!hoveredDate && isEqual(day, selectedStart!)) ||
                  (isEqual(day, hoveredDate!) && isAfter(selectedStart!, hoveredDate!)) ||
                  (isEqual(selectedStart!, day!) &&
                    isBefore(selectedStart!, hoveredDate!)) ||
                  isHoveredDayEqualToStart

                const removeStyles = isEqual(hoveredDate!, selectedStart!)
                return (
                  <GridItem
                    onClick={() => handleDateClick(day)}
                    onMouseEnter={() => handleDateHover(day)}
                    colStart={getDay(day)}
                    display="flex"
                    key={day.toString()}
                    bg={isInRangeHover || isInRange ? '#F7F9FD' : 'transparent'}
                    bgImage={
                      (isInRangeHover || isInRange) && !removeStyles
                        ? 'repeating-linear-gradient(90deg, #157FEE, #157FEE 7px, transparent 7px, transparent 14px, #157FEE 14px), repeating-linear-gradient(270deg, #157FEE, #157FEE 6px, transparent 6px, transparent 10px, #157FEE 12px)'
                        : 'none'
                    }
                    bgSize="100% 1px, 100% 1px"
                    bgPosition="0 0, 0 100%"
                    bgRepeat="no-repeat"
                    _hover={{
                      Button: {
                        background: isSelected
                          ? 'blue.400'
                          : hoveredDate && isEqual(day, hoveredDate)
                          ? '#DEDFE2'
                          : '#DEDFE2',
                      },
                    }}
                  >
                    {/* Remove true || to remove days that are not in current month */}
                    {true ||
                    (!isBefore(day, startOfMonth(new Date(currentMonth))) &&
                      !isAfter(day, endOfMonth(new Date(currentMonth)))) ? (
                      <Box position="relative">
                        {shouldRenderRightBorder && !removeStyles ? (
                          <Box
                            bg={'#F7F9FD'}
                            position="absolute"
                            top={0}
                            left="-10px"
                            right={0}
                            bottom={0}
                            w="15px"
                            bgImage={
                              'repeating-linear-gradient(90deg, #157FEE, #157FEE 7px, transparent 7px, transparent 14px, #157FEE 14px), repeating-linear-gradient(270deg, #157FEE, #157FEE 6px, transparent 6px, transparent 10px, #157FEE 12px)'
                            }
                            bgSize="100% 1px, 100% 1px"
                            bgPosition="0 0, 0 100%"
                            bgRepeat="no-repeat"
                          />
                        ) : null}
                        <Button
                          zIndex="10"
                          style={{ boxShadow: 'none' }}
                          size="xs"
                          w="24px"
                          h="24px"
                          padding="0"
                          variant="ghost"
                          boxShadow="none"
                          disabled={isAfter(day, today)}
                          outline="0"
                          color={
                            isBefore(day, firstDayCurrentMonth) ||
                            (isAfter(day, lastDayCurrentMonth) && !isAfter(day, today))
                              ? '#cecfd0'
                              : (isSelected && 'whiteAlpha.900') || 'BlackAlpha.200'
                          }
                          bg={
                            isSelected
                              ? 'blue.400'
                              : isHovered
                              ? '#DEDFE2'
                              : 'transparent'
                          }
                          rounded="full"
                        >
                          <time dateTime={format(day, 'yyyy-MM-dd')}>
                            {format(day, 'd')}
                          </time>
                        </Button>
                        {shouldRenderLeftBorder && !removeStyles ? (
                          <Box
                            bg={'#F7F9FD'}
                            position="absolute"
                            top={0}
                            left="17px"
                            right={0}
                            bottom={0}
                            w="25px"
                            bgImage={
                              'repeating-linear-gradient(90deg, #157FEE, #157FEE 7px, transparent 7px, transparent 14px, #157FEE 14px), repeating-linear-gradient(270deg, #157FEE, #157FEE 6px, transparent 6px, transparent 10px, #157FEE 12px)'
                            }
                            bgSize="100% 1px, 100% 1px"
                            bgPosition="0 0, 0 100%"
                            bgRepeat="no-repeat"
                          />
                        ) : null}
                      </Box>
                    ) : null}
                  </GridItem>
                )
              })}
            </Grid>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default DateRangePicker
