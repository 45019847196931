import { Center, Spinner } from '@chakra-ui/react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useUser } from '../context/UserContext'

type Props = {
  redirectTo: string
  children?: JSX.Element
}

export const PrivateRoute = ({ children, redirectTo }: Props): JSX.Element => {
  const { user, isError } = useUser()
  const token = window.localStorage.getItem('aiot_t')
  const location = useLocation()

  if (token && user) {
    if (children) {
      return <>{children}</>
    } else {
      return <Outlet />
    }
  }

  if (!token || isError) {
    return <Navigate replace to={redirectTo} state={{ from: location }} />
  }

  return (
    <Center w="full" h="100vh">
      <Spinner size="xl"></Spinner>
    </Center>
  )
}
