import { createIcon } from '@chakra-ui/icon'

const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 16 16',
  path: (
    <g fill="none">
      <path
        d="M3.33366 8L12.667 8"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00033 3.33334L12.667 8L8.00033 12.6667"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
})

export default ArrowRightIcon
