// DeviceInfoLink.tsx
import React from 'react'
import {
  createSearchParams,
  Link as ReactRouterLink,
  useNavigate,
} from 'react-router-dom'

interface DeviceInfoLinkProps {
  id: number
  title: string
  queryParams: Record<string, any>
}

const DeviceInfoLink: React.FC<DeviceInfoLinkProps> = ({ id, title, queryParams }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate({
      pathname: '/deviceInfo',
      search: `?${createSearchParams({
        ...queryParams,
        device: id.toString(),
      })}`,
    })
  }

  return (
    <ReactRouterLink
      to={`/deviceInfo?${createSearchParams({
        ...queryParams,
        device: id.toString(),
      })}`}
      onClick={handleClick}
    >
      {title}
    </ReactRouterLink>
  )
}

export default DeviceInfoLink
