import { pythonApi } from '../../../app/services/api/api'
import { Defects } from '../../dashboard/types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getDefectsInfo: builder.query<Defects.Response, any>({
      query: ({ location, device }) => {
        const queryDeviceStr = device ? `&device=${device}` : ''
        const queryLocationStr = location ? `?location=${location}` : ''
        return {
          url: `/defects/${queryLocationStr}${queryDeviceStr}`,
          method: 'GET',
        }
      },
      providesTags: ({ results }: any) => [
        'Defects',
        ...results.map(({ id }: Defects.Defect) => ({ type: 'Defects', id })),
      ],
    }),
  }),
  overrideExisting: false,
})

export const { useGetDefectsInfoQuery } = extendedApi
