import { createIcon } from '@chakra-ui/icon'

export const BarIcon = createIcon({
  displayName: 'BarIcon',
  viewBox: '0 0 24.437 20.936',
  path: (
    <g
      stroke="currentColor"
      id="Group_119"
      data-name="Group 119"
      transform="translate(-97 -144)"
    >
      <rect
        id="Rectangle_120"
        data-name="Rectangle 120"
        width="5.503"
        height="9.542"
        transform="translate(98 154.394)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        id="Rectangle_121"
        data-name="Rectangle 121"
        width="5.503"
        height="13.595"
        transform="translate(106.467 150.341)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        id="Rectangle_122"
        data-name="Rectangle 122"
        width="5.503"
        height="18.936"
        transform="translate(114.935 145)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  ),
})
