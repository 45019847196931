import React, { useCallback, useMemo } from 'react'
import { HStack } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import { PaginationProps } from './types'
import { PageButton } from './components/PageButton'
import { Dots } from './components/Dots'
import { useLocation, useNavigate } from 'react-router-dom'

export const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const pagesArray = useMemo(
    () => Array.from({ length: totalPages }, (_, index) => index + 1),
    [totalPages],
  )

  const firstPage = pagesArray[0]
  const lastPage = pagesArray[pagesArray.length - 1]

  const pagesToShow = useMemo(() => {
    if (totalPages <= 2) {
      return []
    }
    if (totalPages < 6) {
      return pagesArray.slice(1, totalPages - 1)
    }

    if (currentPage < 4) {
      return pagesArray.slice(1, 4)
    }

    if (currentPage > totalPages - 3) {
      return pagesArray.slice(totalPages - 4, totalPages - 1)
    }

    return pagesArray.slice(currentPage - 2, currentPage + 1)
  }, [currentPage, pagesArray, totalPages])

  const handleSetCurrentPage = useCallback(
    (page) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('page', page.toString())
      navigate(`${location.pathname}?${searchParams.toString()}`)
    },
    [location.pathname, location.search, navigate],
  )

  return (
    <HStack my="24px" justifyContent="space-around">
      <HStack gap="2">
        <PageButton
          page={firstPage}
          currentPage={currentPage}
          setCurrentPage={handleSetCurrentPage}
        />

        {currentPage > 3 && totalPages > 5 && <Dots />}
        {pagesToShow.map((page) => (
          <AnimatePresence exitBeforeEnter>
            <PageButton
              key={page}
              page={page}
              currentPage={currentPage}
              setCurrentPage={handleSetCurrentPage}
            />
          </AnimatePresence>
        ))}
        {currentPage < lastPage - 2 && totalPages > 5 && <Dots />}
        <PageButton
          page={lastPage}
          currentPage={currentPage}
          setCurrentPage={handleSetCurrentPage}
        />
      </HStack>
    </HStack>
  )
}
