import { createIcon } from '@chakra-ui/icons'

const PowerIcon = createIcon({
  displayName: 'PowerIcon',
  viewBox: '0 0 16 16',
  path: (
    <g id="Icons/power">
      <path
        id="Vector"
        d="M3.76 4.42676C2.92108 5.26595 2.34983 6.33505 2.11848 7.49888C1.88712 8.66272 2.00606 9.86902 2.46024 10.9653C2.91442 12.0615 3.68346 12.9985 4.67011 13.6577C5.65677 14.3169 6.81673 14.6687 8.00333 14.6687C9.18994 14.6687 10.3499 14.3169 11.3365 13.6577C12.3232 12.9985 13.0922 12.0615 13.5464 10.9653C14.0006 9.86902 14.1195 8.66272 13.8882 7.49888C13.6568 6.33505 13.0856 5.26595 12.2467 4.42676"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Vector_2"
        d="M8 1.33301V7.99967"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
})

export default PowerIcon
