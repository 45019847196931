import { Box, GridItem } from '@chakra-ui/react'
import React from 'react'
import { TileContainerProps } from './types'

export const TileContainer: React.FC<TileContainerProps> = ({ children }) => {
  const itemCount = React.Children.count(children)

  // Calculate the number of columns based on child count
  let columns = 'repeat(2, 1fr)'
  if (itemCount === 1) {
    columns = '1fr'
  } else if (itemCount === 3) {
    columns = '1fr 1fr'
  }
  return (
    <Box display="grid" gridTemplateColumns={columns} gap="8px" width="100%">
      {React.Children.map(children, (child, index) => (
        <GridItem
          key={index}
          colSpan={index + 1 === itemCount && (index + 1) % 2 !== 0 ? 2 : 1}
        >
          {child}
        </GridItem>
      ))}
    </Box>
  )
}
