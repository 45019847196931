import React, { MouseEventHandler } from 'react'
import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'
import './RangePicker.css'

type RangePickerProps = {
  openCalendar?: MouseEventHandler<HTMLDivElement>
  value?: string[]
}

const RangePicker: React.FC<RangePickerProps> = ({ openCalendar, value }) => {
  const from = (value && value[0]) || ''
  const to = (value && value[1]) || ''
  return (
    <Stack direction="row" color="#157FEE">
      <HStack onClick={openCalendar}>
        <CalendarIcon /> {(!from || !to) && <Text>Custom Period</Text>}
      </HStack>

      <Box hidden={!from || !to} onClick={openCalendar}>
        <Text>
          {dayjs(from).format('DD-MM-YYYY')}-{dayjs(to).format('DD-MM-YYYY')}
        </Text>
      </Box>
    </Stack>
  )
}

export default RangePicker
