import { createIcon } from '@chakra-ui/react'

const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 24 24',
  path: (
    <g id="Leading-icon">
      <g id="Union">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8293 7C10.4175 8.16519 9.30622 9 8 9C6.69378 9 5.58254 8.16519 5.17071 7H4C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5H5.17071C5.58254 3.83481 6.69378 3 8 3C9.30622 3 10.4175 3.83481 10.8293 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H10.8293ZM7 6C7 5.44772 7.44772 5 8 5C8.55228 5 9 5.44772 9 6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 12C3 11.4477 3.44772 11 4 11H13.1707C13.5825 9.83481 14.6938 9 16 9C17.3062 9 18.4175 9.83481 18.8293 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H18.8293C18.4175 14.1652 17.3062 15 16 15C14.6938 15 13.5825 14.1652 13.1707 13H4C3.44772 13 3 12.5523 3 12ZM15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H8.17071C8.58254 20.1652 9.69378 21 11 21C12.3062 21 13.4175 20.1652 13.8293 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H13.8293C13.4175 15.8348 12.3062 15 11 15C9.69378 15 8.58254 15.8348 8.17071 17H4ZM11 17C10.4477 17 10 17.4477 10 18C10 18.5523 10.4477 19 11 19C11.5523 19 12 18.5523 12 18C12 17.4477 11.5523 17 11 17Z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
})

export default FilterIcon
