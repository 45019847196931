import { createIcon } from '@chakra-ui/icons'

// Define the icon using the createIcon utility
const Search = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 16 16',
  path: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Leading Icon">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33334 2.66683C4.75601 2.66683 2.66668 4.75617 2.66668 7.3335C2.66668 9.91083 4.75601 12.0002 7.33334 12.0002C9.91067 12.0002 12 9.91083 12 7.3335C12 4.75617 9.91067 2.66683 7.33334 2.66683ZM1.33334 7.3335C1.33334 4.01979 4.01963 1.3335 7.33334 1.3335C10.6471 1.3335 13.3333 4.01979 13.3333 7.3335C13.3333 10.6472 10.6471 13.3335 7.33334 13.3335C4.01963 13.3335 1.33334 10.6472 1.33334 7.3335Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6286 10.6288C10.889 10.3684 11.3111 10.3684 11.5714 10.6288L14.4714 13.5288C14.7318 13.7891 14.7318 14.2112 14.4714 14.4716C14.2111 14.7319 13.789 14.7319 13.5286 14.4716L10.6286 11.5716C10.3683 11.3112 10.3683 10.8891 10.6286 10.6288Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  ),
})

export default Search
