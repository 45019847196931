import { Box } from '@chakra-ui/react'
import React, { useState, useMemo } from 'react'
import { PieChart, Pie, Cell, Sector } from 'recharts'
import { DOUGHNUT_COLORS } from '../../../features/analysis/utils'

interface IDataEntry {
  name: string
  value: number
  startAngle?: number
  endAngle?: number
}

const getTotalValue = (data: IDataEntry[]): number =>
  data.reduce((acc, entry) => acc + entry.value, 0)

const calculateAngles = (data: IDataEntry[]): IDataEntry[] => {
  let startAngle = 0
  const totalValue = getTotalValue(data)
  return data.map((entry) => {
    const endAngle = startAngle + (entry.value / totalValue) * 360
    const segment: IDataEntry = { ...entry, startAngle, endAngle }
    startAngle = endAngle
    return segment
  })
}

const BRIGHTENED_COLORS = [
  '#c5def5',
  '#c6e7f7',
  '#d5ecf7',
  '#e4f0f8',
  '#eff6fb',
  '#f7fbfd',
]

const DoughnutChart: React.FC<{
  data: IDataEntry[]
  activeName: string
  setActiveName: any
  isLoading: boolean
}> = ({ data, activeName, setActiveName, isLoading }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const enhancedData = useMemo(() => calculateAngles(data), [data])

  const onPieEnter = (item: any, index: number): void => {
    setActiveName(item.name)
    setActiveIndex(index)
  }

  const getPosition = (startAngle: number, endAngle: number): number => {
    const midpointAngle = ((startAngle + endAngle) / 2) % 360 // Normalize to 0-360 degrees if necessary
    if (midpointAngle > 270 || midpointAngle <= 90) return 0 // Top
    if (midpointAngle > 90 && midpointAngle <= 270) return 2 // Bottom
    if (midpointAngle > 0 && midpointAngle <= 180) return 1 // Right
    return 3 // Left
  }

  const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

    return (
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    )
  }

  return (
    <Box>
      <PieChart width={500} height={500}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          innerRadius={120}
          outerRadius={170}
          dataKey="value"
          onMouseEnter={onPieEnter}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
        >
          {data.map((entry, index) => {
            const isActive = activeName === entry.name
            const segmentColor = isActive
              ? DOUGHNUT_COLORS[index]
              : BRIGHTENED_COLORS[index]

            const enhancedEntry = enhancedData.find((d) => d.name === entry.name)

            const segmentPosition = getPosition(
              enhancedEntry?.startAngle ?? 0,
              enhancedEntry?.endAngle ?? 0,
            )

            return (
              <Cell
                key={`cell-${index}`}
                fill={segmentColor}
                stroke="none"
                style={{
                  outline: 'none',
                  filter: `drop-shadow(${segmentPosition === 3 ? '6px' : '0px'} ${
                    segmentPosition === 2 ? '6px' : '0px'
                  } 8px ${segmentColor})`,
                }}
              />
            )
          })}
        </Pie>
      </PieChart>
    </Box>
  )
}

export default DoughnutChart
