// CookieBanner.js

import React, { useState, useEffect } from 'react'
import { Button, Flex, Text, Heading } from '@chakra-ui/react'
declare global {
  interface Window {
    dataLayer: any
    _hjSettings: {
      hjid: number
      hjsv: number
    }
    hj: any
    'ga-disable-G-CNCJ770XEX': any
  }
}

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(!localStorage.getItem('cookieConsent'))

  const loadHotjarScript = () => {
    ;(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: 3783725, hjsv: 6 } //@ts-ignore
      a = o.getElementsByTagName('head')[0] //@ts-ignore
      r = o.createElement('script') //@ts-ignore
      r.async = 1 //@ts-ignore
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv //@ts-ignore
      a.appendChild(r)
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
  }

  const loadCookies = () => {
    document.cookie = 'cookieConsent=accepted; SameSite=None; Secure'
  }

  const loadGoogleTagScript = () => {
    const gtagScript = document.createElement('script')
    gtagScript.async = true
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-CNCJ770XEX'
    document.head.appendChild(gtagScript)

    gtagScript.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag(...args: any[]) {
        window.dataLayer.push(args)
      }
      gtag('js', new Date())
      gtag('config', 'G-CNCJ770XEX')
    }
  }

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted')
    setShowBanner(false)
    loadHotjarScript()
    loadGoogleTagScript()
    loadCookies()
  }

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined')
    setShowBanner(false)

    // Disable HotJar
    window.hj =
      window.hj ||
      function () {
        ;(window.hj.q = window.hj.q || []).push(arguments)
      }
    window._hjSettings = { hjsv: 6, hjid: 3783725 }
    window.hj('disable')

    // Disable Google Tag (Gtag)
    window['ga-disable-G-CNCJ770XEX'] = true
  }

  useEffect(() => {
    if (localStorage.getItem('cookieConsent') === 'accepted') {
      loadHotjarScript()
      loadGoogleTagScript()
      loadCookies()
    }
  }, [])

  if (!showBanner) {
    return null
  }

  return (
    <Flex
      position="fixed"
      maxW="900px"
      margin={'0 auto'}
      direction="column"
      bottom="0"
      left="0"
      right="0"
      backgroundColor="gray.100"
      padding="1rem"
      justifyContent="center"
      alignItems="center"
    >
      <Flex direction="column">
        <Heading>We use cookies</Heading>
        <Text>
          We use cookies and other tracking technologies to improve your browsing
          experience on our website, to analyze our website traffic, and to understand
          where our visitors are coming from.
        </Text>
      </Flex>
      <Flex pt="20px">
        <Button onClick={handleAccept} colorScheme="button.primary" marginRight="1rem">
          I agree
        </Button>
        <Button onClick={handleDecline} colorScheme="button.secondary">
          I decline
        </Button>
      </Flex>
    </Flex>
  )
}

export default CookieConsent
