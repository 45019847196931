import { createIcon } from '@chakra-ui/icon'

export const EllipseIcon = createIcon({
  displayName: 'EllipseIcon',
  viewBox: '0 0 12 12',
  path: (
    <g fill="none">
      <circle cx="6" cy="6" r="5.5" stroke="currentColor" />
    </g>
  ),
})
