import { Box, Flex, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CustomTabs from '../../../app/components/CustomTabs/CustomTabs'
import Header from '../../../app/components/Header'
import { useGetAllLocationsQuery } from '../../reports/api/reportsApi'
import { useGetAllDevicesQuery } from '../api/automationsApi'
import { Climate } from '../components/Climate'
import { Relay } from '../components/Relay'

const Automations = () => {
  const [index, setIndex] = useState(0)

  const {
    data: { results: devices = [] } = {},
    error,
    isLoading,
  } = useGetAllDevicesQuery()

  const { isLoading: isLocationsLoading, data: { results: locations = [] } = {} } =
    useGetAllLocationsQuery()

  const filterDevicesByCategory = (devices, category) => {
    return devices.filter((device) => device.category === category)
  }

  const relays = filterDevicesByCategory(devices, 'relay')
  const climateControls = filterDevicesByCategory(devices, 'climate_control')

  const componentMap = {
    0: Relay,
    1: Climate,
  }

  useEffect(() => {
    if (!isLoading) {
      setIndex(relays.length > 0 ? 0 : 1)
    }
  }, [relays.length, climateControls.length, isLoading])

  if (isLocationsLoading && isLoading) {
    return (
      <Flex h="100vh" w="100%" justifyContent="space-around" align="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (relays.length === 0 && climateControls.length === 0) {
    return (
      <>
        <Header title="Automations" />
        <Box>No devices are currently available for control.</Box>
      </>
    )
  }

  const props = { isLoading }

  const componentProps =
    index === 0 ? { ...props, relays } : { ...props, climateControls, locations }

  const Component = componentMap[index]

  return (
    <>
      <Header title="Automations" />
      {relays.length > 0 && climateControls.length > 0 ? (
        <CustomTabs
          tabs={[{ label: 'Relay' }, { label: 'Climate Control' }]}
          activeIndex={index}
          setActiveIndex={setIndex}
        />
      ) : null}

      <Component {...componentProps} />
    </>
  )
}

export default Automations
