import { Outlet } from 'react-router-dom'
import aiot from '../../../assets/Login/aiot.mp4'

export const Background: React.FC = () => {
  return (
    <>
      <video
        id="background-video"
        loop
        autoPlay
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          objectFit: 'cover',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: -1,
        }}
      >
        <source src={aiot} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Outlet />
    </>
  )
}
