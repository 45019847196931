import { createIcon } from '@chakra-ui/react'

export const TrendDownIcon = createIcon({
  displayName: 'TrendDownIcon',
  viewBox: '0 0 16 16', // Ensures the icon scales properly within its container
  // Directly utilizing the provided SVG path for the "trend down" representation
  path: (
    <path
      d="M7.53408 10.8346C7.79796 11.2305 8.3796 11.2305 8.64348 10.8346L12.0645 5.70315C12.3598 5.26012 12.0422 4.66669 11.5098 4.66669H4.66779C4.13532 4.66669 3.81773 5.26012 4.11309 5.70315L7.53408 10.8346Z"
      fill="currentColor"
    />
  ),
})
