const colors = {
  red: {
    500: '#CF202F',
    200: '#FDC8CC',
  },
  orange: {
    300: '#FFE3D2',
  },
  yellow: {
    300: '#FFF7CA',
  },
  text: {
    light: '#9b9b9b',
    intermediate: '#a0a0a0',
    dark: '#484848',
    secondary: '#707070',
    brand: '#0080ea',
    on: '#FFFFFF',
    disabled: '#CECFD0',
  },
  heading: { dark: '#484848' },

  button: {
    primary: {
      50: '#ade4ff',
      500: 'linear-gradient(to right, #1DABFC, #157FEE)',
      600: 'linear-gradient(to right, #1DABFC, #1DABFC)',
    },
    secondary: {
      50: '#ffcccc',
      500: 'linear-gradient(to right, #FF8888, #FF5555)',
      600: 'linear-gradient(to right, #FF8888, #FF8888)',
    },
  },
  container: {
    background: {
      gray: '#f5f6f7',
    },
  },
  border: {
    light: '#DEDFE2',
    strong: '#9B9B9B',
    strongest: '#484848',
  },
  icon: {
    primary: '#484848',
    strong: '#157FEE',
    on: '#FFFFFF',
    hover: '#1DABFC',
    terirary: '#9B9B9B',
  },
}

export default colors
