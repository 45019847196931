import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const isFetchBaseQueryError = (
  error: any,
): error is {
  status: number
  data: unknown
} => {
  return typeof error.status === 'number'
}

const useRedirectOn404 = (error: any, message: string) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      if (isFetchBaseQueryError(error) && error.status === 404) {
        navigate('/404', { state: { message } })
      }
    }
  }, [error, navigate, message])
}

export default useRedirectOn404
