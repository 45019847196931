import { Skeleton } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { Doughnut } from '../../../app/components/Doughnut'
import { Tile } from '../../../app/components/Tile'
import { TileContainer } from '../../../app/components/TileContainer'
import { calculatePercentage } from '../../../utils/helpers'
import { DOUGHNUT_COLORS } from '../../analysis/utils'
import { ReportData } from '../types'

interface StatsAllProps {
  isBaseReportLoading: boolean
  baseReportData: ReportData.Data
  totalConsumption: number
}

const StatsAll: React.FC<StatsAllProps> = ({
  isBaseReportLoading,
  baseReportData,
  totalConsumption,
}) => {
  const [activeName, setActiveName] = useState<string>('')

  const doughnutData = useMemo<{ name: string; value: number }[]>(
    () =>
      baseReportData?.device_type_grouping
        ?.map((deviceTypeGroup) => ({
          name: deviceTypeGroup.name,
          value: Number(deviceTypeGroup.total_consumption),
        }))
        .sort((a, b) => b.value - a.value),
    [baseReportData],
  )

  return (
    <>
      <Doughnut
        doughnutData={doughnutData}
        activeName={activeName}
        setActiveName={setActiveName}
        overviewFetching={isBaseReportLoading}
      />

      {isBaseReportLoading ? (
        <TileContainer>
          <Skeleton width="100%" height="78px" padding="12px" borderRadius="12px" />
          <Skeleton width="100%" height="78px" padding="12px" borderRadius="12px" />
          <Skeleton width="100%" height="78px" padding="12px" borderRadius="12px" />
          <Skeleton width="100%" height="78px" padding="12px" borderRadius="12px" />
        </TileContainer>
      ) : (
        <TileContainer>
          {doughnutData?.map((deviceTypeGroup, index) => (
            <Tile
              key={deviceTypeGroup.name}
              name={deviceTypeGroup.name}
              value={deviceTypeGroup.name}
              percentage={calculatePercentage(deviceTypeGroup.value, totalConsumption)}
              setActiveName={setActiveName}
              isLoading={isBaseReportLoading}
              doughnutChartColor={DOUGHNUT_COLORS[index]}
            />
          ))}
        </TileContainer>
      )}
    </>
  )
}

export default StatsAll
