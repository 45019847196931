import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { KILOWATT_HOUR } from '../../../utils/consts'
import { addCommasToNumber } from '../../../utils/helpers'
import { ProgressBar } from './ProgressBar'

export interface ProgressBarWrapperProps {
  title: string
  kilowatts: number
  percentage: number
  variant: 'generator' | 'consumer'
}

export const ProgressBarWrapper: React.FC<ProgressBarWrapperProps> = ({
  title,
  kilowatts,
  percentage,
  variant,
}) => {
  return (
    <Box w="100%">
      <Flex justifyContent="space-between">
        <Text color="#484848" fontSize="14px" fontWeight={400}>
          {title}
        </Text>
        <Box>
          <Text color="text.secondary" fontSize="14px" fontWeight={700}>
            {addCommasToNumber(kilowatts)} {KILOWATT_HOUR} • {percentage} %
          </Text>
        </Box>
      </Flex>

      <Box mt="8px">
        <ProgressBar value={percentage} variant={variant} />
      </Box>
    </Box>
  )
}
