import { createIcon } from '@chakra-ui/react'

export const TrendUpIcon = createIcon({
  displayName: 'TrendUpIcon',
  viewBox: '0 0 16 16', // Use the same viewbox dimensions for consistency
  path: (
    <g fill="none">
      {/* This path is designed to visually represent an uptrend arrow within the same 16x16 viewBox. */}
      <path
        d="M8.46592 5.1654C8.20204 4.7695 7.6204 4.7695 7.35652 5.1654L3.93549 10.2969C3.64013 10.7399 3.95772 11.3333 4.49019 11.3333H11.3322C11.8647 11.3333 12.1823 10.7399 11.8869 10.2969L8.46592 5.1654Z"
        fill="currentColor"
      />
    </g>
  ),
})
