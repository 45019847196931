import { createIcon } from '@chakra-ui/react'

const MethodiaLogo = createIcon({
  displayName: 'CustomIcon',
  viewBox: '500 400 1560 640',
  path: (
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,1440)">
      <g>
        <defs>
          <rect id="SVGID_1_" x="-320" y="-180" width="2560" height="1440" />
        </defs>
        <clipPath id="SVGID_00000138569414015759365930000006098080544213597590_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
        </clipPath>
      </g>
      <g>
        <defs>
          <rect
            id="SVGID_00000048463651856128146360000016945216421043071672_"
            x="-320"
            y="-180"
            width="2560"
            height="1440"
          />
        </defs>
        <clipPath id="SVGID_00000162312114035870552430000008688257717151450047_">
          <use
            xlinkHref="#SVGID_00000048463651856128146360000016945216421043071672_"
            style={{ overflow: 'visible' }}
          />
        </clipPath>
        <g
          style={{
            clipPath: 'url(#SVGID_00000162312114035870552430000008688257717151450047_)',
          }}
        >
          <g transform="translate(867.5876,739.9313)">
            <path
              fill="#EECEC7"
              d="M0,0c-51.7-51.7-51.7-135.5,0-187.1c51.7-51.7,135.5-51.7,187.1,0 c51.7,51.7,51.7,135.5,0,187.1C135.5,51.7,51.7,51.7,0,0"
            />
          </g>
          <g transform="translate(985.6565,776.4069)">
            <path
              fill="#E3A59C"
              d="M0,0l105.5-105.5c-1.6,8.5-4,16.8-7.3,24.9L24.9-7.3C16.8-4,8.5-1.6,0,0"
            />
          </g>
          <g transform="translate(1054.7268,739.9303)">
            <path
              fill="#E9B9B0"
              d="M0,0c-11.9,11.9-25.5,21-40,27.4L27.4-40C21-25.5,11.9-11.9,0,0"
            />
          </g>
          <g transform="translate(958.5691,778.6578)">
            <path
              fill="#DD9289"
              d="M0,0l134.9-134.9c0.2,8.1-0.4,16.2-1.7,24.2l-109,109C16.2-0.4,8.1,0.2,0,0"
            />
          </g>
          <g transform="translate(931.5229,775.3365)">
            <path
              fill="#D98179"
              d="M0,0l158.6-158.6c1.9,8.1,2.9,16.4,3.3,24.7L24.7,3.3C16.4,2.9,8.1,1.9,0,0"
            />
          </g>
          <g transform="translate(904.2212,765.8424)">
            <path
              fill="#D3706B"
              d="M0,0l176.4-176.4c3.9,8.2,6.9,16.7,9,25.3L25.3,9C16.7,6.9,8.2,3.9,0,0"
            />
          </g>
          <g transform="translate(877.2076,748.6559)">
            <path
              fill="#CE5D5E"
              d="M0,0l186.2-186.2c6.5,7.9,12,16.5,16.4,25.4L25.4,16.4C16.5,12,7.9,6.5,0,0"
            />
          </g>
          <g transform="translate(867.5865,739.9303)">
            <path
              fill="#CA4A52"
              d="M0,0c-5.9-5.9-11.1-12.2-15.6-18.8l184-184c6.6,4.5,12.9,9.7,18.8,15.6 c2.7,2.7,5.2,5.5,7.6,8.3L8.3,7.6C5.5,5.2,2.7,2.7,0,0"
            />
          </g>
          <g transform="translate(832.615,677.8698)">
            <path
              fill="#C8404D"
              d="M0,0l160.1-160.1c14.7,3.6,28.9,9.7,41.8,18.4L18.4,41.8C9.7,28.9,3.6,14.7,0,0"
            />
          </g>
          <g transform="translate(867.5865,552.7906)">
            <path
              fill="#C63548"
              d="M0,0c33.4-33.4,80.2-45.2,123.1-35.4L-35.4,123.1C-45.2,80.2-33.4,33.4,0,0"
            />
          </g>
          <g transform="translate(885.9119,689.2828)">
            <path
              fill="#FFFFFF"
              d="M0,0v-90.3h34.8l0,65.7c0,0,6.1,1.9,13.1,1.8c7.1-0.1,10.1-3.9,10.1-9.8v-57.6h35.3v65.1 c0,0,5.5,3,13.6,2.3c8.1-0.7,8.1-6.6,8.4-10.1c0.3-3.4,0-57.3,0-57.3h35.3v73.7c0,0,0,11.8-8.6,16.7S122.4,6,111.8,2.3 C101.1-1.3,91.3-8.4,91.3-8.4s-3.7,9.1-14.5,12C66.1,6.4,52.6,2,49.6,0.7c-3-1.4-15-8.1-15-8.1V4L0,0z"
            />
          </g>
          <g transform="translate(550.6816,378.8434)">
            <path
              fill="#858789"
              d="M0,0v90.3l34.5,4.4v-12c10,7.8,22.8,12.2,33.4,12.2c12,0,20.2-4.7,23.3-12.8 c10,7.8,24.1,12.8,34.6,12.8c14.6,0,22.8-6.7,24.3-18.2c0.7-5.5,0.7-13.7,0.7-23.9V0H116v47c0,8.2-0.2,13.5-1.3,16 c-1.5,3.5-4.4,4.4-8.4,4.4c-4.9,0-9.1-0.9-13.7-2.4c0.2-3.8,0.2-8.4,0.2-12.9V0H58v47c0,8.2-0.2,13.5-1.3,16 c-1.5,3.5-4.4,4.4-8.4,4.4c-4.9,0-8.9-0.9-13.5-2.4V0H0z"
            />
          </g>
          <g transform="translate(775.3418,440.4781)">
            <path
              fill="#858789"
              d="M0,0c0,7.3-4.6,10.4-12,10.4c-9.5,0-13.3-6.2-13.3-15h25.2C0-3.3,0-1.8,0,0 M-14.2-64.2 C-44.1-64.2-60-45.4-60-17.3C-60,16.6-38.1,33-12.4,33C27,33,31,8.6,31-15.5c0-1.8-0.2-6.6-0.5-10h-56.7c0-0.5,0.2-2,0.4-2.7 c1.6-6.9,7.5-10.6,17.9-10.6c9.3,0,20.6,1.6,29.9,5.5l8.6-19.9C15.9-61.1,2.2-64.2-14.2-64.2"
            />
          </g>
          <g transform="translate(856.9047,377.3853)">
            <path
              fill="#858789"
              d="M0,0c-10,0-18.4,3.5-22.4,8.8c-4,5.3-6.4,13.3-6.4,26.8V68h-12.9v23.9l14.2,1.8l1.3,21.9 L6,119.1V93.5h15.5V68H6V42.7C6,35.6,6.4,31,8.2,28.8c1.6-2,4-2.6,6.9-2.6c2.4,0,4.9,0,9.1,0.5l2.9-23.7C20.4,1.1,13.5,0,0,0"
            />
          </g>
          <g transform="translate(897.2692,378.8434)">
            <path
              fill="#858789"
              d="M0,0v136c0,0,6.8-3.6,16.6-6.8c10.5-3.4,18.1-4.4,18.1-4.4L34.8,83c10,7.7,22.2,11.9,32.8,11.9 c14.6,0,22.8-6.7,24.3-18.2c0.7-5.5,0.7-13.7,0.7-23.9V0H57.8v47c0,8.2-0.2,13.5-1.3,16c-1.5,3.5-4.4,4.4-8.4,4.4 c-4.9,0-8.8-0.9-13.3-2.4V0H0z"
            />
          </g>
          <g transform="translate(1054.2748,449.231)">
            <path
              fill="#858789"
              d="M0,0c-11.3,0-14.6-7.7-14.6-23.3c0-22.1,5.3-25.3,13.7-25.3c9.3,0,14.6,3.5,14.6,23.3 C13.7-8.2,11.7,0,0,0 M-2.2-72.9c-30.5,0-48.3,15.7-48.3,46.7c0,33.6,17.1,50.5,52.9,50.5c32.1,0,47.4-16.2,47.4-46.1 C49.8-55.3,31.5-72.9-2.2-72.9"
            />
          </g>
          <g transform="translate(1176.6342,447.7721)">
            <path
              fill="#858789"
              d="M0,0c-3.1,0.4-5.3,0.5-8.8,0.5c-9.7,0-17.5-4.2-17.5-23c0-18.1,8-21.3,14.2-21.3 c3.3,0,7.8,0.4,12,1.8V0z M0.7-68.9L0-61.6c-6.4-6.6-16-9.1-25-9.1c-24.8,0-37.7,16.4-37.7,46.7c0,34.5,19.1,49.2,49.1,49.2 c4,0,10.4-0.4,13.7-0.7v38.3l34.8,4.4v-136H0.7z"
            />
          </g>
          <g transform="translate(1230.0658,378.8434)">
            <path fill="#858789" d="M0,0v90.3l34.8,4.4V0H0z" />
          </g>
          <g transform="translate(1247.5714,480.5943)">
            <path
              fill="#C63548"
              d="M0,0c-8.8,0-16.6,4.6-16.6,16.6c0,11.7,7.8,16.6,17.3,16.6c9.5,0,16.4-4.4,16.4-16.6 C17.1,5.5,10.2,0,0,0"
            />
          </g>
          <g transform="translate(1331.0873,416.4068)">
            <path
              fill="#858789"
              d="M0,0c-3.3-0.2-8-0.5-13.7-2c-3.3-0.9-5.5-3.3-5.5-7.3c0-5.5,3.5-7.8,8.2-7.8 c5.1,0,7.8,1.5,10.9,3.8V0z M2.4-37.6l-0.7,7.8c-7.7-8.9-17-9.7-23.5-9.7c-21.2,0-30.8,15.9-30.8,29.7c0,13.7,5.8,20.1,17,23.5 c9.8,3.1,21.7,3.8,35.4,4.4v3.3c0,2.7-0.4,4.9-1.6,6.6c-1.5,2-4.4,2.7-9.3,2.7c-7.5,0-17.9-1.8-29.7-4.9l-5.5,22.8 C-32.8,54-14,57.1-1.3,57.1c14.2,0,26.4-2.6,32.5-15c2.6-5.3,3.1-13.9,3.1-23.5v-56.2L2.4-37.6z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
})

export default MethodiaLogo
