import { createIcon } from '@chakra-ui/react'

export const ConsumedEnergyIcon = createIcon({
  displayName: 'ConsumedEnergyIcon',
  viewBox: '0 0 32 32', // Ensures the icon scales properly
  // The `path` prop can take an array of objects if your SVG has multiple paths
  path: (
    <g fill="none">
      <path
        d="M30.667 5.33331V13.3333H22.667"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33301 26.6667V18.6667H9.33301"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67967 12C5.3559 10.0891 6.50518 8.38056 8.0203 7.03391C9.53541 5.68726 11.367 4.74637 13.3441 4.29903C15.3212 3.85169 17.3793 3.91247 19.3266 4.47572C21.2738 5.03896 23.0467 6.0863 24.4797 7.52002L30.6663 13.3334M1.33301 18.6667L7.51967 24.48C8.95267 25.9137 10.7255 26.9611 12.6728 27.5243C14.62 28.0876 16.6782 28.1484 18.6553 27.701C20.6324 27.2537 22.4639 26.3128 23.9791 24.9661C25.4942 23.6195 26.6434 21.911 27.3197 20"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8886 10.6667L13.333 16H18.6663L15.1108 21.3334"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
})
