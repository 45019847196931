import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Flex, Text, List, Collapse } from '@chakra-ui/react'
import { ProgressBarWrapper } from '../../../app/components/ProgressBar'
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon'
import { calculatePercentage } from '../../../utils/helpers'
import ArrowUpIcon from '../../../assets/icons/ArrowUp'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon'

const DevicesContainer = ({ devices, totalConsumption, title, type, locationId }) => {
  const [showAll, setShowAll] = useState(false)

  const handleShowMore = () => {
    setShowAll(!showAll)
  }

  const alwaysVisibleItems = devices.slice(0, 5)
  const additionalItems = devices.slice(5)

  return (
    <Box py="16px" transition={{ height: '200ms' }}>
      <Text px="20px" fontSize="14px" fontWeight="700" color="text.secondary">
        {title}
      </Text>
      <List overflow="hidden">
        {alwaysVisibleItems.map((device) => (
          <Link
            to={`/deviceInfo?device=${device.id}&location=${locationId}`}
            key={device.id}
          >
            <Flex
              align="center"
              gap="2"
              py="5"
              _hover={{ bg: '#f7f9fd' }}
              px="20px"
              cursor="pointer"
            >
              <ProgressBarWrapper
                title={device.title}
                variant={type === 'generator' ? 'generator' : 'consumer'}
                kilowatts={
                  type === 'generator'
                    ? device.total_generated_power
                    : device.total_consumption
                }
                percentage={calculatePercentage(
                  type === 'generator'
                    ? device.total_generated_power.toString()
                    : device.total_consumption.toString(),
                  totalConsumption,
                )}
              />
              <ChevronRightIcon />
            </Flex>
          </Link>
        ))}

        <Collapse
          in={showAll}
          animateOpacity
          transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}
        >
          {additionalItems.map((device) => (
            <Link
              to={`/deviceInfo?device=${device.id}&location=${locationId}`}
              key={device.id}
            >
              <Flex
                align="center"
                gap="2"
                py="5"
                _hover={{ bg: '#f7f9fd' }}
                px="20px"
                cursor="pointer"
              >
                <ProgressBarWrapper
                  title={device.title}
                  variant={type === 'generator' ? 'generator' : 'consumer'}
                  kilowatts={
                    type === 'generator'
                      ? device.total_generated_power
                      : device.total_consumption
                  }
                  percentage={calculatePercentage(
                    type === 'generator'
                      ? device.total_generated_power.toString()
                      : device.total_consumption.toString(),
                    totalConsumption,
                  )}
                />
                <ChevronRightIcon />
              </Flex>
            </Link>
          ))}
        </Collapse>
      </List>

      {devices.length > 5 && (
        <Flex justifyContent="space-around">
          <Button onClick={handleShowMore} variant="ghost">
            {showAll
              ? `See only top ${type === 'generator' ? 'generators' : 'consumers'}`
              : `See all ${type === 'generator' ? 'generators' : 'consumers'}`}

            {showAll ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Button>
        </Flex>
      )}
    </Box>
  )
}

export default DevicesContainer
