import { EmailIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useForgotPasswordMutation } from '../api/authApi'
import key from '../../../assets/Login/key.svg'
import mail from '../../../assets/Login/email.svg'
import { Icon } from '../../../utils/Icon'
import { ForgotPasswordRequest, ForgotPasswordError } from '../types'

export const ForgotPassword: React.FC = () => {
  const [sendForgotPassword, { isLoading, isError, error, data, originalArgs }] =
    useForgotPasswordMutation()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ForgotPasswordRequest>()

  function onSubmit(value: ForgotPasswordRequest) {
    sendForgotPassword(value)
  }

  return (
    <>
      <Icon w={12} h={12} src={data ? mail : key} mx="auto" />
      <Text as="h2" fontSize={{ base: 'xl', sm: '2xl' }} fontWeight="bold" mt={6}>
        {data ? 'Check your email' : 'Forgot your password?'}
      </Text>
      <Container fontSize="sm" textAlign="center" w={{ base: '95%', sm: 'md' }} mt={4}>
        {isLoading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : data ? (
          <Text>
            Please follow the instructions on how to create a new password to an email
            address{' '}
            <Text as="span" fontWeight="bold">
              {data.email}
            </Text>
            .
          </Text>
        ) : isError ? (
          <Text>{(error as ForgotPasswordError).data?.email[0]}</Text>
        ) : (
          <Text>
            Please enter the email address you used to register your account and we'll
            send instructions for creating a new password to your email address.
          </Text>
        )}
      </Container>
      {isLoading ? (
        ''
      ) : data ? (
        <Box w="full" mt={11}>
          <Button
            w="full"
            variant="main"
            h={12}
            fontSize="md"
            onClick={() => window.open('', '_blank')}
          >
            Open Mail
          </Button>
          <Box fontSize="xs" color="text.light" textAlign="center" mt={2}>
            Didn't receive the email?{' '}
            <Button
              variant="unstyled"
              fontSize="xs"
              color="#157FEE"
              _focus={{ boxShadow: 'none' }}
              onClick={() => sendForgotPassword(originalArgs as ForgotPasswordRequest)}
            >
              Click to resent it
            </Button>
          </Box>
        </Box>
      ) : (
        <VStack as="form" onSubmit={handleSubmit(onSubmit)} w="full" spacing={4} mt={6}>
          <FormControl isInvalid={errors.email !== undefined} color="text.light">
            <InputGroup size="lg">
              <InputLeftElement
                pointerEvents="none"
                w={{ base: 10, sm: 14 }}
                children={<EmailIcon color="gray.300" />}
              />
              <Input
                placeholder="Enter your email address"
                id="email"
                pl={{ base: 10, sm: 14 }}
                {...register('email', {
                  required: '* Required field',
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: '* Invalid email address',
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <Button
            w="full"
            variant="main"
            h={12}
            isLoading={isLoading}
            type="submit"
            fontSize="md"
          >
            Reset Password
          </Button>
        </VStack>
      )}
    </>
  )
}
