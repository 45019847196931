import { createContext, PropsWithChildren, useContext, useState } from 'react'

const AlarmsCountContext = createContext<{
  alarmsCount: number
  setAlarmsCount: (alarmsCount: number) => void
}>({
  alarmsCount: 0,
  setAlarmsCount: () => null,
})

export const useAlarmsCountContext = () => {
  return useContext(AlarmsCountContext)
}

export const AlarmsCountContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [alarmsCount, setAlarmsCount] = useState<number>(0)
  return (
    <AlarmsCountContext.Provider value={{ alarmsCount, setAlarmsCount }}>
      {children}
    </AlarmsCountContext.Provider>
  )
}

export default AlarmsCountContext
