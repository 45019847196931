import { format } from 'date-fns'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { downloadFile } from '../../../utils/helpers'
// initialize an empty api service that we'll inject endpoints into later as needed

const nodeApi = createApi({
  reducerPath: 'nodeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_REACT_APP_API_URL || '/node-api',
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = window.localStorage.getItem('aiot_t')
      if (token) {
        headers.set('Authorization', `Token ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getReportAsPDF: builder.mutation<void, any>({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
        responseHandler: async (response) => {
          if (response.ok) {
            const href = window.URL.createObjectURL(await response.blob())
            const filename = `Report_${format(new Date(), 'yyyy-MM-dd')}.pdf`
            downloadFile(href, filename)
          }

          return null
        },
      }),
    }),
  }),
})
export default nodeApi
