import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Wrap,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import addDevice from '../../assets/locations/addDeviceIcon.svg'
import heatPump from '../../assets/locations/heatPump.svg'
import subLoationIcon from '../../assets/locations/subLoationIcon.svg'
import { Icon } from '../../utils/Icon'
import { SublocationCard } from './components/SublocationCard'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  useGetLocationConsumptionsQuery,
  useGetLocationDevicesQuery,
  useGetLocationQuery,
  useLazyDeleteDeviceQuery,
} from './api/locationsApi'
import { useParams, useNavigate } from 'react-router-dom'
import { Device } from '../analysis/api/types'
import Header from '../../app/components/Header'
import { Link } from 'react-router-dom'

const Devices = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [trigger] = useLazyDeleteDeviceQuery()

  const {
    data: location,
    isFetching: locationDataFetching,
    refetch: refetchLocation,
  } = useGetLocationQuery({
    id: parseInt(id || '0'),
  })

  const { data: consumptionData, isFetching: consumptionDataFetching } =
    useGetLocationConsumptionsQuery({
      locationId: parseInt(id || '0'),
    })

  const {
    data: devices,
    isFetching: devicesDataFetching,
    refetch: refetchDevices,
  } = useGetLocationDevicesQuery({
    id: parseInt(id || '0'),
  })

  const handleDelete = async (deviceId: number) => {
    await trigger({ id: deviceId })
      .unwrap()
      .then(() => {
        refetchDevices()
      })
  }

  return (
    <>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          <Stack
            direction="row"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBackIcon />
            <Text>Devices</Text>
          </Stack>
        </Heading>
      </Header>
      <Box py={10} px={6} mt={4} boxShadow="lg" rounded="lg" borderWidth={1}>
        <HStack
          spacing={44}
          w="full"
          flexDir={{ sm: 'column', md: 'row' }}
          alignItems={{ sm: 'flex-start', md: 'center' }}
          justifyContent="space-between"
        >
          <Flex direction="column" marginBottom={{ sm: 6, md: 0 }}>
            <Text fontSize="sm" fontWeight="bold" color="text.light">
              Location name
            </Text>
            <Heading as="h2" fontSize="3xl" fontWeight="bold">
              {location?.title}
            </Heading>
          </Flex>
          <HStack spacing={8} marginInlineStart={0}>
            <Flex direction="column">
              <Heading as="h4" fontSize="sm" color="text.light">
                Current Usage
              </Heading>
              <Heading as="h3" fontSize="2xl" fontWeight="bold" mt={3}>
                {consumptionData && consumptionData.current_usage
                  ? `${consumptionData.current_usage} ${consumptionData.price_unit}`
                  : `-`}
              </Heading>
            </Flex>
            <Flex direction="column">
              <Heading as="h4" fontSize="sm" color="text.light">
                Carbon intensity
              </Heading>
              <Heading as="h3" fontSize="2xl" fontWeight="bold" mt={3}>
                {consumptionData && consumptionData.carbon_intensity
                  ? `${consumptionData.carbon_intensity} g`
                  : `-`}
              </Heading>
            </Flex>
            {/* <Flex direction="column">
              <Heading as="h4" fontSize="sm" color="text.light">
                Total Consumption / BGN
              </Heading>
              <Heading as="h3" fontSize="2xl" fontWeight="bold" mt={3}>
                {consumptionData && consumptionData.total_consumption
                  ? `${consumptionData.total_consumption} ${consumptionData.price_unit} / ${consumptionData.price} BGN`
                  : `-`}
              </Heading>
            </Flex> */}
          </HStack>
        </HStack>
        <Divider orientation="horizontal" mt={6} />
        <Flex justifyContent="space-between" mt={6}>
          <Heading as="h3" fontSize="2xl" fontWeight="bold" mt={3}>
            Sublocations
          </Heading>
          <Button
            leftIcon={<Icon src={subLoationIcon} />}
            colorScheme="button.primary"
            variant="outline"
            w="xs"
            onClick={() => {
              navigate('/locations/add-location', {
                state: {
                  locationId: id,
                  locationName: location?.title,
                },
              })
            }}
            ml={5}
          >
            {' '}
            Add Location
          </Button>
        </Flex>
        <Wrap spacing={6} mt={6}>
          {location?.children?.map((item) => (
            <SublocationCard
              key={item.id}
              location={item}
              refetchLocation={refetchLocation}
            />
          ))}
        </Wrap>
        <Flex justifyContent="space-between" mt={6}>
          <Heading as="h3" fontSize="2xl" fontWeight="bold" mt={3}>
            Devices
          </Heading>
          <Button
            leftIcon={<Icon src={addDevice} />}
            colorScheme="button.primary"
            variant="outline"
            w="xs"
            ml={5}
            onClick={() => {
              navigate('/locations/add-device', {
                state: {
                  locationId: id,
                },
              })
            }}
          >
            Add Device
          </Button>
        </Flex>
        {devices && devices.length > 0 ? (
          <TableContainer mt={4}>
            <Table variant="unstyled">
              <Thead color="gray">
                <Tr>
                  <Th p={0}>Device name</Th>
                  <Th display={{ sm: 'none', md: 'table-cell' }}>Serial number</Th>
                  <Th display={{ sm: 'none', md: 'table-cell' }}>Sublocation</Th>
                </Tr>
              </Thead>
              <Tbody>
                {devices?.map(({ id, title, serial_number, location }: Device.Data) => (
                  <Tr key={id} borderBottom="1px" borderColor="gray.200">
                    <Td p={0}>
                      <HStack>
                        <Icon src={heatPump} />
                        <Box>{title}</Box>
                      </HStack>
                    </Td>
                    <Td display={{ sm: 'none', md: 'table-cell' }}>
                      {serial_number ? `${serial_number} (mm)` : ''}
                    </Td>
                    <Td display={{ sm: 'none', md: 'table-cell' }}>{location}</Td>
                    <Td>
                      <HStack
                        spacing={4}
                        fontSize="sm"
                        color="gray"
                        mt={2}
                        justifyContent="flex-end"
                      >
                        <Link to={`/locations/device/${id}/edit`}>
                          <Button variant="unstyled" rightIcon={<EditIcon />}>
                            Edit
                          </Button>
                        </Link>
                        <Button
                          variant="unstyled"
                          onClick={() => handleDelete(id)}
                          rightIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
    </>
  )
}

export default Devices
