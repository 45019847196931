import { Center, Spinner, Text, VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { useLazyValidateTokenQuery } from '../features/auth/api/authApi'

type Props = {
  redirectTo: string
  children: JSX.Element
}

export const TokenProtectedRoute: React.FC<Props> = ({ children, redirectTo }) => {
  const token = window.localStorage.getItem('aiot_t')
  const [searchParams] = useSearchParams()
  const tokenUrlParam = searchParams.get('token')

  const [validateToken, { isLoading, isSuccess }] = useLazyValidateTokenQuery()
  const location = useLocation()

  useEffect(() => {
    if (tokenUrlParam && !isLoading) {
      validateToken({ token: tokenUrlParam })
    }
  }, [])

  if (!token && !tokenUrlParam) {
    return <Navigate replace to={redirectTo} state={{ from: location }} />
  }

  if (isLoading) {
    return (
      <Center>
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Validating Token
          </Text>
          <Spinner size="xl" />
        </VStack>
      </Center>
    )
  }

  if (isSuccess || token) {
    return children
  }

  return null
}
