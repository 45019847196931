import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  VStack,
  Select,
  IconButton,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { Icon } from '../../utils/Icon'
import humidIcon from '../../assets/humid.svg'
import tempIcon from '../../assets/temp.svg'
import filter from '../../assets/notifications/filter.svg'
import settings from '../../assets/notifications/settings.svg'
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form'
import Header from '../../app/components/Header'
import { useGetDefectsInfoQuery } from './api/notificationsApi'
import {
  useGetLocationDevicesQuery,
  useGetLocationsInfoQuery,
} from '../locations/api/locationsApi'

const Notifications = () => {
  const { register, watch, setValue, getValues, reset } = useForm<{
    location: string
    device: string
    period: string
  }>()
  const location = watch('location')
  const device = watch('device')

  const { data, isFetching, refetch } = useGetDefectsInfoQuery({
    location,
    device,
  })

  const { data: locationsData } = useGetLocationsInfoQuery({})

  const [notifications, setNotifications] = useState<any>([])
  const [searchLocation, setSearchLocation] = useState<string>('')

  const { data: devicesData, refetch: refetchDevices } = useGetLocationDevicesQuery({
    id: parseInt(location) || 0,
  })

  useEffect(() => {
    if (data?.results) {
      setNotifications(data.results)
    }
  }, [data])

  useEffect(() => {
    if (location) {
      refetch()
      refetchDevices()
      setValue('device', location ? getValues()['device'] : '')
    }
  }, [location])

  const searchLocationHandler = (e: any) => {
    const term = e.target.value
    setSearchLocation(e.target.value)

    const filteredResults = data?.results?.filter(({ device }) => {
      return (
        device?.location?.title?.toLowerCase()?.includes(term.toLowerCase()) ||
        device?.location?.parent?.title?.toLowerCase()?.includes(term.toLowerCase())
      )
    })

    setNotifications(filteredResults)
  }

  const filterCriteriaCount = useMemo(
    () => Object.values(getValues()).filter((item) => item).length,
    [getValues()],
  )

  return (
    <>
      <Header></Header>
      <Box boxShadow="lg" rounded="lg" borderWidth={1} p={6} mt={5}>
        <HStack spacing={6} mb={8}>
          <InputGroup>
            <Input
              placeholder="Search location"
              value={searchLocation}
              onChange={searchLocationHandler}
            />
            <InputRightElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
          </InputGroup>

          <InputGroup w={48}>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon w={9} h={4} src={filter} />}
            />
            <Input
              value={`Filter ${
                filterCriteriaCount > 0 ? `(${filterCriteriaCount})` : ''
              }`}
              readOnly
            />
            <InputRightElement
              children={
                <Popover returnFocusOnClose={false} placement="bottom-start">
                  <PopoverTrigger>
                    <IconButton
                      variant="unstyled"
                      aria-label="Open filter menu"
                      icon={<ChevronDownIcon w={9} h={5} color="gray.300" />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverBody px={4}>
                      <VStack alignItems="flex-start" as="form">
                        <Text ml={1} fontWeight="bold">
                          Location
                        </Text>
                        <Select {...register('location')}>
                          <option value="">All locations</option>
                          {locationsData?.results?.map((loc) => {
                            return (
                              <option key={loc.id} value={loc.id}>
                                {loc.title}
                              </option>
                            )
                          })}
                        </Select>
                        <Text ml={1} fontWeight="bold">
                          Device
                        </Text>
                        <Select {...register('device')} disabled={!location}>
                          <option value="">All Devices</option>
                          {devicesData?.map((d) => {
                            return (
                              <option key={d.id} value={d.id}>
                                {d.title}
                              </option>
                            )
                          })}
                        </Select>
                        {/* <Text ml={1} fontWeight="bold">
                          Period
                        </Text>
                        <Select {...register("period")}>
                          <option value="">All Time</option>
                          <option value="1">Period 1</option>
                          <option value="2">Period 2</option>
                          <option value="3">Period 3</option>
                        </Select> */}
                      </VStack>
                      <Button
                        mt={4}
                        size="sm"
                        colorScheme="button.primary"
                        onClick={() => {
                          setValue('location', '')
                          setValue('device', '')
                          setNotifications(data?.results || [])
                        }}
                      >
                        Clear Filters
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              }
            />
          </InputGroup>
          {/* <Icon w={9} h={5} src={settings} /> */}
        </HStack>
        {notifications.map(({ device }: any, idx: number) => (
          <Flex
            justifyContent="space-between"
            w="full"
            borderBottom="1px"
            borderColor="gray.200"
            key={idx}
            alignItems={{ sm: 'flex-start', md: 'center' }}
            flexDir={{ base: 'row', sm: 'column', md: 'row' }}
            pb={4}
          >
            <Flex alignItems="center">
              <Icon w={9} h={8} src={humidIcon} />
              <Flex flexDirection="column" ml={4}>
                <Text mt={1} fontWeight="bold">
                  {device.title}
                </Text>
                <Flex fontSize="xs" mt={1} color="text.intermediate">
                  {/* <Text>2 mins ago &bull;</Text> */}
                  <Text ml={1}>
                    {device?.location?.title}
                    {device?.location?.parent?.title
                      ? `, ${device?.location?.parent?.title}`
                      : ''}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            {/* <Button colorScheme="button.primary" size="sm" mt={{sm: 6, md: 0}}>
              Details
            </Button> */}
          </Flex>
        ))}
      </Box>
    </>
  )
}

export default Notifications
