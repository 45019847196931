import { pythonApi } from '../../../app/services/api/api'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    toggleDeviceById: builder.mutation<any, { id: number; action: string }>({
      query: ({ id, action }) => ({
        url: `v2/toggle-relay-device/${id}/`,
        method: 'POST',
        body: { action },
      }),
    }),
    getAllDevices: builder.query<any, void>({
      query: () => ({
        url: `/devices/`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetAllDevicesQuery, useToggleDeviceByIdMutation } = extendedApi
