import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Heading,
  HStack,
  VStack,
  Box,
  Skeleton,
  Stack,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  useBreakpointValue,
  Td,
} from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetDeviceDataQuery } from '../../../app/services/api/api'
import {
  useGetDeviceByIdQuery,
  useGetDeviceReportDataQuery,
  useGetSensiboDeviceDataQuery,
} from '../api/dasboardApi'

import { PeriodTabStrip } from '../components/PeriodTabStrip'
import { DEFAULTPERIOD, DEVICEPARAMS } from '../consts'
import { useQueryParams } from '../hooks/useQueryParams'
import Header from '../../../app/components/Header'

import 'simplebar/dist/simplebar.min.css'
import React, { useState } from 'react'
import { CLIMATE_CONTROL, GENERATORS_HEADERS } from '../../../utils/consts'
import DeviceRow from '../widgets/DeviceRow'
import EnergyDeviceTitle from '../components/EnergyDeviceTitle'
import EnergyDeviceData from '../components/EnergyDeviceData'
import ClimateDeviceTitle from '../components/ClimateDeviceTitle'
import ClimateDeviceData from '../components/ClimateDeviceData'
import useRedirectOn404 from '../../../app/hooks/useRedirectTo404'

const DeviceDetails = () => {
  const [hoveredRow, setHoveredRow] = useState(null)

  const handleMouseEnter = (rowIndex) => {
    setHoveredRow(rowIndex)
  }

  const handleMouseLeave = () => {
    setHoveredRow(null)
  }

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [queryParams] = useQueryParams(DEVICEPARAMS)
  const deviceId = searchParams.get('device') || '0'

  const { data: currentDevice, error: getCurrentDeviceError } = useGetDeviceByIdQuery({
    id: deviceId,
  })
  const { data: deviceData, isFetching: isDeviceDataFetching } = useGetDeviceDataQuery({
    ...DEFAULTPERIOD,
    ...queryParams,
  })

  const { data: deviceReportData, isFetching: isDeviceReportDataFetching } =
    useGetDeviceReportDataQuery({
      ...DEFAULTPERIOD,
      ...queryParams,
    })

  const deviceReportResults = deviceReportData?.results || []

  const extras = deviceReportData?.extras

  useRedirectOn404(getCurrentDeviceError, 'Device not found')

  const deviceCategory = currentDevice?.category

  const isClimateControl = currentDevice?.category === CLIMATE_CONTROL

  const hiddenOnSmallDevice = useBreakpointValue({ base: true, sm: false })

  const children = deviceData?.children

  const { data: climateData, isFetching: isClimateDataFetching } =
    useGetSensiboDeviceDataQuery({ id: deviceId }, { skip: !isClimateControl })

  return (
    <Stack spacing={5}>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          <Stack direction="row">
            <ArrowBackIcon
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(-1)
              }}
            />
            <Text>{currentDevice?.title}</Text>
          </Stack>
        </Heading>
      </Header>
      <Box w="full" p={6}>
        <VStack
          alignItems="flex-start"
          spacing={7}
          background="white"
          pt={4}
          pb={4}
          rounded="16px"
        >
          <Box w="full" px={6}>
            {isClimateControl ? null : <PeriodTabStrip />}
            <HStack spacing={0} w="full" mt={7}>
              <HStack spacing={8}>
                {isClimateControl ? (
                  <ClimateDeviceTitle
                    isDeviceDataFetching={isClimateDataFetching}
                    deviceData={climateData}
                  />
                ) : (
                  <EnergyDeviceTitle
                    isDeviceDataFetching={isDeviceDataFetching}
                    deviceData={{
                      ...deviceData,
                      // total_consumption: extras?.total_consumption,
                      // total_generated_power: extras?.total_generated_power,
                      category: currentDevice?.category,
                      id: currentDevice?.id,
                    }}
                  />
                )}
              </HStack>
            </HStack>
          </Box>
        </VStack>
        <Box mt={4}>
          {isClimateControl ? (
            <ClimateDeviceData deviceId={deviceId} />
          ) : (
            <EnergyDeviceData
              deviceCategory={deviceCategory}
              deviceReportData={deviceReportResults}
              isDeviceReportDataFetching={isDeviceReportDataFetching}
            />
          )}
        </Box>

        <Table size={hiddenOnSmallDevice ? 'sm' : 'lg'}>
          {children?.length > 0 ? (
            <>
              <Thead>
                <Tr>
                  {GENERATORS_HEADERS.map((header, idx) => (
                    <Th
                      fontSize={{ base: 'xs', md: 'sm' }}
                      fontWeight="bold"
                      color="text.intermediate"
                      textTransform="initial"
                      key={idx}
                      hidden={
                        hiddenOnSmallDevice && (idx === 1 || idx === 2 || idx === 3)
                      }
                    >
                      {idx !== 2 ? header : ''}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {isDeviceDataFetching
                  ? Array.from(Array(5)).map((_, idx) => (
                      <Tr key={idx}>
                        <Td>
                          <Skeleton w="full" h="32px" />
                        </Td>
                        <Td hidden={hiddenOnSmallDevice}>
                          <Skeleton w="full" h="32px" />
                        </Td>
                        <Td hidden={hiddenOnSmallDevice}>
                          <Skeleton w="full" h="32px" />
                        </Td>
                      </Tr>
                    ))
                  : children?.map((device, idx) => (
                      <DeviceRow
                        key={device.id}
                        device={device}
                        hiddenOnSmallDevice={hiddenOnSmallDevice}
                        isFetching={isDeviceDataFetching}
                        queryParams={queryParams}
                        bgColor="#b4dcc4"
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        hoveredRow={hoveredRow}
                      />
                    ))}
              </Tbody>
            </>
          ) : null}
        </Table>
      </Box>
    </Stack>
  )
}

export default DeviceDetails
