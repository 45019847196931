import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { TileWithIconContainerProps, TileWithIconProps } from './types'
import { TrendUpIcon } from '../../../assets/icons/TrendUp'
import { TrendDownIcon } from '../../../assets/icons/TrendDown'

// TileWithImage.tsx

export const TileWithIcon: React.FC<TileWithIconProps> = ({
  name,
  value,
  percentage,
  Icon,
  trend,
}) => {
  const TrendIcon = trend === 'up' ? TrendUpIcon : TrendDownIcon
  return (
    <Flex p="16px" alignContent="center" alignItems="center">
      <Icon w={12} h={12} />
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="8px"
        ml={3}
      >
        <Text color="text.secondary" fontSize="14px" fontWeight="400" lineHeight="18px">
          {name}
        </Text>
        <Flex justifyContent="flex-start" alignItems="center" gap="8px">
          <Text color="#484848" fontSize="20px" fontWeight="700" lineHeight="26px">
            {value}
          </Text>
          <Text color="#484848" fontSize="16px" fontWeight="400" lineHeight="22px">
            kWh
          </Text>
        </Flex>
        {percentage ? (
          <Flex width="198px" justifyContent="flex-start" alignItems="center" gap="2px">
            <TrendIcon color={trend === 'up' ? '#21D17F' : '#EB6F21'} />
            <Text
              flex="1"
              color={trend === 'up' ? '#21D17F' : '#EB6F21'}
              fontSize="14px"
              fontWeight="700"
              lineHeight="18px"
            >
              {percentage}%
            </Text>
          </Flex>
        ) : null}
      </Box>
      {/* Placeholder for right-side icon, adjust as needed */}
      <Box
        width="32px"
        height="32px"
        bg="#F0F0F0"
        borderRadius="8px"
        opacity="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {/* Inner icon content here, if dynamic content is needed, pass it through props */}
      </Box>
    </Flex>
  )
}

export const TileWithIconContainer: React.FC<TileWithIconContainerProps> = ({
  children,
}) => {
  return (
    <Flex
      width="100%"
      direction="column"
      bg="white"
      justifyContent="flex-start"
      borderTopRadius="16px"
      borderBottomRadius="16px"
      gap="16px"
    >
      {children}
    </Flex>
  )
}
