import { addDays } from 'date-fns'
import { addHours, differenceInDays } from 'date-fns'
import { Analysis, Compare } from '../features/analysis/api/types'

import { format, subDays, startOfDay } from 'date-fns'
import { getUserMockData } from './mockUsers'

// Function to generate consumption data for a given date
function generateConsumptionData(objKey = 'consumption') {
  const data = []
  for (let hour = 0; hour < 24; hour++) {
    // Generating random consumption values for demonstration
    let consumption = Math.random() * 10 + 8 // Random value between 8 and 18
    if (hour > 12 && hour < 18) {
      consumption = Math.random() * 15 + 12
    }
    data.push({ [objKey]: consumption, hour })
  }
  return data
}

// Function to generate consumption data for the last 31 days
function generateFromToConsumptionData(from: string, to: string, objKey) {
  const currentDate = new Date(to)
  const previousDate = new Date(from)

  const daysDifference = differenceInDays(currentDate, previousDate) + 1

  const dateRangeDaysData = {}

  for (let i = 0; i < daysDifference; i++) {
    const date = subDays(currentDate, i)
    const formattedDate = format(date, 'yyyy-MM-dd')
    // @ts-ignore
    dateRangeDaysData[formattedDate] = generateConsumptionData(objKey)
  }

  return dateRangeDaysData
}

// Function to generate consumption data for a given date and hour
const generateHourlyConsumptionData = (date: Date) => {
  const data = []
  for (let hour = 0; hour < 24; hour++) {
    const consumption = Math.random() * 80 + 8

    data.push({
      time: format(addHours(date, hour), 'yyyy-MM-dd HH:00:00'),
      consumption,
      current: Math.random() * 20 + 10,
      carbon_intensity: Math.random() * 40 + 10,
      fossil_fuel: Math.random() * 10 + 10,
    })
  }
  return data
}

const generateWeeklyConsumptionData = (date: Date, data: any) => {
  for (let hour = 0; hour < 2; hour++) {
    const consumption = Math.random() * 80 + 8
    data.push({
      time: format(addHours(date, hour), 'yyyy-MM-dd HH:00:00'),
      consumption,
      current: Math.random() * 20 + 10,
      carbon_intensity: Math.random() * 40 + 10,
      fossil_fuel: Math.random() * 10 + 10,
    })
  }
  return data
}

const generateMonthlyConsumptionData = (date: Date, data: any) => {
  const consumption = Math.random() * 80 + 8
  data.push({
    time: format(date, 'yyyy-MM-dd HH:00:00'),
    consumption,
    current: Math.random() * 20 + 10,
    carbon_intensity: Math.random() * 40 + 10,
    fossil_fuel: Math.random() * 10 + 10,
  })
  return data
}

// Function to generate consumption data for the last 31 days
const generateCompareDeviceConsumptionData = (from?: string, to?: string) => {
  const currentDate = to ? new Date(to) : new Date()
  const fromDate = from ? new Date(from) : new Date()

  const daysDifference = differenceInDays(currentDate, fromDate) + 1
  //@ts-ignore
  let last31DaysData = []

  if (daysDifference === 1) {
    for (let i = 0; i < daysDifference; i++) {
      const date = addDays(fromDate, i)

      //@ts-ignore
      last31DaysData = generateHourlyConsumptionData(startOfDay(date))
    }
  }

  if (daysDifference > 1 && daysDifference <= 8) {
    for (let i = 0; i < daysDifference; i++) {
      const date = addDays(fromDate, i)
      //@ts-ignore
      generateWeeklyConsumptionData(startOfDay(date), last31DaysData)
    }
  } //@ts-ignore

  if (daysDifference > 8) {
    for (let i = 0; i < daysDifference; i++) {
      const date = addDays(fromDate, i)
      //@ts-ignore
      generateMonthlyConsumptionData(startOfDay(date), last31DaysData)
    }
  } //@ts-ignore
  return last31DaysData
}

const devicesMockData = [
  {
    user: 'vboyadjiev@aiotcloud.app',
    response: [
      {
        id: 90,
        count: 6,
        next: null,
        previous: null,
        results: [
          {
            title: 'Total consumption',
            id: 305,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: true,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Water heater',
            id: 307,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Lights',
            id: 308,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Appliance 1',
            id: 309,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Appliance 2',
            id: 310,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Appliance 3',
            id: 311,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
        ],
      },
      {
        id: 91,
        count: 6,
        next: null,
        previous: null,
        results: [
          {
            title: 'Total consumption',
            id: 306,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: true,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Air conditioning',
            id: 312,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Lights Room 1',
            id: 313,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Lights Room 2',
            id: 314,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Industrial Unit 1',
            id: 315,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
          {
            title: 'Industrial unit 2',
            id: 316,
            description: '',
            parent_id: null,
            children_count: 0,
            is_main: false,
            location_id: 90,
            on_dashboard: false,
            serial_number: '',
            is_virtual: false,
            physical_devices: [],
            device_type: {
              name: '',
              title: '',
              image: null,
            },
            device_type_relations: [],
          },
        ],
      },
    ],
  },
]

const analysisConsumptionBreakdownMockData = [
  {
    user: 'vboyadjiev@aiotcloud.app',
    response: [
      {
        id: 90,
        data: {
          'Water heater': 54.86,
          'Appliance 2': 25.91,
          Lights: 23.04,
          'Appliance 1': 18.94,
          'Appliance 3': 8.61,
        },
      },
      {
        id: 91,
        data: {
          'Industrial Unit 2': 87.61,
          'Air conditioning': 54.81,
          'Industrial Unit 1': 18.94,
          'Lights Room 2': 5.08,
          'Lights Room 1': 3.91,
        },
      },
    ],
  },
]

export const getAnalysisConsumptionBreakdownMockData = (location: string | number) => {
  const username = window.localStorage.getItem('username')

  if (!username) return

  const mockObject = getUserMockData(username, analysisConsumptionBreakdownMockData)

  if (mockObject) {
    console.log('User data mocked for query "getAnalysisConsumptionBreakdown"')
    return mockObject.response.find((item: any) => item.id === Number(location)).data
  }
}
export const getDevicesMockData = (location: string | number) => {
  const username = window.localStorage.getItem('username')
  if (!username) return

  const mockObject = getUserMockData(username, devicesMockData)

  if (mockObject) {
    console.log('User data mocked for query "getDevices"')
    return mockObject.response.find((item: any) => item.id === Number(location))
  }
}

export const getHeatMapMock = (from: string, to: string) => {
  const username = window.localStorage.getItem('username')

  if (username) {
    const consumptionHeatMapResults = generateFromToConsumptionData(
      from,
      to,
      'consumption',
    )

    const generatedPowerHeatMapResults = generateFromToConsumptionData(
      from,
      to,
      'generated_power',
    )

    const totalConsumption = Object.values(consumptionHeatMapResults)
      .flatMap((hourlyData: any) => hourlyData.map((hour: any) => hour.consumption))
      .reduce((acc, consumption) => acc + consumption, 0)
    const mockObject = [
      {
        user: 'vboyadjiev@aiotcloud.app',
        response: {
          price: 0,
          price_unit: 'kW',
          total_consumption: totalConsumption,

          results: {
            consumption: consumptionHeatMapResults,
            generated_power: generatedPowerHeatMapResults,
          },
        } as Analysis.QueryResponse,
      },
    ]
    return mockObject.find((obj) => obj.user === username)?.response
  }
}

export const getLocationsMock = () => {
  const mockData = [
    {
      user: 'vboyadjiev@aiotcloud.app',
      response: {
        count: 1,
        next: null,
        previous: null,
        results: [
          {
            id: 90,
            title: 'Office',
            description: '',
            image: null,
            price: null,
            price_unit: 'kW',
            devices_count: 9,
            has_main_device: true,
            children: [],
            parent_id: null,
            emission_zone: null,
            sublocations_count: 0,
          },
          {
            id: 91,
            title: 'Factory',
            description: '',
            image: null,
            price: null,
            price_unit: 'kW',
            devices_count: 9,
            has_main_device: true,
            children: [],
            parent_id: null,
            emission_zone: null,
            sublocations_count: 0,
          },
        ],
      },
    },
  ]
  const username = window.localStorage.getItem('username')

  if (username) {
    console.log('User data mocked for query "getAnalysisLocations"')
    return mockData.find((obj) => obj.user === username)?.response
  }
}

const devicesMock = [
  {
    id: 305,
    title: 'Total consumption',
    category: 'relay',
    location: {
      id: 99,
      title: 'Office',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 307,
    title: 'Water heater',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Office',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 308,
    title: 'Lights',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Office',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 309,
    title: 'Appliance 1',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Office',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 310,
    title: 'Appliance 2',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Office',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 311,
    title: 'Appliance 3',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Office',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 306,
    title: 'Total consumption',
    category: 'relay',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 312,
    title: 'Air conditioning',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 313,
    title: 'Lights Room 1',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 314,
    title: 'Lights Room 2',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 315,
    title: 'Industrial Unit 1',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 316,
    title: 'Industrial unit 2',
    category: 'emeter',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 317,
    title: 'Solar panel 1',
    category: 'energy_generator',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
  {
    id: 318,
    title: 'Solar panel 2',
    category: 'energy_generator',
    location: {
      id: 99,
      title: 'Factory',
      price: null,
      price_unit: 'kW',
    },
  },
]

export const compareDevicesMockData = (dateRanges: any[], devices: any[]) => {
  const firstDeviceDateRange = dateRanges[0]
  const secondDeviceDateRange = dateRanges.length === 2 ? dateRanges[1] : dateRanges[0]

  const firstDeviceId = devices[0]
  const secondDeviceId = devices[1]
  const username = window.localStorage.getItem('username')

  const firstDevice = devicesMock.find((device) => device.id === Number(firstDeviceId))

  const firstDeviceData = generateCompareDeviceConsumptionData(
    firstDeviceDateRange!.from_date,
    firstDeviceDateRange!.to_date,
  )

  const firstDeviceTotalConsumption = firstDeviceData.reduce(
    (acc, { consumption }) => acc + consumption,
    0,
  )

  const secondDevice = devicesMock.find((device) => device.id === Number(secondDeviceId))

  const secondDeviceData = generateCompareDeviceConsumptionData(
    secondDeviceDateRange!.from_date,
    secondDeviceDateRange!.to_date,
  )

  const secondDeviceTotalConsumption = secondDeviceData.reduce(
    (acc, { consumption }) => acc + consumption,
    0,
  )

  const mockData = [
    {
      user: 'vboyadjiev@aiotcloud.app',
      response: {
        results: [
          {
            ...firstDevice,
            description: '',
            parent_id: null,
            current_usage: 12.44,
            is_main: false,
            children_count: 0,
            device_type: null,
            carbon_intensity: 12,
            on_dashboard: true,
            total_consumption: firstDeviceTotalConsumption,
            datas: firstDeviceData,
          },
          {
            ...secondDevice,
            current_usage: 12.44,
            is_main: false,
            children_count: 0,
            description: '',
            parent_id: null,
            device_type: null,
            on_dashboard: true,
            carbon_intensity: 12,
            total_consumption: secondDeviceTotalConsumption,
            datas: secondDeviceData,
          },
        ],
      } as Compare.Response,
    },
  ]

  if (username) {
    console.log('User data mocked for query "getCompareDevices"')
    return mockData.find((obj) => obj.user === username)?.response
  }
}
