import { Box, Flex, SkeletonCircle, Text } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { Odometer } from '../Odometer'
import DoughnutChart from './Doughnut'

interface DoughnutProps {
  doughnutData: {
    name: string
    value: number
  }[]
  activeName?: string
  overviewFetching: boolean
  setActiveName: Dispatch<SetStateAction<string>>
}
export const Doughnut: React.FC<DoughnutProps> = ({
  doughnutData,
  activeName,
  overviewFetching,
  setActiveName,
}) => {
  const odometerValue = useMemo(() => {
    if (doughnutData) {
      if (!activeName) {
        return doughnutData.reduce((acc: number, data: any) => (acc += data.value), 0)
      }
      return doughnutData?.find((data) => data.name === activeName)?.value
    }
  }, [doughnutData, activeName])

  return (
    <>
      {overviewFetching ? (
        <Box alignItems="center" textAlign="center" padding="8">
          <Box position="relative" display="inline-block">
            <SkeletonCircle width="350px" height="350px" />
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              backgroundColor="white"
              borderRadius="50%"
              width="70%"
              height="70%"
            ></Box>
          </Box>
        </Box>
      ) : (
        <Box position="relative" w="410px" h="420px">
          <Box>
            <DoughnutChart
              data={doughnutData}
              activeName={activeName}
              setActiveName={setActiveName}
              isLoading={overviewFetching}
            />
          </Box>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={10}
          >
            <Flex direction="column" alignItems="center">
              <Text>Total consumption in kWh</Text>
              {odometerValue ? <Odometer value={odometerValue} /> : null}
            </Flex>
          </Box>
        </Box>
      )}
    </>
  )
}
