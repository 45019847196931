// DeviceDetailsButton.tsx
import React from 'react'
import { Button } from '@chakra-ui/react'
import { createSearchParams, useNavigate } from 'react-router-dom'

interface DeviceDetailsButtonProps {
  id: number
  queryParams: Record<string, any>
  isFetching: boolean
}

const DeviceDetailsButton: React.FC<DeviceDetailsButtonProps> = ({
  id,
  queryParams,
  isFetching,
}) => {
  const navigate = useNavigate()
  return (
    <Button
      size="sm"
      disabled={isFetching}
      colorScheme="button.primary"
      onClick={() =>
        navigate({
          pathname: '/deviceInfo',
          search: `?${createSearchParams({
            ...queryParams,
            device: id.toString(),
          })}`,
        })
      }
    >
      Details
    </Button>
  )
}

export default DeviceDetailsButton
