import { createIcon } from '@chakra-ui/icon'

export const Logo = createIcon({
  displayName: 'Logo',
  viewBox: '0 0 260.456 33.98',
  path: (
    <g id="AIoT_Cloud" data-name="AIoT Cloud" transform="translate(-687 -352)">
      <path
        id="Path_41"
        data-name="Path 41"
        d="M564.447,131.172h-3.813a6.276,6.276,0,0,0-5.4,3.074l-18.333,30.9h6.306v.009h3.513a5.978,5.978,0,0,0,5.2-3.035l1.72-3.043h-5.77l10.775-18.155,1.952,12.09a5.974,5.974,0,0,0-5.072,2.911l-1.888,3.162h7.937l.339,2.1a4.716,4.716,0,0,0,4.655,3.964h3.359Z"
        transform="translate(189.888 220.828)"
      />
      <g id="Group_9" data-name="Group 9" transform="translate(774.835 358.538)">
        <path
          id="Path_42"
          data-name="Path 42"
          d="M622.391,166.2a12.549,12.549,0,1,1,12.549-12.549A12.549,12.549,0,0,1,622.391,166.2Zm0-17.85a5.3,5.3,0,1,0,5.3,5.3,5.3,5.3,0,0,0-5.3-5.3Z"
          transform="translate(-609.843 -141.098)"
        />
      </g>
      <path
        id="Path_43"
        data-name="Path 43"
        d="M593.187,131.179h2.8a4.842,4.842,0,0,1,4.842,4.842v29.128h-2.8a4.842,4.842,0,0,1-4.842-4.841Z"
        transform="translate(170.677 220.826)"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M666.59,136.021V137.7H656.345v27.45H653.54a4.841,4.841,0,0,1-4.841-4.841V137.7h-5.4a4.842,4.842,0,0,1-4.842-4.842v-1.678h23.3A4.842,4.842,0,0,1,666.59,136.021Z"
        transform="translate(155.227 220.826)"
      />
      <g id="Group_10" data-name="Group 10" transform="translate(687 352.001)">
        <path
          id="Path_45"
          data-name="Path 45"
          d="M504.622,135.991c-.136-.04-.274-.076-.413-.107a10.718,10.718,0,0,0-1.875-.275,14,14,0,0,0-1.91.029,19.089,19.089,0,0,0-3.779.7,27.194,27.194,0,0,0-3.629,1.334,29.063,29.063,0,0,0-6.547,4.033,20.509,20.509,0,0,0-2.681,2.678c-.408.485-.79.988-1.157,1.5s-.7,1.047-1.014,1.589a16.645,16.645,0,0,0-1.494,3.386,9.778,9.778,0,0,0-.476,3.463,4.3,4.3,0,0,0,1.083,2.766l.121.126.116.092a1.712,1.712,0,0,0,.245.171c.082.057.186.092.275.144.1.036.2.078.31.112a4.748,4.748,0,0,0,1.485.166,11.134,11.134,0,0,0,3.386-.732A21.265,21.265,0,0,0,490,155.6c.2-.114.391-.234.586-.354a4.541,4.541,0,0,1-.906-.7c-2.163-2.164-1.75-6.087.919-8.777l-.018-.018a30.178,30.178,0,0,1,6.891-4.9,24.611,24.611,0,0,1,3.947-1.636,14.05,14.05,0,0,1,4.413-.7,8.111,8.111,0,0,1,1.705.216c-.308-.364-.629-.721-.972-1.064A17.039,17.039,0,0,0,504.622,135.991Z"
          transform="translate(-477.568 -132.681)"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M489.388,166.744a19.033,19.033,0,0,0,3.806-.665,27.449,27.449,0,0,0,3.663-1.294,28.958,28.958,0,0,0,6.632-3.965,19.6,19.6,0,0,0,2.714-2.662,22.084,22.084,0,0,0,2.2-3.1c.315-.545.605-1.1.864-1.666a16.019,16.019,0,0,0,.659-1.728,9.957,9.957,0,0,0,.513-3.483,4.395,4.395,0,0,0-1.054-2.8,1.94,1.94,0,0,0-.241-.227l-.113-.095-.132-.08a2.775,2.775,0,0,0-.578-.263,4.673,4.673,0,0,0-1.484-.178,11.143,11.143,0,0,0-3.4.725,21.462,21.462,0,0,0-3.344,1.576c-.179.1-.356.214-.533.322a4.531,4.531,0,0,1,.887.691c2.17,2.174,1.749,6.111-.943,8.8l-.039.038.018.018a30.053,30.053,0,0,1-6.86,4.883,24.547,24.547,0,0,1-3.927,1.631,14.09,14.09,0,0,1-4.387.709,8.218,8.218,0,0,1-1.661-.2c.29.34.593.674.914,1a17.161,17.161,0,0,0,1.956,1.682l.073.021A11.639,11.639,0,0,0,489.388,166.744Z"
          transform="translate(-478.594 -135.735)"
          fill="#157fee"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M476.752,148.587a18.989,18.989,0,0,1,1.881-3.782q.567-.879,1.2-1.7c.429-.545.872-1.076,1.34-1.581a22.515,22.515,0,0,1,3.064-2.786,30.7,30.7,0,0,1,7.125-3.948,28.557,28.557,0,0,1,3.88-1.223,20.3,20.3,0,0,1,4.046-.559,15,15,0,0,1,1.976.059,16.984,16.984,0,0,0-24.736,16.2C476.6,149.034,476.673,148.809,476.752,148.587Z"
          transform="translate(-476.495 -131.174)"
          fill="#157fee"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M514.863,156.078a18.318,18.318,0,0,1-.856,1.958c-.323.629-.678,1.237-1.054,1.827a24.316,24.316,0,0,1-2.569,3.286,21.567,21.567,0,0,1-3.106,2.774,30.608,30.608,0,0,1-7.209,3.871,28.785,28.785,0,0,1-3.911,1.18,20.211,20.211,0,0,1-4.069.515,15.8,15.8,0,0,1-1.683-.057,16.983,16.983,0,0,0,24.767-16.265C515.073,155.475,514.971,155.781,514.863,156.078Z"
          transform="translate(-481.243 -139.363)"
        />
      </g>
      <g id="Group_11" data-name="Group 11" transform="translate(825.836 358.549)">
        <path
          id="Path_49"
          data-name="Path 49"
          d="M687.273,153.465a12.2,12.2,0,0,1,12.521-12.351,11.489,11.489,0,0,1,9.977,5.022l-4.819,3.733a6.386,6.386,0,0,0-5.225-2.749c-3.326,0-5.665,2.817-5.665,6.345,0,3.563,2.342,6.346,5.665,6.346,2.476,0,3.936-1.154,5.36-2.884l4.955,3.529a12.415,12.415,0,0,1-22.769-6.99Z"
          transform="translate(-687.27 -141.114)"
          fill="#157fee"
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M726.119,141.835H732.7v17.982h11.5v5.773H726.119Z"
          transform="translate(-700.53 -141.36)"
          fill="#157fee"
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M754.559,153.465a12.442,12.442,0,0,1,12.825-12.351c7.329,0,12.758,5.463,12.758,12.351,0,6.82-5.5,12.351-12.825,12.351S754.559,160.354,754.559,153.465Zm18.865,0a6.173,6.173,0,0,0-6.107-6.413c-3.6,0-6.006,2.918-6.006,6.413a6.167,6.167,0,0,0,6.073,6.413c3.631,0,6.04-2.918,6.04-6.413Z"
          transform="translate(-710.237 -141.114)"
          fill="#157fee"
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M798.142,155.17V141.834h6.684v13.2c0,3.425,1.731,5.055,4.378,5.055s4.377-1.561,4.377-4.886V141.832h6.685V155c0,7.668-4.378,11.028-11.132,11.028S798.142,162.6,798.142,155.17Z"
          transform="translate(-725.112 -141.359)"
          fill="#157fee"
        />
        <path
          id="Path_53"
          data-name="Path 53"
          d="M837.654,141.835h9.162c8.483,0,13.4,4.886,13.4,11.808,0,6.854-4.988,11.944-13.539,11.944h-9.024Zm9.264,17.916c3.936,0,6.549-2.174,6.549-6.074,0-3.8-2.613-6.074-6.549-6.074h-2.681v12.148Z"
          transform="translate(-738.598 -141.36)"
          fill="#157fee"
        />
      </g>
    </g>
  ),
})
