import { Flex } from '@chakra-ui/react'
import React from 'react'

const Badge: React.FC<{ bg: string; boxSize?: string }> = ({
  bg,
  boxSize = '25px',
  children,
}) => {
  return (
    <Flex
      bg={bg}
      rounded="40px"
      boxSize={boxSize}
      align="center"
      justifyContent="space-around"
    >
      {children}
    </Flex>
  )
}

export default Badge
