import dayjs from 'dayjs'

export const groupByKey = <T extends { [key: string]: any }>(array: T[], key: string) => {
  return array.reduce<{ [key: string]: T[] }>((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    })
  }, {})
}

export const calculateCO2Emissions = (kWh: number, carbonIntensity: number) => {
  const CO2Emissions = (kWh * carbonIntensity).toFixed(2)
  return parseFloat(CO2Emissions)
}

export const numberWithCommas = (x: number) => {
  if (x !== null) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const formatDateForCompare = (date: string) => dayjs(date).format('MMMM D, YYYY')

export const DOUGHNUT_COLORS = [
  '#0080ea',
  '#00abf8',
  '#4bc1f9',
  '#9dd8fb',
  '#c2e3fc',
  '#d9eefc',
]

export const photovoltaicsColorsArray = [
  { color: '#21D17F', height: '10%' },
  { color: '#7AE3B2', height: '10%' },
  { color: '#88F9DE', height: '10%' },
  { color: '#C7FFF1', height: '10%' },
  { color: '#CBF4FF', height: '10%' },
  { color: '#A5DDFE', height: '10%' },
  { color: '#87D2FF', height: '10%' },
  { color: '#61C4FD', height: '10%' },
  { color: '#51A3D2', height: '10%' },
  { color: '#fff', height: '10%' },
]

export const consumptionColorsArray = [
  { color: '#E8001C', height: '10%' },
  { color: '#F05B20', height: '10%' },
  { color: '#FAAE2B', height: '10%' },
  { color: '#FEF639', height: '10%' },
  { color: '#D3F53A', height: '10%' },
  { color: '#B3D43D', height: '10%' },
  { color: '#009A48', height: '10%' },
  { color: '#3AB044', height: '10%' },
  { color: '#A8D0BA', height: '10%' },
  { color: '#fff', height: '10%' },
]

export const HEAT_MAP_KEY_CONSUMPTION = 'consumption'
export const HEAT_MAP_KEY_GENERATED_POWER = 'generated_power'
