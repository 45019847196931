import { createIcon } from '@chakra-ui/react'

const ChevronRightIcon = createIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M6 12L10 8L6 4"
      stroke="#707070"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  ),
})

export default ChevronRightIcon
