import React from 'react'
import { Tr, Td, Skeleton, VisuallyHidden } from '@chakra-ui/react'
import { DeviceRowDevice } from '../types'
import {
  ENERGY_GENERATOR,
  ENERGY_PLANT_LOGGER,
  KILOWATT,
  KILOWATT_HOUR,
} from '../../../utils/consts'
import DeviceDetailsButton from './DeviceDetailsButton'
import DeviceInfoLink from './DeviceInfoLink'
import { addCommasToNumber } from '../../../utils/helpers'
import Flippable from '../components/Flippable'

interface DeviceRowProps {
  device: DeviceRowDevice
  hiddenOnSmallDevice: boolean | undefined
  isFetching: boolean
  queryParams?: Record<string, any>
  bgColor?: string
  currentData?: any
  handleMouseEnter: any
  handleMouseLeave: any
  hoveredRow: any
}

const DeviceRow: React.FC<DeviceRowProps> = ({
  device,
  hiddenOnSmallDevice,
  isFetching,
  queryParams,
  bgColor = 'gray.200',
  currentData,
  handleMouseEnter,
  handleMouseLeave,
  hoveredRow,
}) => {
  const { id, title, total_consumption, category, total_generated_power } = device

  return (
    <Tr
      key={id}
      _hover={{ backgroundColor: bgColor }}
      width="100%"
      onMouseEnter={() => handleMouseEnter(id)}
      onMouseLeave={handleMouseLeave}
    >
      <Td>
        {isFetching ? (
          <Skeleton w="full" h="32px" />
        ) : (
          <DeviceInfoLink id={id} title={title} queryParams={queryParams} />
        )}
      </Td>
      {category === ENERGY_GENERATOR || category === ENERGY_PLANT_LOGGER ? (
        <>
          <Td hidden={hiddenOnSmallDevice}>
            {isFetching ? (
              <Skeleton w="full" h="32px" />
            ) : (
              `${addCommasToNumber(total_generated_power)} ${KILOWATT_HOUR}`
            )}
          </Td>
          <Td hidden={hiddenOnSmallDevice}>
            {isFetching ? (
              <Skeleton w="full" h="32px" />
            ) : (
              <VisuallyHidden>
                `${addCommasToNumber(total_generated_power || 0)} ${KILOWATT_HOUR}`
              </VisuallyHidden>
            )}
          </Td>
        </>
      ) : (
        <>
          <Td hidden={hiddenOnSmallDevice}>
            {isFetching ? (
              <Skeleton w="full" h="32px" />
            ) : (
              <Flippable
                value={addCommasToNumber(currentData?.consumption || 0)}
                unit={KILOWATT}
                hovered={hoveredRow === id}
              />
            )}
          </Td>
          <Td hidden={hiddenOnSmallDevice}>
            {isFetching ? (
              <Skeleton w="full" h="32px" />
            ) : (
              `${addCommasToNumber(total_consumption)} ${KILOWATT_HOUR}`
            )}
          </Td>
        </>
      )}
      <Td textAlign="right">
        <DeviceDetailsButton id={id} queryParams={queryParams} isFetching={isFetching} />
      </Td>
    </Tr>
  )
}

export default DeviceRow
