import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import dayjs from 'dayjs'
import React from 'react'
import { Line } from 'react-chartjs-2'

import { Periods } from '../../features/dashboard/types'
import { Chart } from '../services/api/types'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
)

export type Props = {
  period: string
  deviceData: { title?: string; datas: Chart.BaseData[] }[]
  title?: string
  config: [
    (
      | 'consumption'
      | 'generated_power'
      | 'min_voltage'
      | 'max_voltage'
      | 'power_factor'
      | 'current'
      | 'carbon_footprint'
    ),
    string,
  ][]
  chartTitle?: string
  showLegend?: boolean
  sameDevice?: boolean
}

const labelFormat: { [k: string]: string } = {
  day: 'HH:mm',
  week: 'MMM D',
  month: 'MMM D',
  year: 'MMM D',
}

export type ChartConfig = {}

interface DataSetsType {
  key:
    | 'consumption'
    | 'min_voltage'
    | 'max_voltage'
    | 'generated_power'
    | 'power_factor'
    | 'current'
    | 'carbon_footprint'
  data: Chart.BaseData[]
  label: string
}

const getChartLabels = (
  deviceData: any,
  period: string,
  sameDevice: boolean | undefined,
) => {
  if (sameDevice) {
    return deviceData.reduce((acc: any, device: any) => {
      device.datas.map(({ time }: any) => {
        acc.push(dayjs(time).format(labelFormat[period]))
      })
      return acc
    }, [] as any)
  } else {
    return deviceData.length
      ? deviceData[0].datas.map(({ time }: any) =>
          dayjs(time).format(labelFormat[period]),
        )
      : []
  }
}
const LineChart: React.FC<Props> = ({
  period,
  deviceData,
  config,
  chartTitle,
  showLegend = true,
  sameDevice,
}) => {
  const labels = getChartLabels(deviceData, period, sameDevice)
  const colors = ['#f66384', '#36A2EB']

  const dataSets = config.reduce<DataSetsType[]>((prev, [key, value]) => {
    const tempData = deviceData.map(({ datas: data, title: label = value }) => ({
      data,
      label,
      key,
    }))
    return prev.concat(tempData)
  }, [])

  const data = {
    labels,
    datasets: dataSets.map(({ data, label, key }, idx) => ({
      label: label,
      data: data.map((item) => ({
        x: dayjs(item.time).format(labelFormat[period]),
        y: item[key],
      })),
      borderColor: colors[idx],
      backgroundColor: colors[idx],
    })),
  }

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        align: 'start' as const,
        text: chartTitle,
      },
      legend: {
        display: showLegend,
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (val: any, index: any): any {
            switch (period) {
              case Periods.DAY:
                //show every third label
                return index % 3 === 0 ? labels[val].toString() : ''
              case Periods.WEEK:
                //show every third label
                return index % 2 === 0 ? labels[val].toString() : ''
              case Periods.MONTH:
                return labels[val].toString()
              case Periods.YEAR:
                //show every third label
                return index % 2 === 0 ? labels[val].toString() : ''
            }
          },
        },
      },
    },
  }
  return <Line datasetIdKey="id" data={data} options={options} />
}

export default LineChart

export const GradientLineChart: React.FC<Props> = ({
  period,
  deviceData,
  config,
  chartTitle,
  showLegend = true,
  sameDevice,
}) => {
  const labels = getChartLabels(deviceData, period, sameDevice)

  let gradient: CanvasGradient
  const getGradientColor = (
    context: CanvasRenderingContext2D,
    chartArea: any,
    colors: any,
  ): CanvasGradient => {
    if (!gradient) {
      gradient = context.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)

      gradient.addColorStop(0, colors['0'])
      // gradient.addColorStop(0.5, colors['0.5'])
      gradient.addColorStop(1, colors['1'])
    }
    return gradient
  }

  const dataSets = config.reduce<DataSetsType[]>((prev, [key, value]) => {
    const tempData = deviceData.map(({ datas: data, title: label = value }) => ({
      data,
      label,
      key,
    }))
    return prev.concat(tempData)
  }, [])

  const data = {
    labels,
    datasets: dataSets.map(({ data, label, key }, idx) => ({
      label: label,
      data: data.map((item) => ({
        x: dayjs(item.time).format(labelFormat[period]),
        y: item[key],
      })),

      borderColor: (context: any) => {
        if (!context.chart.chartArea) {
          return
        }
        const chart = context.chart as ChartJS<'line'>
        const { ctx, chartArea } = chart
        return getGradientColor(ctx, chartArea, {
          '0': '#0080ea',
          '0.5': '#60749e',
          '1': '#ff5d24',
        })
      },
      backgroundColor: (context: any) => {
        if (!context.chart.chartArea) {
          return
        }
        const chart = context.chart as ChartJS<'line'>
        const { ctx, chartArea } = chart

        let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
        gradient.addColorStop(0.2, 'rgba(128, 182, 244, 0.0)')
        gradient.addColorStop(1, 'rgba(244, 144, 128, 0.8)')
        return gradient
      },
      borderWidth: 5,
      fill: true,
    })),
  }

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.2,
      },
    },
    plugins: {
      title: {
        display: true,
        align: 'start' as const,
        text: chartTitle,
      },
      legend: {
        display: showLegend,
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (val: any, index: any): any {
            switch (period) {
              case Periods.DAY:
                //show every third label
                return index % 3 === 0 ? labels[val].toString() : ''
              case Periods.WEEK:
                //show every third label
                return index % 2 === 0 ? labels[val].toString() : ''
              case Periods.MONTH:
                return labels[val].toString()
              case Periods.YEAR:
                //show every third label
                return index % 2 === 0 ? labels[val].toString() : ''
            }
          },
          // display: false,
        },
      },
      // y: {
      //   ticks: {display: false,}
      // },
    },
  }
  // @ts-ignore
  return <Line datasetIdKey="id" data={data} options={options} redraw={true} />
}
