// ProgressBar.tsx
import React from 'react'
import { Progress, Stack } from '@chakra-ui/react'

interface ProgressBarProps {
  max?: number
  value: number
  variant: 'consumer' | 'generator'
  // You can extend this interface to include additional props, such as percentage, colors, etc.
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  max = 100,
  variant,
}) => {
  return (
    <Stack align="center" spacing={5} width="100%">
      <Progress
        w="100%"
        borderRadius="100px"
        value={value}
        variant={variant}
        max={max}
        sx={{
          div: {
            borderRadius: '100px',
          },
        }}
      />
    </Stack>
  )
}
