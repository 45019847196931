import React, { useCallback, useMemo } from 'react'
import {
  Flex,
  Text,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Collapse,
} from '@chakra-ui/react'
import { CloseIcon } from '../../../assets/icons/Close'
import Chip from '../../../app/components/Chip/Chip'
import Badge from '../../../app/components/Badge/Badge'
import SingleSelect from '../../reports/components/SingleSelect'
import DateRangeInputs from '../../../app/components/DateRangePicker/DateRangeInputs'
import { useForm } from 'react-hook-form'

interface Filters {
  from_date?: Date
  to_date?: Date
  severity?: string[]
  location?: number
  value_comparison?: string
  value?: number
}

const FilterModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  locations: any
  setFilters: (
    value: React.SetStateAction<{
      from_date?: Date
      to_date?: Date
      severity?: string[]
      location?: number
      value_comparison?: string
      value?: number
    }>,
  ) => void
  filters: Filters
}> = ({ isOpen, onClose, locations, setFilters, filters }) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<{
    from_date?: Date
    to_date?: Date
    severity?: string[]
    location: number
    value_comparison: string
    value?: number
  }>()

  const severity = watch('severity') || filters.severity || []
  const location = watch('location') || filters.location
  const value_comparison = watch('value_comparison') || filters.value_comparison

  const from_date = watch('from_date') || filters.from_date
  const to_date = watch('to_date') || filters.to_date
  const value = watch('value') || filters.value

  const isFilterSelected = useMemo(
    () =>
      (from_date && to_date) ||
      (value_comparison && value) ||
      severity?.length > 0 ||
      location,
    [from_date, to_date, value_comparison, value, severity, location],
  )

  const comparisonsArr = useMemo(
    () => [
      { id: 'eq', name: '= (equal to)' },
      { id: 'gt', name: '> (greater than)' },
      { id: 'lt', name: '< (less than)' },
      { id: 'gte', name: '>= (greater than or equal to)' },
      { id: 'lte', name: '<= (less than or equal to)' },
    ],
    [],
  )

  const setSelectedSeverity = useCallback(
    (severity: string[]) => {
      setValue('severity', severity)
    },
    [setValue],
  )

  const clearFields = useCallback(() => {
    onClose()
    reset()
    setFilters({
      from_date: undefined,
      to_date: undefined,
      severity: [],
      location: undefined,
      value_comparison: undefined,
    })
  }, [onClose, reset, setFilters])

  const handleOnClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  const handleFormSubmit = useCallback(
    (data: Filters) => {
      setFilters(data)
      onClose()
    },
    [setFilters, onClose],
  )

  const locationItems = useMemo(
    () =>
      locations.map(({ id, title }) => ({
        id,
        name: title,
      })),
    [locations],
  )

  return (
    <Modal onClose={handleOnClose} isOpen={isOpen} scrollBehavior="inside" size="xl">
      <ModalOverlay />
      <ModalContent>
        <Flex justifyContent="space-between" p="24px">
          <Text fontSize="24px" lineHeight="30px" fontWeight="700" color="text.dark">
            Filter Alarms
          </Text>
          <Button variant="ghost" onClick={handleOnClose}>
            <CloseIcon color="icon.primary" />
          </Button>
        </Flex>
        <ModalBody px="24px" pt="0" pb="24px">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Flex direction="column" gap="1">
              <Flex direction="column" gap="1">
                <Text fontSize="13px" color="text.dark">
                  Severity
                </Text>
                <Flex justifyContent="space-between">
                  <Chip
                    value="Critical"
                    name="Critical"
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    icons={{
                      selected: <Badge bg="red.500" boxSize="12px" />,
                      unselected: <Badge bg="red.500" boxSize="12px" />,
                    }}
                  />
                  <Chip
                    value="Major"
                    name="Major"
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    icons={{
                      selected: <Badge bg="red.200" boxSize="12px" />,
                      unselected: <Badge bg="red.200" boxSize="12px" />,
                    }}
                  />
                  <Chip
                    value="Minor"
                    name="Minor"
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    icons={{
                      selected: <Badge bg="orange.300" boxSize="12px" />,
                      unselected: <Badge bg="orange.300" boxSize="12px" />,
                    }}
                  />
                  <Chip
                    value="Warning"
                    name="Warning"
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    icons={{
                      selected: <Badge bg="yellow.300" boxSize="12px" />,
                      unselected: <Badge bg="yellow.300" boxSize="12px" />,
                    }}
                  />
                  <Chip
                    value="Info"
                    name="Info"
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    icons={{
                      selected: <Badge bg="icon.strong" boxSize="12px" />,
                      unselected: <Badge bg="icon.strong" boxSize="12px" />,
                    }}
                  />
                </Flex>
              </Flex>
              <Flex direction="column" gap="1">
                <Text fontSize="13px" color="text.dark">
                  Location
                </Text>

                <SingleSelect
                  header="Select a location"
                  items={locationItems}
                  setSelectedOption={(selectedOptionId: number) =>
                    setValue('location', selectedOptionId)
                  }
                  selectedOption={location}
                />
              </Flex>

              <Flex direction="column" gap="1">
                <Text fontSize="13px" color="text.dark">
                  Value
                </Text>

                <Flex justify="space-between" gap="2" align="center">
                  <SingleSelect
                    header="Select a comparison"
                    items={comparisonsArr}
                    setSelectedOption={(selectedOptionId: string) =>
                      setValue('value_comparison', selectedOptionId)
                    }
                    selectedOption={value_comparison}
                  />
                  <Text>-</Text>
                  <Input {...register('value')} type="number" placeholder="Value" />
                </Flex>
              </Flex>

              <Flex direction="column" gap="1">
                <Text fontSize="13px" color="text.dark">
                  Occurance time
                </Text>
                {/* @ts-ignore */}
                <DateRangeInputs
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  selectedStartName="from_date"
                  selectedEndName="to_date"
                  startDate={from_date}
                  endDate={to_date}
                  isRequired={false}
                />
              </Flex>
            </Flex>
            <Flex direction="column" my="24px">
              <Button
                type="submit"
                colorScheme="button.primary"
                disabled={!isFilterSelected}
                mb="2"
              >
                Save
              </Button>
              <Collapse in={!!isFilterSelected}>
                <Button onClick={clearFields} variant="ghost" w="full">
                  Clear filters
                </Button>
              </Collapse>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default FilterModal
