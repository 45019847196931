import { Button } from '@chakra-ui/react'
import React from 'react'
import PowerIcon from '../../../assets/icons/PowerIcon'

interface ToggleProps {
  handleClick: () => void
  isActive: boolean
  isLoading: boolean
}

export const Toggle: React.FC<ToggleProps> = ({ handleClick, isActive, isLoading }) => {
  return (
    <Button
      onClick={handleClick}
      variant="toggle"
      isActive={isActive}
      isLoading={isLoading}
    >
      <PowerIcon />
    </Button>
  )
}
