import {
  Avatar,
  Button,
  Heading,
  Link,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MdLogout } from 'react-icons/md'
import { useUser } from '../../context/UserContext'
import { resetAPISState } from '../services/api/api'

type Props = {
  mediaQuery?: string
  children?: React.ReactNode
  title?: string
}

const Header: React.FC<Props> = ({
  children,
  mediaQuery = '(max-width: 1200px)',
  title,
}) => {
  const navigate = useNavigate()
  const [isMobileHeader] = useMediaQuery(mediaQuery)
  const { user, setUser } = useUser()
  const dispatch = useDispatch()
  const logout = () => {
    window.localStorage.removeItem('aiot_t')
    window.localStorage.removeItem('username')
    setUser(null)
    resetAPISState(dispatch)
    navigate('/login')
  }
  return isMobileHeader ? (
    <>{children}</>
  ) : (
    <Stack direction="row" justify="space-between" mb="24px">
      {title ? (
        <Stack direction="row" w="full">
          <Text color="text.dark" fontSize="24px" fontWeight={700} lineHeight="30px">
            {title}
          </Text>
        </Stack>
      ) : null}
      <Stack direction="row" justify={children ? 'space-between' : 'flex-end'} w="full">
        {children}
        <Stack align="center" direction="row" spacing={4}>
          <Text whiteSpace="nowrap">{user?.name}</Text>

          {/* <Link href="/alarms">
          </Link> */}
          <Avatar size="sm" name={user?.name} src="https://bit.ly/kent-c-dworkodds" />
          <Button onClick={logout} leftIcon={<MdLogout />}>
            Logout
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
export default Header
