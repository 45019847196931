import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useQueryParams = (params: string[]) => {
  const [searchParams] = useSearchParams()
  const queryParams = useMemo(() => {
    return Object.fromEntries(
      params
        .filter((param) => searchParams.get(param))
        .map((param) => [param, searchParams.get(param)]),
    ) as { [k: string]: string }
  }, [searchParams, params])

  return [queryParams]
}
