import { HStack, VStack } from '@chakra-ui/react'
import React from 'react'
import { Analysis } from '../../api/types'
import { Cell } from './Cell'

type Props = {
  items: Analysis.Response | undefined
  pointerEvents: any
  consumptionRates: Analysis.Rates
  heatMapKey: string
}

const Grid: React.FC<Props> = ({
  items,
  pointerEvents,
  consumptionRates,
  heatMapKey,
}) => {
  return (
    <VStack
      spacing={0}
      overflow="hidden"
      zIndex={1}
      sx={{ pointerEvents: pointerEvents }}
      border="dimgray"
    >
      {Object.keys(items?.results[heatMapKey])
        .sort()
        .map((key, idx) => (
          <HStack spacing={0} key={idx} w="full">
            {items?.results[heatMapKey][key].map((singleHour, index) => {
              const currentConsumption =
                singleHour[heatMapKey] <= 0.01 ? 0 : singleHour[heatMapKey]

              return (
                <Cell
                  key={singleHour.idx}
                  heatMapKey={heatMapKey}
                  item={{
                    idx: singleHour.idx,
                    hour: singleHour.hour,
                    day: key,
                    consumption: currentConsumption,
                    consumptionRates,
                  }}
                />
              )
            })}
          </HStack>
        ))}
    </VStack>
  )
}

export default Grid
