let socket: WebSocket | null = null

export const initiateSocketConnection = (url: string, token: string): void => {
  console.log(`Connecting to WebSocket server at ${url}`)

  const customHeaderData = {
    Authorization: `Token ${token}`,
  }

  // Encode the custom headers as a JSON string and base64 encode it
  const encodedHeaderData = btoa(JSON.stringify(customHeaderData))

  const protocols = [encodedHeaderData]
  socket = new WebSocket(url)
  // socket = new WebSocket(url, protocols)

  socket.onopen = () => {
    console.log('WebSocket connected')
  }

  socket.onclose = (event) => {
    console.log('WebSocket disconnected', event.reason)
  }

  socket.onerror = (error) => {
    console.error('WebSocket error', error)
  }

  socket.onmessage = (event) => {
    console.log('WebSocket message received:', event.data)
  }
}

export const disconnectSocket = (): void => {
  if (socket) {
    console.log('Disconnecting WebSocket')
    socket.close()
  }
}

export const sendMessage = (message: string): void => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    console.log('Sending message:', message)
    socket.send(message)
  } else {
    console.error('WebSocket is not open')
  }
}

export const subscribeToMessages = (callback: (message: any) => void): void => {
  if (socket) {
    socket.onmessage = (event) => {
      callback(event.data)
    }
  }
}
