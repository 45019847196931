export const prepareFilters = (filters) => {
  const searchParams = new URLSearchParams()
  Object.keys(filters).forEach((filterName) => {
    if (Array.isArray(filters[filterName])) {
      filters[filterName].forEach((filter) => searchParams.append(filterName, filter))
      return
    }
    if (filters[filterName] !== undefined && filters[filterName] !== '') {
      searchParams.append(filterName, filters[filterName])
    }
  })

  return searchParams
}
