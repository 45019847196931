import { createIcon } from '@chakra-ui/icon'

export const ListViewIcon = createIcon({
  displayName: 'ListViewIcon',
  viewBox: '0 0 18 18',
  path: (
    <g
      id="Group_69"
      data-name="Group 69"
      transform="translate(-66.6 -124)"
      fill="currentColor"
    >
      <rect
        id="Rectangle_83"
        data-name="Rectangle 83"
        width="4"
        height="4"
        rx="2"
        transform="translate(66.6 124)"
      />
      <rect
        id="Rectangle_90"
        data-name="Rectangle 90"
        width="4"
        height="4"
        rx="2"
        transform="translate(66.6 131)"
      />
      <rect
        id="Rectangle_92"
        data-name="Rectangle 92"
        width="4"
        height="4"
        rx="2"
        transform="translate(66.6 138)"
      />
      <rect
        id="Rectangle_84"
        data-name="Rectangle 84"
        width="12"
        height="4"
        rx="2"
        transform="translate(72.6 124)"
      />
      <rect
        id="Rectangle_89"
        data-name="Rectangle 89"
        width="12"
        height="4"
        rx="2"
        transform="translate(72.6 131)"
      />
      <rect
        id="Rectangle_91"
        data-name="Rectangle 91"
        width="12"
        height="4"
        rx="2"
        transform="translate(72.6 138)"
      />
    </g>
  ),
})
