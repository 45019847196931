import { Spinner } from '@chakra-ui/react'

const Loading = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading) {
    return <Spinner color="white.200" />
  }
  return null
}

export default Loading
