import { createIcon } from '@chakra-ui/icon'

export const GridIcon = createIcon({
  displayName: 'GridIcon',
  viewBox: '0 0 18 18',
  path: (
    <g
      id="Group_69"
      data-name="Group 69"
      transform="translate(-66.6 -124)"
      fill="currentColor"
    >
      <rect
        id="Rectangle_83"
        data-name="Rectangle 83"
        width="8"
        height="8"
        rx="2"
        transform="translate(66.6 124)"
      />
      <rect
        id="Rectangle_86"
        data-name="Rectangle 86"
        width="8"
        height="8"
        rx="2"
        transform="translate(66.6 134)"
      />
      <rect
        id="Rectangle_84"
        data-name="Rectangle 84"
        width="8"
        height="8"
        rx="2"
        transform="translate(76.6 124)"
      />
      <rect
        id="Rectangle_85"
        data-name="Rectangle 85"
        width="8"
        height="8"
        rx="2"
        transform="translate(76.6 134)"
      />
    </g>
  ),
})
