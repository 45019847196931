import { createIcon } from '@chakra-ui/icon'

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 22 24.721',
  path: (
    <path
      id="Path_1338"
      data-name="Path 1338"
      d="M23,11.163V25.721H16.518V18.189H9.482v7.532H3V11.163L13,3Z"
      transform="translate(-2 -2)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  ),
})
