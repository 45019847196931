import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Text,
  useBoolean,
  useMediaQuery,
} from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  useGetDashboardOverviewQuery,
  useGetCurrentLocationsDataQuery,
} from '../api/dasboardApi'
import { useQueryParams } from '../hooks/useQueryParams'
import { DEFAULTPERIOD, DEVICEPARAMS, PERIODPARAMS } from '../consts'
import { LocationTable } from '../widgets/LocationsTable'
import { LocationTabStrip } from '../components/LocationTabStrip'
import { PeriodTabStrip } from '../components/PeriodTabStrip'
import Header from '../../../app/components/Header'
import { GridIcon } from '../components/icons/Grid'
import { ListViewIcon } from '../components/icons/ListView'
import ListView from '../widgets/ListView'
import { CURRENT_USAGE_POLLING_INTERVAL } from '../../../utils/consts'
import { DashboardOverviewLocation } from '../types'

const Dashboard: React.FC<{ locations: any; overviewFetching: boolean }> = ({
  locations,
  overviewFetching,
}) => {
  const navigate = useNavigate()
  const [gridView, setGridView] = useBoolean(false)
  const [queryParams] = useQueryParams(PERIODPARAMS)
  const querySearchParams = { ...DEFAULTPERIOD, ...queryParams }
  const [deviceQueryParams] = useQueryParams(DEVICEPARAMS)

  const locationFlag = !!deviceQueryParams?.location

  useEffect(() => {
    if (locations.length === 1) {
      navigate(
        `/dashboard/location/${locations[0].id.toString()}?${new URLSearchParams(
          queryParams,
        ).toString()}`,
      )
    }
  }, [locations, queryParams, navigate])

  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)')
  const HeaderContent = <Header title="Overview" />

  const ids = (locations as DashboardOverviewLocation[]).map((l) => l.id)

  const { data: currentLocationsData = [] } = useGetCurrentLocationsDataQuery(
    {
      locationIds: ids,
    },
    {
      pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
      skip: ids.length === 0,
    },
  )

  return (
    <>
      {!isSmallerThan1200 && HeaderContent}
      <LocationTabStrip
        data={locations?.filter(({ parent_id }) => parent_id === null)}
        clearNavigationPath="dashboard"
        handleNavigationPath="dashboard/location"
      />
      <Box mt={6} w="full" bg="white" rounded="lg">
        {gridView ? (
          <ListView locations={locations} />
        ) : (
          <>
            <Flex justifyContent="space-between" w="full" p="4">
              <PeriodTabStrip />
              <HStack hidden={locationFlag || isSmallerThan1200}>
                <IconButton
                  background="none"
                  onClick={() => {
                    setGridView.off()
                  }}
                  aria-label="change view to list view"
                  size="xs"
                  _focus={{ boxShadow: 'none' }}
                  icon={
                    <ListViewIcon w={5} h={5} color={gridView ? '#DEDFE2' : '#157FEE'} />
                  }
                />
                {!gridView && <Text fontSize="sm">List View</Text>}
                <IconButton
                  onClick={() => setGridView.on()}
                  aria-label="change view to grid view"
                  size="xs"
                  background="none"
                  _focus={{ boxShadow: 'none' }}
                  icon={<GridIcon w={5} h={5} color={gridView ? '#157FEE' : '#DEDFE2'} />}
                />
                {gridView && <Text fontSize="sm">Grid View</Text>}
              </HStack>
            </Flex>
            <LocationTable
              data={locations}
              isLoading={overviewFetching}
              currentLocationsData={currentLocationsData || []}
            />
          </>
        )}
      </Box>
    </>
  )
}

export default Dashboard
