import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const Tabs: ComponentMultiStyleConfig = {
  parts: ['tabs', 'tab', 'tablist'],
  baseStyle: {
    tab: {
      _selected: {
        boxShadow: 'none',
      },
    },
  },
  variants: {
    dashboard: {
      tabs: {
        size: 'xl',
        colorScheme: 'blue',
      },
      tablist: {
        borderBottom: '2px solid',
        borderColor: 'inherit',
      },
      tab: {
        px: '1.5rem',
        py: '1rem',
        color: 'brand.700',
        borderBottom: '3px solid',
        borderColor: 'transparent',
        whiteSpace: 'nowrap',
        _selected: {
          color: '#157FEE',
          borderColor: 'currentColor',
          bg: 'gray.200',
        },
        _hover: {
          boxShadow: '',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
}

export default Tabs
