import { createIcon } from '@chakra-ui/react'

const ArrowDownIcon = createIcon({
  displayName: 'AnotherIcon',
  viewBox: '0 0 16 16',
  path: (
    <g id="Trailing-icon">
      <path
        id="Vector"
        d="M8 3.33334V12.6667"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Vector_2"
        d="M12.6668 8L8.00016 12.6667L3.3335 8"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
})

export default ArrowDownIcon
