import { ArrowBackIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ChangeEvent, useState, KeyboardEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../app/components/Header'
import { useAddLocationMutation } from './api/locationsApi'
import ImageModal from './components/ImageModal'
import { useDropzone } from 'react-dropzone'
import Loading from './components/Loading'
import { KILOWATT_HOUR } from '../../utils/consts'

export enum IMAGES {
  LIVING_ROOM_1 = 'living-room-1',
  LIVING_ROOM_2 = 'living-room-2',
  LIVING_ROOM_3 = 'living-room-3',
  BEDROOM_1 = 'bedroom-1',
  BEDROOM_2 = 'bedroom-2',
  OFFICE_1 = 'office-1',
  KITCHEN_1 = 'kitchen-1',
  WORKING_PLACE_1 = 'working-place-1',
  WORKING_PLACE_2 = 'working-place-2',
}

const AddLocation = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [ownImage, setOwnImage] = useState<any>(null)
  const [pureImage, setPureImage] = useState<any>(null)
  const [selectedImage, setSelectedImage] = useState<IMAGES | null>(null)
  const [newSublocations, setNewSublocations] = useState<string[]>([])
  const [subLocation, setSubLocation] = useState<string>('')

  const location = useLocation()
  const navigate = useNavigate()
  const [addLocation, { isLoading, isError }] = useAddLocationMutation()

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setPureImage(acceptedFiles[0])
      setOwnImage(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        )[0],
      )
    },
  })

  const { locationName, locationId } = location.state || ({} as any)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const handleSublocationsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSubLocation(value)
  }

  const handleSublocationsKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === ',') && subLocation) {
      setNewSublocations([...newSublocations, subLocation])
      setSubLocation('')
      e.preventDefault()
    }
  }

  async function onSubmit(values: any) {
    const formData = new FormData()
    formData.append('title', values.name)
    formData.append('price', values.price)
    if (selectedImage) {
      formData.append('description', selectedImage)
    }
    if (pureImage) {
      formData.append('image', pureImage)
    }
    if (locationId) {
      formData.append('parent_id', locationId)
    }

    formData.append('sublocations', newSublocations.join(','))

    await addLocation(formData)
      .unwrap()
      .then((res) => {
        if (locationId) {
          navigate(`/locations/${locationId}/devices`)
        } else {
          navigate('/locations')
        }
      })
  }

  return (
    <>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold" pl={{ base: 6, lg: 0 }}>
          <Stack
            _hover={{ cursor: 'pointer' }}
            direction="row"
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBackIcon />
            <Text>
              {(location.state as any)?.path === 'locations' ? 'Locations' : 'Devices'}
            </Text>
          </Stack>
        </Heading>
      </Header>
      <Stack
        direction="column"
        boxShadow="lg"
        rounded="lg"
        mt={4}
        borderWidth={1}
        w="100%"
        maxWidth="2xl"
        px={6}
        py={8}
        mb={20}
        spacing={8}
        mx="auto"
      >
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          {locationName ? `Add new sub location to ${locationName}` : `Add new location`}
        </Heading>
        <VStack spacing={6} as="form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.name !== undefined} color="text.light">
            <Input
              placeholder="Enter location name *"
              id="name"
              color="#000"
              {...register('name', {
                required: '* Required field',
              })}
            />
            <FormErrorMessage>
              {(errors.name?.message as React.ReactNode) ?? null}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.price !== undefined} color="text.light">
            <Input
              type="text"
              placeholder={`Enter supplier base electricity price (BGN / ${KILOWATT_HOUR})*`}
              id="price"
              color="#000"
              {...register('price', {
                required: '* Required field',
              })}
            />
            <FormErrorMessage>
              {(errors.price?.message as React.ReactNode) ?? null}
            </FormErrorMessage>
          </FormControl>
          <FormControl color="text.light">
            <Input
              placeholder="Add sublocations (optional)"
              id="subLocations"
              onChange={handleSublocationsChange}
              onKeyDown={handleSublocationsKeyDown}
              value={subLocation}
              color="#000"
            />
            <Text fontSize={'10px'} mb={2} color="text.dark">
              To add new sub location just use Enter or comma after every sub location
            </Text>
            <Box>
              {newSublocations.map((subloc, i) => {
                return (
                  <Tag
                    pb={1}
                    pt={1}
                    pl={3}
                    pr={3}
                    mr={1}
                    mb={1}
                    key={`${subloc}-${i}`}
                    borderRadius="lg"
                    variant="solid"
                    colorScheme="gray"
                    display="inline-flex"
                    alignContent="center"
                  >
                    <TagLabel fontSize="sm">{subloc}</TagLabel>
                    <TagCloseButton
                      onClick={() => {
                        setNewSublocations(newSublocations.filter((s) => s !== subloc))
                      }}
                    />
                  </Tag>
                )
              })}
            </Box>
          </FormControl>
          <FormControl color="text.dark">
            <Text mb={2}>Add image (optional)</Text>
            {ownImage || selectedImage ? (
              <HStack alignItems={'flex-start'}>
                <Box>
                  <Image
                    width={'250px'}
                    src={ownImage ? ownImage.preview : `/locations/${selectedImage}.png`}
                    onLoad={() => {
                      if (ownImage) {
                        URL.revokeObjectURL(ownImage.preview)
                      }
                    }}
                  />
                </Box>
                <VStack alignItems={'flex-start'} pl={4}>
                  <Button
                    onClick={() => {
                      if (ownImage) {
                        setOwnImage(null)
                      } else {
                        setIsOpen(true)
                      }
                    }}
                    variant="unstyled"
                    rightIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      if (ownImage) {
                        setOwnImage(null)
                      } else {
                        setSelectedImage(null)
                      }
                    }}
                    variant="unstyled"
                    rightIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </VStack>
              </HStack>
            ) : (
              <>
                <Box
                  h="130px"
                  borderRadius="10px"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  border="1px dashed #dedfe2"
                  background="#F7F7F7"
                  cursor="pointer"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {
                    <Text fontSize="16px" color="#DEDFE2">
                      Drag & drop or
                      <Button
                        background="transparent"
                        border="0"
                        pl="2"
                        color="#9B9B9B"
                        textDecor="underline"
                        outline="none"
                        _hover={{
                          background: 'transparent',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setIsOpen(true)
                        }}
                      >
                        browse in library
                      </Button>
                    </Text>
                  }
                </Box>
              </>
            )}
          </FormControl>
          {isError && (
            <Text alignSelf="flex-start" color="red.400">
              There is some error, please try again later
            </Text>
          )}
          <Button
            colorScheme="button.primary"
            size="lg"
            w={60}
            h={12}
            alignSelf="flex-start"
            mt={8}
            type="submit"
            rightIcon={<Loading isLoading={isLoading} />}
          >
            Add
          </Button>
        </VStack>

        <ImageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
        />
      </Stack>
    </>
  )
}

export default AddLocation
