import { createIcon } from '@chakra-ui/icon'

const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 16 16',
  path: (
    <g fill="none">
      <path
        d="M12.6663 8H3.33301"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99967 12.6667L3.33301 8L7.99967 3.33333"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
})

export default ArrowLeftIcon
