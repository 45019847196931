import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  MenuDivider,
  Tooltip,
  PlacementWithLogical,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

interface Props {
  items?: { name: string; id: number | string; isMain?: boolean }[]
  setSelectedOption: (value: number | string) => void
  selectedOption: number | string | undefined
  header: string
  isDisabled?: boolean
  tooltip?: {
    label: string
    hasArrow: boolean
    placement: PlacementWithLogical
  }
}

const SingleSelect: React.FC<Props> = ({
  items,
  setSelectedOption,
  selectedOption,
  header,
  isDisabled,
  tooltip,
}) => {
  const selectedItemName = items?.find((item) => item.id === selectedOption)?.name

  const renderWithTooltip = (children: JSX.Element) => (
    <Tooltip
      w="full"
      label={tooltip!.label}
      placement={tooltip!.placement}
      hasArrow={tooltip!.hasArrow}
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  )

  const MenuButtonComponent = (
    <MenuButton
      w="full"
      isDisabled={isDisabled}
      as={Button}
      rightIcon={<ChevronDownIcon />}
      bg="white"
      border="1px"
      height="48px"
      borderColor="border.strong"
      textAlign="left"
      fontSize="18px"
      _disabled={{ color: 'gray.400' }}
      fontWeight="normal"
    >
      {selectedItemName || header}
    </MenuButton>
  )

  return (
    <Menu closeOnSelect={true}>
      {isDisabled && tooltip
        ? renderWithTooltip(MenuButtonComponent)
        : MenuButtonComponent}
      <MenuList as="ul" maxHeight="25rem" overflowY="auto" zIndex="20">
        {items?.map((item, index) => (
          <React.Fragment key={`${item.name}-${index}`}>
            <MenuItem
              zIndex="10"
              as="li"
              key={item.id}
              onClick={() => {
                setSelectedOption(item.id)
              }}
            >
              <Text>{item.name}</Text>
            </MenuItem>
            {item.isMain ? <MenuDivider /> : null}
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  )
}

export default SingleSelect
