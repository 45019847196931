import {
  Box,
  Heading,
  Grid,
  GridItem,
  Flex,
  Text,
  Button,
  Spinner,
  Image,
  Divider,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import Header from '../../../app/components/Header'
import aiotReportAnimation from '../../../assets/Aiot_report_page_animation.png'
import Chip from '../../../app/components/Chip/Chip'
import DateRangeInputs from '../../../app/components/DateRangePicker/DateRangeInputs'
import { useForm } from 'react-hook-form'
import { useGetAllLocationsQuery, useGetReportsHistoryQuery } from '../api/reportsApi'
import { useGetReportAsPDFMutation } from '../../../app/services/api/api'
import { format } from 'date-fns'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { CheckIcon } from '../../../assets/icons/Check'
import { EllipseIcon } from '../../../assets/icons/Ellipse'
import { OverflowButton } from './components/OverflowButton'

enum DIRECTION {
  COL = 'column',
  ROW = 'row',
}

const GenerateReport: React.FC = () => {
  const { data: locations, error: getLocationsError } = useGetAllLocationsQuery()

  const { data: reportsHistoryData, isLoading: isReportHistoryLoading } =
    useGetReportsHistoryQuery({
      page: 1,
    })

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ startDate: Date; endDate: Date; selectedLocations: string[] }>()

  useEffect(() => {
    setValue(
      'selectedLocations',
      locations?.results.map((location: any) => location.id.toString()),
    )
  }, [locations, setValue])
  const setSelectedLocations = (selectedLocations: string[]) => {
    setValue('selectedLocations', selectedLocations)
  }
  const startDate = watch('startDate') || null
  const endDate = watch('endDate') || null
  const selectedLocations = watch('selectedLocations') || []

  const [getReportAsPDF, { isLoading, error }] = useGetReportAsPDFMutation()

  const navigate = useNavigate()
  const onSubmit = () => {
    navigate({
      pathname: `/reports/${btoa(
        selectedLocations.map((loc) => Number(loc)).join(', '),
      )}`,
      search: `?${createSearchParams({
        from: format(startDate, 'yyyy-MM-dd'),
        to: format(endDate, 'yyyy-MM-dd'),
      })}`,
    })
    // getReportAsPDF({
    //   from_date: format(startDate, 'yyyy-MM-dd'),
    //   to_date: format(endDate, 'yyyy-MM-dd'),
    //   location_ids: selectedLocations.map((loc) => Number(loc)),
    //   exclude_hidden: true,
    // })
  }

  if (isReportHistoryLoading) {
    return (
      <Flex h="100vh" w="100%" justifyContent="space-around" align="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  const {
    count: reportHistoryCount,
    results: reportsHistory,
    total_pages: totalPages,
  } = reportsHistoryData

  return (
    <>
      <Header />

      <Grid templateColumns={`repeat(${reportHistoryCount === 0 ? '1' : '2'}, 1fr)`}>
        <GridItem h="100%">
          <Box h="100%">
            <Flex justifyContent="space-around" align="center" h="100%">
              <Box>
                {reportHistoryCount === 0 && (
                  <Box position="relative" width="620px" height="210px">
                    <Image
                      src={aiotReportAnimation}
                      position="absolute"
                      top="0"
                      left="0"
                    />
                  </Box>
                )}

                <Flex justifyContent="space-around" zIndex="1" position="relative">
                  <Box maxW="470px">
                    <Flex
                      direction="column"
                      gap="32px"
                      as="form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Flex gap="3" textAlign="center" direction="column">
                        <Heading color="text.dark">Generate report</Heading>
                        <Text
                          color="text.dark"
                          fontSize={14}
                          fontWeight="400"
                          letterSpacing={0.28}
                        >
                          Reports bring your energy data to life. Understand trends,
                          identify areas for improvement, and measure your impact on the
                          environment.
                        </Text>
                      </Flex>

                      <Flex
                        justifyContent="center"
                        alignItems="flex-start"
                        wrap="wrap"
                        gap="3"
                      >
                        {locations?.results.map(
                          ({ title, id }: { title: string; id: number }) => (
                            <Chip
                              name={title}
                              value={id.toString()}
                              selectedValues={selectedLocations}
                              setSelected={setSelectedLocations}
                              icons={{
                                selected: <CheckIcon />,
                                unselected: <EllipseIcon />,
                              }}
                            />
                          ),
                        )}
                      </Flex>
                      <Flex alignItems="center">
                        {/* @ts-ignore */}
                        <DateRangeInputs
                          register={register}
                          watch={watch}
                          setValue={setValue}
                          errors={errors}
                          selectedStartName="startDate"
                          selectedEndName="endDate"
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Flex>
                      <Button
                        alignSelf="flex-start"
                        colorScheme="button.primary"
                        type="submit"
                        disabled={
                          !startDate || !endDate || selectedLocations.length === 0
                        }
                        w="full"
                        h={12}
                      >
                        {isLoading ? <Spinner /> : 'Generate Report'}
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </GridItem>

        {reportHistoryCount !== 0 && (
          <GridItem>
            <Box bg="white" rounded="lg" pl="24px">
              <Text fontSize="16px" fontWeight="700" py="24px">
                Report History
              </Text>
              <Grid templateColumns={`repeat(2), 1fr)`}>
                {reportsHistory?.map((rh) => (
                  <>
                    <GridItem py="16px">
                      <Flex
                        justifyContent="left"
                        fontSize="14px"
                        fontWeight="700"
                        lineHeight="18px"
                        h="30px"
                        direction="column"
                      >
                        <Text color="text.dark">
                          {format(new Date(rh.from_date), 'dd MMM yyyy')} -{' '}
                          {format(new Date(rh.to_date), 'dd MMM yyyy')}
                        </Text>
                        <Text fontSize="12px" fontWeight="400" color="text.secondary">
                          {rh.locations.length === locations?.results?.length
                            ? 'All locations'
                            : rh.locations
                                .map(
                                  (rhLoc) =>
                                    locations?.results.find((loc) => loc.id === rhLoc)
                                      ?.title,
                                )
                                .join(' . ')}
                        </Text>
                      </Flex>
                    </GridItem>

                    <GridItem pr="24px">
                      <Flex justifyContent="right" align="center" h="100%">
                        <OverflowButton
                          locations={locations?.results}
                          from={rh.from_date}
                          to={rh.to_date}
                        />
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Divider />
                    </GridItem>
                  </>
                ))}

                {totalPages > 1 && (
                  <GridItem colSpan={2} py="24px">
                    <Button as="a" href="/reports/history">
                      View All
                    </Button>
                  </GridItem>
                )}
              </Grid>
            </Box>
          </GridItem>
        )}
      </Grid>
    </>
  )
}

export default GenerateReport
