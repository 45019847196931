import { Flex, Heading, Skeleton } from '@chakra-ui/react'
import React from 'react'
import { CELSIUM, HUMIDITY, TVOC, CO2 } from '../../../utils/consts'
import Flippable from './Flippable'

interface ClimateDeviceTitleProps {
  isDeviceDataFetching: boolean
  deviceData: {
    temperature: number
    humidity: number
    co2?: number
    tvoc?: number
  }
}

const ClimateDeviceTitle: React.FC<ClimateDeviceTitleProps> = ({
  isDeviceDataFetching,
  deviceData,
}) => {
  return (
    <>
      <Flex direction="column">
        <Heading as="h4" fontSize="sm" color="text.light">
          Temperature
        </Heading>
        <Skeleton isLoaded={!isDeviceDataFetching}>
          <Flippable value={Math.round(deviceData?.temperature)} unit={CELSIUM} />
        </Skeleton>
      </Flex>
      <Flex direction="column">
        <Heading as="h4" fontSize="sm" color="text.light">
          Humidity
        </Heading>
        <Skeleton isLoaded={!isDeviceDataFetching}>
          <Flippable value={Math.round(deviceData?.humidity)} unit={HUMIDITY} />
        </Skeleton>
      </Flex>
      {deviceData?.co2 ? (
        <Flex direction="column">
          <Heading as="h4" fontSize="sm" color="text.light">
            CO2
          </Heading>
          <Skeleton isLoaded={!isDeviceDataFetching}>
            <Flippable value={Math.round(deviceData?.co2)} unit={CO2} />
          </Skeleton>
        </Flex>
      ) : null}
      {deviceData?.tvoc ? (
        <Flex direction="column">
          <Heading as="h4" fontSize="sm" color="text.light">
            Total volatile organic compounds (TVOC)
          </Heading>
          <Skeleton isLoaded={!isDeviceDataFetching}>
            <Flippable value={Math.round(deviceData?.tvoc)} unit={TVOC} />
          </Skeleton>
        </Flex>
      ) : null}
    </>
  )
}

export default ClimateDeviceTitle
