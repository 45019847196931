import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Heading,
  HStack,
  Stack,
  Tooltip,
  Text,
  VStack,
  Skeleton,
  Button,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SelectableGroup } from 'react-selectable-fast'
import {
  useGetDeviceHeatMapQuery,
  useGetAnalysisLocationsQuery,
  useGetDevicesQuery,
} from '../api/analysisApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TSelectableItem } from 'react-selectable-fast/lib/Selectable.types'
import Grid from '../components/Selectable/Grid'
import { useGetDeviceDataQuery } from '../../../app/services/api/api'
import { ArrowBackIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'
import Header from '../../../app/components/Header'
import AnalysisHomeForm from '../components/AnalysisHomeForm'
import { addCommasToNumber } from '../../../utils/helpers'
import DevicesBreakdown from '../components/DevicesBreakdown'
import { SelectableItem } from '../api/types'
import { KILOWATT, KILOWATT_HOUR } from '../../../utils/consts'
import {
  consumptionColorsArray,
  HEAT_MAP_KEY_CONSUMPTION,
  HEAT_MAP_KEY_GENERATED_POWER,
  photovoltaicsColorsArray,
} from '../utils'

interface SelectableProps {
  item: number
  hour: number
  consumption: number
  day: string
  idx: number
}

const AnimatedHeading = motion(Heading)
export const AnalysisData: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [heatMapKey, setHeatMapKey] = useState('consumption')

  const selectableGridDimentions = {
    width:
      (((parseInt(searchParams.get('to_hour') || '24') -
        parseInt(searchParams.get('from_hour') || '0')) /
        2) *
        24 +
        25) *
      4,
    height:
      (dayjs(searchParams.get('to_date')).diff(searchParams.get('from_date'), 'days') *
        6 +
        25) *
      4,
  }

  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const deviceId = searchParams.get('device')
  const { data: analysisData, isSuccess: AnalysisDataSuccess } = useGetDeviceHeatMapQuery(
    {
      from_date: searchParams.get('from_date') || '',
      to_date: searchParams.get('to_date') || '',
      device: deviceId,
      location: searchParams.get('location'),
      from_hour: searchParams.get('from_hour') || '0',
      to_hour: searchParams.get('to_hour') || '23',
      interval: 1,
    },
  )

  useEffect(() => {
    if (analysisData?.isGenerator) {
      setHeatMapKey(HEAT_MAP_KEY_GENERATED_POWER)
    } else {
      setHeatMapKey(HEAT_MAP_KEY_CONSUMPTION)
    }
  }, [analysisData])

  const colorsArray =
    heatMapKey === HEAT_MAP_KEY_CONSUMPTION
      ? consumptionColorsArray
      : photovoltaicsColorsArray

  const consumptionRates = useMemo(() => {
    if (analysisData) {
      const { consumptionBorders } = analysisData

      let maxConsumption
      let minConsumption

      if (consumptionBorders) {
        maxConsumption = 4500 / 1000
        minConsumption = consumptionBorders.min / 1000
      } else {
        const consumptions: number[] = []

        Object.keys(analysisData.results[heatMapKey]).forEach((key: string) => {
          analysisData.results[heatMapKey][key].forEach((obj) => {
            consumptions.push(obj[heatMapKey])
          })
        })
        maxConsumption = Math.max(...consumptions)
      }

      const color1 = maxConsumption
      const color2 = color1 - (maxConsumption * 10) / 100
      const color3 = color2 - (maxConsumption * 10) / 100
      const color4 = color3 - (maxConsumption * 10) / 100
      const color5 = color4 - (maxConsumption * 10) / 100
      const color6 = color5 - (maxConsumption * 10) / 100
      const color7 = color6 - (maxConsumption * 10) / 100
      const color8 = color7 - (maxConsumption * 10) / 100
      const color9 = color8 - (maxConsumption * 10) / 100

      const color10 = minConsumption || 0

      return {
        color1,
        color2,
        color3,
        color4,
        color5,
        color6,
        color7,
        color8,
        color9,
        color10,
      }
    }
    return {}
  }, [analysisData, heatMapKey])

  const locationParam = searchParams.get('location')

  const { data: deviceData, isFetching: deviceDataFetching } = useGetDeviceDataQuery(
    {
      from: searchParams.get('from_date') || '',
      to: searchParams.get('to_date') || '',
      device: deviceId,
      location: locationParam,
    },
    {
      skip: !deviceId,
    },
  )

  const { data: { results: locations } = { results: [] } } =
    useGetAnalysisLocationsQuery()

  const { data: { results: devices } = { results: [] } } = useGetDevicesQuery(
    {
      locationId: locationParam || '0',
    },
    {
      skip: !locationParam,
    },
  )

  const navigate = useNavigate()

  const selectedDevice = devices?.find(({ id }) => id.toString() === deviceId)

  const selectedLocation = locations?.find(({ id }) => id.toString() === locationParam)

  const [isSelecting, setIsSelecting] = useState<boolean>(false)
  // const [sliderValue, setSliderValue] = useState(0);
  // const sliderLabelStyles = {
  //   mt: "2",
  //   ml: "-2.5",
  //   fontSize: "sm",
  // };

  const [selectedItems, setSelectedItems] = useState<
    Array<
      TSelectableItem & {
        props: { item: SelectableProps }
      }
    >
  >([])

  interface TouchHour {
    hour: number
    idx: number
  }

  const [touchedHours, setTouchedHours] = useState<TouchHour[]>([])

  const selectedHours: SelectableItem[] = useMemo(
    () =>
      // const isTouchedContains = selectedItems.filter(item => touchedHours.current?.includes(item.props.hours))
      selectedItems.reduce<SelectableItem[]>(
        (prev, current) => prev.concat(current.props.item),
        [],
      ),
    [selectedItems],
  )

  const calculationsHours: SelectableItem[] = useMemo(() => {
    return selectedHours.filter(
      ({ idx, hour }) =>
        !touchedHours.some(
          ({ idx: delIdx, hour: delHour }) => idx === delIdx && hour === delHour,
        ),
    )
  }, [touchedHours, selectedHours])

  const totalSelectedConsumption = useMemo(
    () =>
      calculationsHours.reduce<number>((prev, current) => prev + current.consumption, 0),
    [calculationsHours],
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    touchedHours.forEach(({ idx }) => {
      if (!calculationsHours.some(({ idx: calcIndex }) => idx === calcIndex)) {
        setTouchedHours([])
        unselect(idx)
      }
    })
  }, [calculationsHours, touchedHours])

  const selectableGroup = useRef<SelectableGroup | null>(null)
  const unselect = (idx: number) => {
    const selectedItems = selectableGroup.current?.selectedItems as Set<
      TSelectableItem & {
        props: { item: SelectableProps }
      }
    >
    selectedItems?.forEach((item) => {
      if (item.props.item.idx === idx) {
        selectedItems.delete(item)
        item.setState({ isSelected: false })
        setSelectedItems(Array.from(selectedItems))
      }
    })
  }

  const maxHours = useMemo(() => {
    if (analysisData) {
      const lengths = Object.keys(analysisData.results[heatMapKey]).map((key) => ({
        date: key,
        length: analysisData.results[heatMapKey][key].length,
      }))
      const arrIndexWithMaxHours = lengths.sort((a, b) => b.length - a.length)
      return analysisData.results[heatMapKey][arrIndexWithMaxHours[0]?.date]
    }
  }, [analysisData, heatMapKey])
  return (
    <>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          <Stack direction="row">
            <ArrowBackIcon
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(-1)
              }}
            />
            <Text>Data Analysis</Text>
          </Stack>
        </Heading>
      </Header>
      <AnalysisHomeForm dataAnalysisPage />

      <Box
        py={10}
        px={6}
        mt={5}
        mb={20}
        h="full"
        w="full"
        position="relative"
        boxShadow="lg"
        bgColor="white"
        rounded="lg"
        borderWidth={1}
        sx={{
          '.selectable-selectbox': {
            zIndex: 9000,
            position: 'absolute',
            cursor: 'default',
            background: 'none',
            border: '1px dashed grey',
          },
        }}
      >
        <HStack spacing={{ base: 4 }} w="100%" justifyContent={{ base: 'space-between' }}>
          {selectedDevice?.device_type?.image && (
            <Image src={`${selectedDevice?.device_type?.image}`} />
          )}
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="bold" color="text.light">
              {deviceId ? 'Device' : ''}
            </Text>
            <Heading
              as="h2"
              fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}
              fontWeight="bold"
            >
              <Flex direction="column">
                {deviceId ? selectedDevice?.title : 'Total consumption'}
                <Text
                  fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
                  display="inline-block"
                  fontWeight="normal"
                >
                  {selectedLocation?.title ? `(${selectedLocation.title.trim()})` : ''}
                </Text>
              </Flex>
            </Heading>
          </Flex>
          {isDesktop && (
            <Center height={20}>
              <Divider orientation="vertical" height="full" />
            </Center>
          )}
          <Flex gap="20px">
            <Flex direction="column">
              <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                Selected time slot consumption:
              </Heading>
              <Skeleton isLoaded={!deviceDataFetching}>
                <AnimatedHeading
                  as="h3"
                  key={totalSelectedConsumption}
                  minH="30px"
                  animate={{
                    fontSize: ['16px', '24px', '16px'],
                    transition: { duration: 1 },
                  }}
                  fontSize={{ base: 'sm', md: 'md' }}
                  fontWeight={{ base: 'normal' }}
                  mt={3}
                >
                  {totalSelectedConsumption
                    ? `${addCommasToNumber(totalSelectedConsumption)} ${KILOWATT}`
                    : 'N/A'}
                </AnimatedHeading>
              </Skeleton>
            </Flex>

            {deviceId ? (
              <>
                <Flex direction="column">
                  <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                    Current Usage
                  </Heading>
                  <Skeleton isLoaded={!deviceDataFetching}>
                    <Heading
                      as="h3"
                      fontSize={{ base: 'sm', md: 'md' }}
                      fontWeight={{ base: 'normal' }}
                      mt={3}
                    >
                      {deviceData?.current_usage || 5} {KILOWATT}
                    </Heading>
                  </Skeleton>
                </Flex>
                <Flex direction="column">
                  <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                    Carbon Intesity
                  </Heading>
                  <Skeleton isLoaded={!deviceDataFetching}>
                    <Heading
                      as="h3"
                      fontSize={{ base: 'sm', md: 'md' }}
                      fontWeight={{ base: 'normal' }}
                      mt={3}
                    >
                      {deviceData?.carbon_intensity || 24} g
                    </Heading>
                  </Skeleton>
                </Flex>
              </>
            ) : null}
            <Flex direction="column">
              <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                {analysisData?.isGenerator
                  ? 'Total Produced energy for the period'
                  : 'Total Consumption for the period'}
              </Heading>
              <Skeleton isLoaded={AnalysisDataSuccess}>
                <Heading
                  as="h3"
                  fontSize={{ base: 'sm', md: 'md' }}
                  fontWeight={{ base: 'normal' }}
                  mt={3}
                >
                  {addCommasToNumber(
                    analysisData?.isGenerator
                      ? analysisData?.totalGeneratedPower || 0
                      : analysisData?.totalConsumption || 0,
                  )}
                  {KILOWATT_HOUR}
                </Heading>
              </Skeleton>
            </Flex>
          </Flex>
        </HStack>
        <Stack
          direction={isDesktop ? 'row' : 'column'}
          mt={10}
          mb={10}
          spacing={12}
          width="100%"
          overflowX="hidden"
          alignItems={isDesktop ? 'start' : 'center'}
        >
          <Stack
            direction="row"
            w="100%"
            overflowX="scroll"
            pb="40px"
            width="100%"
            spacing={4}
            alignItems="stretch"
          >
            <Flex
              direction="column"
              // minW={840}
            >
              <Flex minH="40px" mt="10px">
                <Flex direction="column" justifyContent="space-around">
                  <Heading
                    as="h5"
                    fontSize="sm"
                    fontWeight="bold"
                    color="text.light"
                    alignSelf="flex-start"
                  >
                    Infographic
                  </Heading>
                </Flex>
                {selectedItems.length > 0 ? (
                  <Flex marginLeft="10px" width="320px" justifyContent="space-between">
                    <Tooltip
                      label={`You can press "Esc" key to clear selection`}
                      placement="top"
                      hasArrow
                    >
                      <Button
                        maxW="280px"
                        onClick={() => {
                          selectableGroup?.current?.clearSelection()
                        }}
                      >
                        Clear selection
                      </Button>
                    </Tooltip>
                    {!selectedDevice || selectedDevice.is_main ? (
                      <Button maxW="280px" onClick={onOpen}>
                        Show Breakdown
                      </Button>
                    ) : null}
                  </Flex>
                ) : null}
              </Flex>
              <Skeleton
                w={selectableGridDimentions.width}
                h={selectableGridDimentions.height}
                isLoaded={AnalysisDataSuccess}
                hidden={AnalysisDataSuccess}
              />
              <Box zIndex={1} mt={8} hidden={!AnalysisDataSuccess}>
                {AnalysisDataSuccess && (
                  <HStack spacing={2}>
                    <VStack spacing={0}>
                      {Object.keys(analysisData?.results[heatMapKey])
                        .sort()
                        .map((key, idx) => (
                          <Box
                            key={idx}
                            className="not-selectable"
                            h={6}
                            whiteSpace="nowrap"
                            fontSize="sm"
                            color="text.light"
                          >
                            {/* @ts-ignore */}
                            {key?.replaceAll('-', '/')}
                          </Box>
                        ))}
                    </VStack>
                    {/* @ts-ignore */}
                    <SelectableGroup
                      className={
                        isSelecting ? 'selecting selectBox' : 'selection selectBox'
                      }
                      clickClassName="tick"
                      enableDeselect={true}
                      tolerance={0}
                      deselectOnEsc={true}
                      allowClickWithoutSelected={true}
                      duringSelection={() => setIsSelecting(true)}
                      onSelectionFinish={(selectedItems: any) => {
                        setIsSelecting(false)
                        setSelectedItems(selectedItems)
                      }}
                      ignoreList={['.not-selectable']}
                      ref={selectableGroup}
                    >
                      <Grid
                        items={analysisData}
                        pointerEvents={isSelecting ? 'none' : 'auto'}
                        consumptionRates={consumptionRates}
                        heatMapKey={heatMapKey}
                      />
                    </SelectableGroup>
                  </HStack>
                )}
              </Box>
              <HStack spacing={0} mt={1} hidden={!AnalysisDataSuccess}>
                <Box fontSize="sm" w={'88px'} h={5} mt={2}></Box>
                {maxHours &&
                  maxHours.map((hours) => {
                    return (
                      <Box
                        key={hours.hour}
                        fontSize="sm"
                        w={12}
                        textAlign="center"
                        color="text.light"
                      >
                        {`${hours.hour.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}h`}
                      </Box>
                    )
                  })}
              </HStack>
            </Flex>
            <Flex direction="column" pb={5}>
              <Heading as="h5" fontSize="sm" fontWeight="bold" color="text.light">
                Scale
              </Heading>
              <HStack alignItems="stretch" h="full" mt={8}>
                <VStack spacing={1}>
                  {colorsArray.map((item, idx) => (
                    <Box key={idx} w={3} bgColor={item.color} h={item.height}></Box>
                  ))}
                </VStack>
                <VStack justify="space-between">
                  {Array.from([
                    consumptionRates?.color1,
                    consumptionRates?.color3,
                    consumptionRates?.color5,
                    consumptionRates?.color8,
                    consumptionRates?.color10,
                  ]).map((item, idx) => (
                    <Box key={idx} fontSize="sm" color="text.light">
                      {typeof item === 'number'
                        ? `${+parseFloat(item.toFixed(1))} ${KILOWATT_HOUR}`
                        : null}
                    </Box>
                  ))}
                </VStack>
              </HStack>
            </Flex>
          </Stack>
          {selectedItems.length ? (
            <Stack
              direction={isDesktop ? 'column' : 'row'}
              fontSize="xs"
              color="text.intermediate"
              fontWeight="bold"
              spacing={5}
              minH={isDesktop ? 'lg' : 'auto'}
              h={isDesktop ? `${selectableGridDimentions.height}px` : 'auto'}
              justify="flex-start"
              divider={<Divider orientation={isDesktop ? 'horizontal' : 'vertical'} />}
              w={isDesktop ? 44 : 'auto'}
            ></Stack>
          ) : null}
          {selectedHours.length ? (
            <DevicesBreakdown
              selectedHours={selectedHours}
              onClose={onClose}
              isOpen={isOpen}
            />
          ) : null}
        </Stack>
      </Box>
    </>
  )
}
