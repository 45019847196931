import { ComponentWithAs, IconProps, Stack, Text } from '@chakra-ui/react'
import { Link, LinkProps, useResolvedPath, useMatch } from 'react-router-dom'

type Props = LinkProps & {
  Icon: ComponentWithAs<'svg', IconProps>
  iconWidth: string | number
  iconHeight: string | number

  TextWrapper?: React.FC
}

export const CustomLink: React.FC<Props> = ({
  Icon,
  iconWidth,
  iconHeight,
  children,
  to,
  TextWrapper,
  ...props
}) => {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: false })

  return (
    <Link to={to} {...props}>
      <Stack
        as="li"
        fontSize="md"
        color="text.dark"
        direction="row"
        w="full"
        alignItems="center"
      >
        <Icon
          color={match ? '#157FEE' : 'icon.terirary'}
          w={iconWidth}
          h={iconHeight}
          minWidth="36px"
        />
        {TextWrapper ? (
          <TextWrapper>
            <Text fontWeight={match ? 'bold' : 'normal'}>{children}</Text>
          </TextWrapper>
        ) : (
          <Text fontWeight={match ? 'bold' : 'normal'}>{children}</Text>
        )}
      </Stack>
    </Link>
  )
}
