import { format } from 'date-fns'
import { pythonApi } from '../../../app/services/api/api'
import { downloadFile } from '../../../utils/helpers'
import { prepareFilters } from './helpers'

interface Alarm {
  alarm_id: number
  device_id: number
  device_title: string
  location_id: number
  location_title: string
  measurement_type: string
  timestamp: string
  value: number
  limit: number
  alarm_type: string
  description: string
  priority: number
  ai_suggestion: string
}

interface ApiResponse {
  count: number
  next: string | null
  previous: string | null
  results: Alarm[]
  total_pages: number
}

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAlarms: builder.query<ApiResponse, any>({
      query: ({ filters }) => {
        const searchParams = prepareFilters(filters)
        return { url: `/alarms/?${searchParams}`, method: 'GET' }
      },
    }),
    getAlarmById: builder.query<Alarm, { id: number }>({
      query: ({ id }) => ({
        url: `/alarms/${id}`,
        method: 'GET',
      }),
    }),

    patchAlarmById: builder.mutation<Alarm, { id: number; body: Partial<Alarm> }>({
      query: ({ id, body }) => ({
        url: `/alarms/${id}/`,
        method: 'PATCH',
        body,
      }),
    }),
    exportCSV: builder.mutation<void, { filters: any }>({
      query: ({ filters }) => {
        const searchParams = prepareFilters(filters)
        return {
          url: `/alarms/export_alarms/?${searchParams}`,
          method: 'POST',
          responseHandler: async (response) => {
            if (response.ok) {
              const href = window.URL.createObjectURL(await response.blob())
              const filename = `Alarms_${format(new Date(), 'yyyy-MM-dd')}.csv`
              downloadFile(href, filename)
            }
            // Return null or an empty object to ensure the state remains serializable
            return null
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAlarmsQuery,
  useGetAlarmByIdQuery,
  usePatchAlarmByIdMutation,
  useExportCSVMutation,
} = extendedApi
