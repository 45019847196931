import { createIcon } from '@chakra-ui/icon'

export const AutomationIcon = createIcon({
  displayName: 'BarIcon',
  viewBox: '-4 -6 36 36',
  path: (
    <g id="sliders">
      <path
        id="Icon"
        d="M4.66602 24.5V16.3333M4.66602 11.6667V3.5M13.9993 24.5V14M13.9993 9.33333V3.5M23.3327 24.5V18.6667M23.3327 14V3.5M1.16602 16.3333H8.16602M10.4993 9.33333H17.4994M19.8327 18.6667H26.8327"
        stroke="currentColor"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
})
