import React, { useMemo } from 'react'
import { Flex, Heading, Skeleton } from '@chakra-ui/react'
import {
  ENERGY_GENERATOR,
  ENERGY_PLANT_LOGGER,
  KILOWATT,
  KILOWATT_HOUR,
  AMPERS,
  CURRENT_USAGE_POLLING_INTERVAL,
} from '../../../utils/consts'
import {
  addCommasToNumber,
  DeviceStatusKeys,
  getDeviceCurrentDataFieldsByCategory,
  getDeviceStatus,
} from '../../../utils/helpers'
import { useGetCurrentDataQuery } from '../api/dasboardApi'
import Flippable from './Flippable'

interface EnergyDeviceTitleProps {
  isDeviceDataFetching: boolean
  deviceData: {
    id?: number
    current_usage?: number
    carbon_intensity?: number
    total_generated_power?: number
    total_consumption?: number
    input_power?: number
    dc_current?: number
    status?: DeviceStatusKeys
    category?: string
  }
}

const EnergyDeviceTitle: React.FC<EnergyDeviceTitleProps> = ({
  isDeviceDataFetching,
  deviceData,
}) => {
  const deviceCurrentFieldsByCategory = useMemo(
    () => getDeviceCurrentDataFieldsByCategory(deviceData?.category),
    [deviceData?.category],
  )

  const response = useGetCurrentDataQuery(
    {
      deviceIds: [deviceData.id],
      fields: deviceCurrentFieldsByCategory,
    },
    {
      pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
      skip: !deviceData.id || deviceCurrentFieldsByCategory.length === 0,
    },
  )

  const { data: deviceCurrentData = [] } = response

  const isEnergyGenerator = deviceData?.category === ENERGY_GENERATOR
  const isEnergyPlantLogger = deviceData?.category === ENERGY_PLANT_LOGGER

  const energyText =
    isEnergyGenerator || isEnergyPlantLogger
      ? 'Total Produced Energy for the period'
      : 'Total Consumption for the period'
  const energyValue =
    isEnergyGenerator || isEnergyPlantLogger
      ? deviceData?.total_generated_power || 0
      : deviceData?.total_consumption || 0

  const currentUsage =
    deviceCurrentData[deviceData.id]?.consumption || deviceData?.current_usage || 0

  const inputPower = deviceCurrentData?.input_power || deviceData?.input_power || 0

  const dcPower = deviceCurrentData?.dc_current || deviceData?.dc_current || 0

  return (
    <>
      {!(isEnergyGenerator || isEnergyPlantLogger) ? (
        <>
          <Flex direction="column">
            <Heading as="h4" fontSize="sm" color="text.light">
              Current Usage
            </Heading>
            <Skeleton isLoaded={!isDeviceDataFetching}>
              <Flippable
                value={addCommasToNumber(currentUsage)}
                unit={typeof currentUsage !== 'string' ? KILOWATT : null}
              />
            </Skeleton>
          </Flex>
          <Flex direction="column">
            <Heading as="h4" fontSize="sm" color="text.light">
              Carbon Intensity
            </Heading>
            <Skeleton isLoaded={!isDeviceDataFetching}>
              <Heading as="h3" fontWeight="normal" fontSize="md" mt={3}>
                {addCommasToNumber(deviceData?.carbon_intensity || 0)} g
              </Heading>
            </Skeleton>
          </Flex>
        </>
      ) : null}

      <Flex direction="column">
        <Heading as="h4" fontSize="sm" color="text.light">
          {energyText}
        </Heading>
        <Skeleton isLoaded={!isDeviceDataFetching}>
          <Heading as="h3" fontWeight="normal" fontSize="md" mt={3}>
            {addCommasToNumber(energyValue)} {KILOWATT_HOUR}
          </Heading>
        </Skeleton>
      </Flex>
      {isEnergyPlantLogger ? (
        <>
          <Flex direction="column">
            <Heading as="h4" fontSize="sm" color="text.light">
              Input Power
            </Heading>
            <Skeleton isLoaded={!isDeviceDataFetching}>
              <Flippable value={addCommasToNumber(inputPower)} unit={KILOWATT} />
            </Skeleton>
          </Flex>
          <Flex direction="column">
            <Heading as="h4" fontSize="sm" color="text.light">
              DC Current
            </Heading>
            <Skeleton isLoaded={!isDeviceDataFetching}>
              {/* <Heading as="h3" fontWeight="normal" fontSize="md" mt={3}>
              </Heading> */}
              <Flippable value={addCommasToNumber(dcPower)} unit={AMPERS} />
            </Skeleton>
          </Flex>

          <Flex direction="column">
            <Heading as="h4" fontSize="sm" color="text.light">
              Active Power
            </Heading>
            <Skeleton isLoaded={!isDeviceDataFetching}>
              <Flippable
                value={addCommasToNumber(deviceCurrentData?.generated_power || 0)}
                unit={
                  typeof deviceCurrentData?.generated_power !== 'string' ? KILOWATT : null
                }
              />
            </Skeleton>
          </Flex>

          <Flex direction="column">
            <Heading as="h4" fontSize="sm" color="text.light">
              Status
            </Heading>
            <Skeleton isLoaded={!isDeviceDataFetching}>
              <Heading as="h3" fontWeight="normal" fontSize="md" mt={3}>
                {getDeviceStatus(deviceData?.status)}
              </Heading>
            </Skeleton>
          </Flex>
        </>
      ) : null}
    </>
  )
}

export default EnergyDeviceTitle
