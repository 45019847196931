import React, { useCallback, useState } from 'react'
import { Tabs, TabList, Tab } from '@chakra-ui/react'
import { CustomTabsProps } from './types'

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabs,
  setActiveIndex,
  activeIndex,
  ...rest
}) => {
  const [internalIndex, setInternalIndex] = useState(0)

  const isControlled = activeIndex !== undefined && setActiveIndex !== undefined
  const handleTabsChange = useCallback(
    (index: number) => {
      if (isControlled) {
        setActiveIndex(index)
        return
      }

      setInternalIndex(index)
    },
    [isControlled, setActiveIndex],
  )

  return (
    <Tabs
      variant="soft-rounded"
      w="fit-content"
      bg="white"
      rounded="3xl"
      colorScheme="gray"
      index={isControlled ? activeIndex : internalIndex}
      padding="4px"
      onChange={handleTabsChange}
      marginBottom="16px"
      {...rest}
    >
      <TabList padding="4px">
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            fontWeight="400"
            padding="0 24px"
            height="36px"
            _selected={{
              fontSize: '14px',
              fontWeight: '700',
              bg: '#F7F9FD',
              outline: 'none',
              shadow: 'none',
            }}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

export default CustomTabs
