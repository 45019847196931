import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Text,
  useDisclosure,
  Box,
  Divider,
} from '@chakra-ui/react'
import { DrawerIcon } from '../../assets/icons/Drawer'
import NavItems from './Navigation'
import { useUser } from '../../context/UserContext'

const DrawerMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user } = useUser()
  const bottomMenuItems = [
    { text: 'Leave feedback' },
    { text: 'Terms of service' },
    { text: 'Privacy policy' },
  ]
  return (
    <>
      <DrawerIcon w={8} h={5} cursor="pointer" as="button" onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Stack direction="column">
              <Avatar size="sm" name={user?.name} src="https://bit.ly/kent-c-dworkodds" />
              <Text>{user?.name}</Text>
            </Stack>
          </DrawerHeader>
          <Divider orientation="horizontal" />
          <DrawerBody mt={4}>
            <Stack
              direction="column"
              spacing={10}
              as="ul"
              justify="space-between"
              w="full"
            >
              <NavItems />
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Stack direction="column" as="ul" w="full" spacing={6}>
              {bottomMenuItems.map(({ text }) => (
                <Box as="li" listStyleType="none" fontSize="sm" key={text}>
                  {text}
                </Box>
              ))}
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default DrawerMenu
